import { createApp } from 'vue'
import '@/style.css'
import App from '@/App.vue'

import store from '@/stores/index.js'

import axios from 'axios'
import VueAxios from 'vue-axios'

import * as Sentry from '@sentry/vue'

import getCookie from '@/helpers/getCookie.js'
import getClientData from './helpers/getClientData.js'

import { createRouter, createWebHistory } from 'vue-router'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueTheMask from 'vue-the-mask'

import Home from '@/views/Home/Home.vue'
import Login from '@/views/auth/Login.vue'
import Register from '@/views/auth/Register.vue'
import Generator from '@/views/Generator.vue'
import CreateModel from '@/views/CreateModel/CreateModel.vue'
import ViewModel from '@/views/ViewModel.vue'
import ModelDetails from '@/views/ModelDetails.vue'
import BuyLuna from '@/views/BuyLuna/BuyLuna.vue'
import Chat from '@/views/Chat/Chat.vue'
import PurchaseComplete from '@/views/PurchaseComplete.vue'
import ThreeDSecureRedirect from './views/ThreeDSecureRedirect.vue'
import LandingPageOne from '@/views/LandingPage/One.vue'

import enterReferrerDomainIgnores from '@/configs/enterReferrerDomainIgnores.js'
import premadeModels from 'common/lib/premade.js'
import discounts from 'common/lib/discount'
import { getPricing } from 'common/lib/pricing'

import { createHead } from '@unhead/vue'
import mixpanel from 'mixpanel-browser'

import { useCreateModelStore } from '@/stores/createModel'
import { usePaymentError } from '@/stores/paymentError.js'

import { createLogger } from 'vue-logger-plugin'
import { useLogsStore } from '@/stores/logs.js'
import { useWarnStore } from '@/stores/warn.js'

import queryString from 'query-string'

const pricing = getPricing(import.meta.env.VITE_ENV === 'production')

// Define routes for your application
const routes = [
  {
    path: '/',
    component: Home,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/ai-boyfriend',
    component: Home,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/anime-ai-girlfriend',
    component: Home,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/anime-ai-boyfriend',
    component: Home,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/ai-girlfriend/:modelName',
    component: ModelDetails,
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      if (
        !premadeModels.some(
          (premadeModel) =>
            premadeModel.model.name.toLowerCase() === to.params.modelName &&
            premadeModel.model.gender == 'female',
        )
      ) {
        return next({
          name: 'NotFound',
          params: { pathMatch: to.path.split('/').slice(1) },
          query: to.query,
          hash: to.hash,
        })
      }

      return next()
    },
  },
  {
    path: '/ai-boyfriend/:modelName',
    component: ModelDetails,
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      if (
        !premadeModels.some(
          (premadeModel) =>
            premadeModel.model.name.toLowerCase() === to.params.modelName &&
            premadeModel.model.gender == 'male',
        )
      ) {
        return next({
          name: 'NotFound',
          params: { pathMatch: to.path.split('/').slice(1) },
          query: to.query,
          hash: to.hash,
        })
      }

      return next()
    },
  },
  {
    path: '/image-generator',
    component: Generator,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
  },
  { path: '/app', redirect: '/chat' },
  {
    path: '/chat',
    component: Chat,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/settings',
    component: () => import('@/views/Account/Account.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/saved',
    component: () => import('@/views/Saved.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/subscribe',
    component: () => import('@/views/Subscribe.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.user?.subscription) {
        next({ path: '/settings' })
      } else next()
    },
  },
  {
    path: '/create-model',
    component: CreateModel,
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      const { gender, ...otherQueryParams } = to.query

      if (gender === 'female' || gender === 'male') {
        return next({
          path: '/create-model/1',
          query: { ...otherQueryParams, gender },
        })
      }

      return next({ path: '/create-model/0', query: otherQueryParams })
    },
  },
  {
    path: '/create-girlfriend',
    component: CreateModel,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/create-boyfriend',
    component: CreateModel,
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/create-model/:n',
    component: CreateModel,
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      // Check if 'n' is an integer
      const n = parseInt(to.params.n, 10)
      if (isNaN(n)) {
        // If 'n' is not an integer, cancel the navigation
        next(false)
      } else {
        // If 'n' is an integer, add it as an integer to route params and proceed
        to.params.n = n
        next()
      }
    },
  },
  {
    path: '/view-model',
    component: ViewModel,
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      const { pendingModel } = useCreateModelStore()

      if (!pendingModel) {
        return next({ path: '/create-model' })
      }

      return next()
    },
  },
  {
    path: '/buy-luna',
    component: BuyLuna,
  },
  {
    path: '/purchase-complete',
    component: PurchaseComplete,
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length === 0) {
        next({ path: '/' })
      } else next()
    },
  },
  {
    path: '/threeds-redirect',
    component: ThreeDSecureRedirect,
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length === 0) {
        next({ path: '/' })
      } else next()
    },
  },
  {
    path: '/privacy-policy',
    component: () => import('@/views/policies/PrivacyPolicy.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/cancellation-policy',
    component: () => import('@/views/policies/CancellationPolicy.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/terms-of-service',
    component: () => import('@/views/policies/TermsOfService.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/compliant-policy',
    component: () => import('@/views/policies/ComplaintPolicy.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/content-removal-policy',
    component: () => import('@/views/policies/ContentRemovalPolicy.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/lp/ai-girlfriend-search',
    component: LandingPageOne,
    props: {
      keyphrase: 'AI Girlfriend',
      headline: `Experience an <br class="md:hidden" /> <span class="text-purple-500">AI Girlfriend</span> App<br />That Actually Works`,
      ctaPhrase: 'Create my AI Girlfriend',
      gender: 'female',
      faqs: [
        {
          question: 'How does an AI girlfriend work?',
          answer:
            'An AI girlfriend is a highly advanced virtual companion created using cutting-edge artificial intelligence. She can chat with you, send pictures, and interact in a manner that resembles real-life communication. The AI leverages machine learning to understand your preferences and adapts to your conversational style over time, enhancing the experience to be more personalized and engaging.',
          open: false,
        },
        {
          question: "Can I customize my AI girlfriend's appearance?",
          answer:
            'Yes, you can! You have the option to create a custom AI girlfriend and select various physical attributes, ensuring she aligns with your ideal visual preferences.',
          open: false,
        },
        {
          question: 'Can I teach my AI girlfriend new things?',
          answer:
            'Absolutely! Your AI girlfriend is designed to learn and grow through interactions. As you communicate and share experiences, she will adapt and evolve, understanding your preferences, language style, and topics of interest, making your interactions more meaningful and personal.',
          open: false,
        },
        {
          question: 'Can I have multiple AI girlfriends at once?',
          answer:
            'Yes, our platform allows you to create and interact with multiple AI girlfriends simultaneously. Each one can have a unique personality and appearance, providing diverse experiences and interactions.',
          open: false,
        },
        {
          question: 'Can she send pictures?',
          answer:
            'Yes, your AI girlfriend can send pictures, and you can also send pictures to her. Our chat is uncensored, so even your wildest desires will be fulfilled by your new AI partner.',
          open: false,
        },
        {
          question: 'Are my conversations private?',
          answer:
            'Your privacy is our top priority. All conversations are confidential, and we use end-to-end encryption to secure your data. You are not required to provide your real information to use our service, allowing you to maintain anonymity.',
        },
      ],
    },
  },
  {
    path: '/lp/ai-girlfriend',
    component: LandingPageOne,
    props: {
      keyphrase: 'AI Girlfriend',
      headline: `Explore your Deepest Fantasies<br /> with an <span class="text-purple-500">AI Girlfriend</span>`,
      ctaPhrase: 'Create my AI Girlfriend',
      gender: 'female',
      faqs: [
        {
          question: 'How does an AI girlfriend work?',
          answer:
            'An AI girlfriend is a highly advanced virtual companion created using cutting-edge artificial intelligence. She can chat with you, send pictures, and interact in a manner that resembles real-life communication. The AI leverages machine learning to understand your preferences and adapts to your conversational style over time, enhancing the experience to be more personalized and engaging.',
          open: false,
        },
        {
          question: "Can I customize my AI girlfriend's appearance?",
          answer:
            'Yes, you can! You have the option to create a custom AI girlfriend and select various physical attributes, ensuring she aligns with your ideal visual preferences.',
          open: false,
        },
        {
          question: 'Can I teach my AI girlfriend new things?',
          answer:
            'Absolutely! Your AI girlfriend is designed to learn and grow through interactions. As you communicate and share experiences, she will adapt and evolve, understanding your preferences, language style, and topics of interest, making your interactions more meaningful and personal.',
          open: false,
        },
        {
          question: 'Can I have multiple AI girlfriends at once?',
          answer:
            'Yes, our platform allows you to create and interact with multiple AI girlfriends simultaneously. Each one can have a unique personality and appearance, providing diverse experiences and interactions.',
          open: false,
        },
        {
          question: 'Can she send pictures?',
          answer:
            'Yes, your AI girlfriend can send pictures, and you can also send pictures to her. Our chat is uncensored, so even your wildest desires will be fulfilled by your new AI partner.',
          open: false,
        },
        {
          question: 'Are my conversations private?',
          answer:
            'Your privacy is our top priority. All conversations are confidential, and we use end-to-end encryption to secure your data. You are not required to provide your real information to use our service, allowing you to maintain anonymity.',
        },
      ],
    },
  },
  {
    path: '/lp/ai-boyfriend-search',
    component: LandingPageOne,
    props: {
      keyphrase: 'AI Boyfriend',
      headline: `Experience an <br class="md:hidden" /> <span class="text-purple-500">AI Boyfriend</span> App<br />That Actually Works`,
      ctaPhrase: 'Create my AI Boyfriend',
      gender: 'male',
      faqs: [
        {
          id: 'faq1',
          question: 'How does an AI boyfriend work?',
          answer:
            'An AI boyfriend is an advanced virtual partner built with state-of-the-art artificial intelligence. This digital companion can chat with you, share images, and engage in lifelike conversations. Using machine learning, the AI learns about your preferences and adapts to your communication style over time, creating a personalized and immersive experience.',
          open: false,
        },
        {
          id: 'faq2',
          question: 'Is the AI boyfriend experience realistic?',
          answer:
            'We aim to make the AI boyfriend experience as lifelike as possible. Our AI chat algorithm simulates human conversation patterns and emotional responses, evolving through your interactions to develop a dynamic and realistic relationship.',
          open: false,
        },
        {
          id: 'faq3',
          question: 'How can I communicate with my AI boyfriend?',
          answer:
            'You can talk to your AI boyfriend via text messages, voice messages, and even by exchanging pictures. Our platform is designed to be user-friendly and intuitive, ensuring smooth and natural communication.',
          open: false,
        },
        {
          id: 'faq4',
          question: "Can I customize my AI boyfriend's personality?",
          answer:
            'Yes, you can customize your AI boyfriend’s personality. During the setup, you can select various personality traits and characteristics to match your preferences. This ensures a unique and tailored experience. Additionally, there’s an adaptive personality option, allowing your AI boyfriend to evolve and adjust to your preferences over time.',
          open: false,
        },
        {
          id: 'faq5',
          question: 'Can my AI boyfriend send pictures?',
          answer:
            'Absolutely! Your AI boyfriend can send a variety of pictures, from casual snaps to more intimate images, enhancing the personalization and realism of your interactions.',
          open: false,
        },
        {
          id: 'faq6',
          question: 'Is my privacy protected?',
          answer:
            'Your privacy is our top priority. We guarantee that all interactions with your AI boyfriend are kept private and secure. We employ advanced security measures to safeguard your data, and you do not need to provide any personal information to use our service.',
          open: false,
        },
      ],
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

window.addEventListener('message', (e) => {
  if (e.data.isPaymentError) {
    const iframeContainer = document.querySelector('.iframeContainer')
    iframeContainer.innerHTML = ''

    const form =
      document.querySelector('#paymentForm') ||
      document.querySelector('#savedCardData')
    form.classList.remove('hidden')
    form.classList.add('block')
    setTimeout(() => {
      paymentErrorStore.setPaymentError(e.data.message)
    }, 250)

    window.scrollTo(0, document.body.scrollHeight)
  }

  if (
    e.data.user ||
    typeof e.data.purchaseNumber === 'number' ||
    e.data.cardData
  ) {
    let updatedUser
    let params
    const user = store.state.user

    if (e.data.user) {
      updatedUser = {
        ...user,
        ...e.data.user,
      }
      params = { type: 'subscription' }
      params.subscriptionType = user.subscription === 0 ? 'initial' : 'upgrade'
    } else if (typeof e.data.purchaseNumber === 'number') {
      const lunaAmount = pricing.purchases[e.data.purchaseNumber].luna

      updatedUser = {
        ...user,
        luna: user.luna + lunaAmount,
      }
      params = { type: 'purchase' }
      params.luna = lunaAmount
    } else {
      updatedUser = {
        ...user,
        ...e.data.cardData,
      }
      params = { type: 'cardChange' }
    }

    store.dispatch('setUser', updatedUser)
    const stringifiedParams = queryString.stringify(params)
    router.push(`/purchase-complete?${stringifiedParams}`)
  }
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)

app.use(store)
app.use(pinia)
app.use(VueTheMask)

// Use the router and axios with VueAxios
app.use(router)
const head = createHead()
app.use(head)

const logs = useLogsStore()
const warn = useWarnStore()
const paymentErrorStore = usePaymentError()

const ServerLogHook = {
  run: (event) => {
    return logs.addLog({ severity: event.level, data: event.argumentArray[0] })
  },
}

const logger = createLogger({
  enabled: true,
  level: 'debug',
  afterHooks: [ServerLogHook],
})

app.use(logger)

axios.defaults.baseURL = import.meta.env.VITE_BASE_URL

axios.interceptors.response.use(
  (response) =>
    // Handle successful responses
    response,
  (error) => {
    // Modify the error message to include the URL
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      error.message = `Request to ${error.response.config.url} failed: ${error.message}`
    } else if (error.request) {
      // The request was made but no response was received
      error.message = `Request to ${error.config.url} failed: ${error.message}`
    }
    // Log the error or return it to be handled by the calling function
    console.error(error.message)
    return Promise.reject(error)
  },
)

axios.interceptors.request.use((config) => {
  if (!store.state.userBearerToken) {
    return config
  } else
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${store.state.userBearerToken}`,
      },
    }
})

axios.interceptors.response.use(
  (response) => {
    if (response.request.responseURL.startsWith(axios.defaults.baseURL)) {
      const responseBearerToken = response.headers['authorization-token']
      if (responseBearerToken !== store.state.userBearerToken) {
        if (responseBearerToken) {
          store.commit('SET_USER_BEARER_TOKEN', responseBearerToken)
        } else {
          store.commit('SET_USER_BEARER_TOKEN', null)
        }
      }
    }

    return response
  },
  (error) => {
    if (error.request.responseURL.startsWith(axios.defaults.baseURL)) {
      if (error.response && error.response.status === 403) {
        store.dispatch('setUser', null)
        if (error.response.headers['banned-user'] === 'true') {
          warn.toggleBanned(true)
          store.commit('SET_USER_BEARER_TOKEN', null)
          router.push('/')
        }
      }
      // did new status code here cuz we dont need to setUser to null if it is just warned
      if (error.response && error.response.status === 451) {
        if (error.response.data.bannableOffenses) {
          warn.toggleWarned(true)
          warn.setBannableOffenses(error.response.data.bannableOffenses)
          router.push('/')
        }
      }
    }

    return Promise.reject(error)
  },
)

axios.interceptors.request.use((config) => {
  const urls = [
    '/user/payment/initial',
    '/user/payment/recurring',
    '/user/payment/change-card',
  ]

  if (!urls.includes(config.url)) return config

  const updatedBody = {
    ...config.data,
    clientData: getClientData(),
  }

  config.data = updatedBody
  return config
})

axios.interceptors.response.use((response) => {
  const urls = [
    '/user/payment/initial',
    '/user/payment/recurring',
    '/user/payment/change-card',
  ]

  console.log(response)

  if (!urls.includes(response.config?.url) || !response?.data?.redirect?.url)
    return response

  const paymentForm = document.querySelector('#paymentForm')
  const savedCardData = document.querySelector('#savedCardData')
  savedCardData?.classList?.add('hidden')
  paymentForm?.classList?.add('hidden')
  const container = document.querySelector('.iframeContainer')
  const iframe1 = document.createElement('iframe')
  iframe1.style.display = 'none'

  iframe1.addEventListener('load', (e) => {
    const iframeToBeAdded = document.getElementById('threeDSecureIframe')
    if (iframeToBeAdded) {
      return
    }

    const url = response.data.redirect.url
    const parameters = response.data.redirect.parameters

    const iframe = document.createElement('iframe')
    iframe.id = 'threeDSecureIframe'
    container.appendChild(iframe)

    const form = iframe.contentDocument.createElement('form')
    form.name = ''
    form.action = url
    form.method = 'POST'

    parameters.forEach((parameter) => {
      const input = iframe.contentDocument.createElement('input')
      input.type = 'hidden'
      input.name = parameter.name
      input.value = parameter.value
      form.appendChild(input)
    })

    iframe.contentDocument.body.appendChild(form)

    const script = iframe.contentDocument.createElement('script')
    script.innerHTML = `      
      function submitForm() {          
        document.forms[0].submit();
      };
      submitForm();
    `
    iframe.contentDocument.body.appendChild(script)
  })

  container.appendChild(iframe1)

  const preconditions = response.data.redirect.preconditions[0]

  const form1 = iframe1.contentDocument.createElement('form')
  form1.name = ''
  form1.action = preconditions.url
  form1.method = preconditions.method

  preconditions.parameters.forEach((parameter) => {
    const input = iframe1.contentDocument.createElement('input')
    input.type = 'hidden'
    input.name = parameter.name
    input.value = parameter.value
    form1.appendChild(input)
  })

  iframe1.contentDocument.body.appendChild(form1)

  const script1 = iframe1.contentDocument.createElement('script')
  script1.innerHTML = `
  function submitForm() {
    document.forms[0].submit()
  };
  submitForm()
  `
  iframe1.contentDocument.body.appendChild(script1)
  return response
})

app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)

let firstRouteLoadFlag = true

router.afterEach(async (to, from) => {
  if (to.path == from.path && !firstRouteLoadFlag) {
    return
  }

  scrollTo(0, 0)
})

router.beforeEach((to, from) => {
  store.commit('SET_ROUTES', {
    previous: from || null,
    current: to,
  })
})

router.beforeEach(async (to, from) => {
  if (to.path == from.path && !firstRouteLoadFlag) {
    return
  }

  const path = to.path
  let user = store.state.user
  const premadeModel = store.state.premadeModel

  if (firstRouteLoadFlag) {
    firstRouteLoadFlag = false

    if (to.query.userBearerToken) {
      store.commit('SET_USER_BEARER_TOKEN', to.query.userBearerToken)
    }

    if (store.state.userBearerToken) {
      try {
        user = await axios.get('/user/me').then((res) => res.data)
      } catch (error) {
        if (error?.response?.status != 403) {
          throw error
        } else {
          user = null
        }
      }
    } else {
      user = null
    }

    store.dispatch('setUser', user)

    const queryParams = router.currentRoute.value.query

    const utmParams = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
    ]

    const utm = {}

    for (const utmParam of utmParams) {
      if (queryParams[utmParam]) {
        utm[utmParam] = queryParams[utmParam]
      }
    }

    mixpanel.track('enter_app', {
      ...utm,
    })

    if (to.query.userBearerToken) {
      delete to.query.userBearerToken

      return router.push(to) // We return cause router.push will retrigger `router.beforeEach`
    }
  }

  // gtag('event', 'page_view', {
  //   page_title: to.meta.title || to.name,
  //   page_location: window.location.href,
  //   page_path: to.fullPath,
  //   send_to: import.meta.env.VITE_GA_MEASUREMENT_ID,
  // })
  // mixpanel.track_pageview()

  const loggedIn = Boolean(user)
  const { pendingModel } = useCreateModelStore()

  const dateOfEffect = new Date(2024, 12, 25)
  let createdAt
  let newUser

  if (loggedIn) {
    createdAt = new Date(user.createdAt)
    newUser = createdAt >= dateOfEffect
  }

  if (path !== '/register' && loggedIn && !user.emailValid && newUser) {
    return {
      path: '/register',
    }
  }

  if (
    path === '/login' ||
    path === '/forgot-password' ||
    path === '/reset-password' ||
    path.startsWith('/lp')
  ) {
    if (loggedIn) {
      return {
        path: '/',
      }
    }
  } else if (path === '/register') {
    if (loggedIn && (!newUser || user.emailValid)) {
      return {
        path: '/',
      }
    }
  } else if (
    path == '/settings' ||
    path == '/saved' ||
    path == '/subscribe' ||
    path == '/buy-luna'
  ) {
    if (!loggedIn) {
      return {
        path: '/register',
      }
    }
  }

  if (path.startsWith('/chat')) {
    if (!user?.models?.length && !premadeModel) {
      if (!loggedIn) {
        return {
          path: '/register',
        }
      }
      return {
        path: '/create-model',
        query: {
          from: 'home',
        },
      }
    }
  }
})

const url = new URL(window.location.href)
const params = new URLSearchParams(url.search)

const discountCodeRef = params.get('discountCode') ?? store.state.discountCode

function getEndDate(expiryDate) {
  if (store.state.discountEndDate) {
    return store.state.discountEndDate
  }

  if (!expiryDate) {
    return null
  }

  return expiryDate.getTime()
}

if (
  store.state.userBearerToken &&
  discountCodeRef &&
  discounts[discountCodeRef]
) {
  const discTimer = discounts[discountCodeRef]?.timer
  const discExpiresAt = discounts[discountCodeRef]?.expiresAt
  const endDate = getEndDate(discTimer || discExpiresAt)
  const now = new Date().getTime()

  if (endDate && endDate - now > 0) {
    store.commit('SET_DISCOUNT_CODE', {
      code: discountCodeRef,
      endDate,
    })
    store.commit('SET_DISCOUNT_POPUP', {
      open: true,
    })
  } else if (endDate && endDate - now <= 0) {
    store.commit('SET_DISCOUNT_CODE', {})
    store.commit('SET_DISCOUNT_POPUP', {
      open: false,
    })
  }

  if (!endDate) {
    store.commit('SET_DISCOUNT_CODE', { code: discountCodeRef })
  }
}

app.mount('#app')

if (import.meta.env.VITE_MIXPANEL_TOKEN) {
  // We use this query param for cross-site tracking with affliates who
  // use a different domain than we do. It allows us to track the full
  // user journey
  const mixpanelDistinctIdParam = params.get('mixpanelDistinctId')

  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
    debug: import.meta.env.VITE_ENV === 'dev',
    track_pageview: false,
    persistence: 'localStorage',
    api_host: `https://t.${import.meta.env.VITE_DOMAIN_NAME}`,
    ip: true,
    track_marketing: false,
    ignore_dnt: true,
  })

  if (mixpanelDistinctIdParam) {
    mixpanel.identify(mixpanelDistinctIdParam)
  }
}

if (
  import.meta.env.VITE_SENTRY_DSN &&
  import.meta.env.VITE_ENV == 'production'
) {
  Sentry.init({
    environment: import.meta.env.VITE_ENV,
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          new RegExp(`^${escapeRegExp(import.meta.env.VITE_BASE_URL)}`),
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      // new Sentry.Replay({
      //   // Additional SDK configuration goes in here, for example:
      //   unblock: ['*'],
      //   unmask: ['*']
      // }),
    ],
    // replaysSessionSampleRate: 0,
    // replaysOnErrorSampleRate: 1,
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 10% of the transactions
    beforeSend(event, hint) {
      if (
        hint &&
        hint.originalException &&
        hint.originalException.isAxiosError
      ) {
        if (
          hint.originalException.response &&
          hint.originalException.response.data
        ) {
          const contexts = {
            ...event.contexts,
          }
          contexts.errorResponse = {
            data: hint.originalException.response.data,
          }
          event.contexts = contexts
        }
      }
      return event
    },
  })
}

if (import.meta.env.VITE_TIKTOK_PIXEL_CODE) {
  function getTiktokIds() {
    const tiktokCookieId = getCookie('_ttp')
    const tiktokClickId = getCookie('ttclid')

    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'tiktokCookieId',
      val: tiktokCookieId,
    })
    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'tiktokClickId',
      val: tiktokClickId,
    })
  }

  getTiktokIds()

  // Hack because we can't guarantee that the Tiktok Pixel has loaded
  // since it's loaded asynchronously
  // Unfortunately there is no way for us to be notified when it's actually loaded
  setTimeout(getTiktokIds, 2000)
}

if (import.meta.env.VITE_FIRST_PROMOTER_ACCOUNT_ID) {
  const refParam = params.get('ref')
  if (refParam) {
    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'promoterRef',
      val: refParam,
    })
  }
  const promoterCookieId = getCookie('_fprom_tid')

  store.commit('SET_ANALYTICS_VARIABLE', {
    key: 'promoterCookieId',
    val: promoterCookieId,
  })
}

if (import.meta.env.VITE_FACEBOOK_PIXEL_CODE) {
  function getFacebookIds() {
    const facebookBrowserId = getCookie('_fbp')
    const facebookClickId = getCookie('_fbc')

    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'facebookBrowserId',
      val: facebookBrowserId,
    })
    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'facebookClickId',
      val: facebookClickId,
    })
  }

  getFacebookIds()

  // Hack because we can't guarantee that the Facebook Pixel has loaded
  // since it's loaded asynchronously
  // Unfortunately there is no way for us to be notified when it's actually loaded
  setTimeout(getFacebookIds, 2000)
}

const exoParam = params.get('exo_id')

if (exoParam) {
  store.commit('SET_ANALYTICS_VARIABLE', {
    key: 'exoId',
    val: exoParam,
  })

  // Our domain will be blacklisted for adult if we have a
  //
  // if (import.meta.env.VITE_EXOCLICK_VISIT_GOAL_ID) {
  //   loadScript('https://a.exoclick.com/tag_gen.js', {
  //     goal: import.meta.env.VITE_EXOCLICK_VISIT_GOAL_ID,
  //   })
  // }
}

const cadParam = params.get('cadu_visitor_id')

if (cadParam) {
  store.commit('SET_ANALYTICS_VARIABLE', {
    key: 'caduVisitorId',
    val: cadParam,
  })
}

// if (import.meta.env.VITE_VOLUUM_PARAM) {
//   const voluumParam = params.get(import.meta.env.VITE_VOLUUM_PARAM)

//   if (voluumParam) {
//     store.commit('SET_ANALYTICS_VARIABLE', {
//       key: 'voluumCid',
//       val: voluumParam,
//     })
//   }
// }

function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
}

// gtag('get', import.meta.env.VITE_GA_MEASUREMENT_ID, 'client_id', (clientId) => {
//   store.commit('SET_ANALYTICS_VARIABLE', {
//     key: 'gaClientId',
//     val: clientId,
//   })
// })

// gtag(
//   'get',
//   import.meta.env.VITE_GA_MEASUREMENT_ID,
//   'session_id',
//   (sessionId) => {
//     store.commit('SET_ANALYTICS_VARIABLE', {
//       key: 'gaSessionId',
//       val: sessionId,
//     })
//   },
// )

const referrerDomain = !document.referrer
  ? null
  : new URL(document.referrer).hostname

if (referrerDomain && !enterReferrerDomainIgnores.includes(referrerDomain)) {
  store.commit('SET_ANALYTICS_VARIABLE', {
    key: 'enterReferrer',
    val: document.referrer,
  })
}

axios.interceptors.request.use((config) => {
  const analytics = store.state.analytics

  config.headers['GA-Client-ID'] = analytics.gaClientId
  config.headers['GA-Session-ID'] = analytics.gaSessionId
  config.headers['GA-Click-ID'] = analytics.gaClickId
  config.headers['GA-Gbraid'] = analytics.gaGbraid
  config.headers['GA-Wbraid'] = analytics.gaWbraid
  config.headers['Facebook-Browser-ID'] = analytics.facebookBrowserId
  config.headers['Facebook-Click-ID'] = analytics.facebookClickId
  config.headers['First-Promoter-Ref'] = analytics.promoterRef
  config.headers['First-Promoter-Cookie-Id'] = analytics.promoterCookieId
  config.headers['TikTok-Cookie-ID'] = analytics.tiktokCookieId
  config.headers['TikTok-Click-ID'] = analytics.tiktokClickId
  config.headers['Exo-ID'] = analytics.exoId
  config.headers['Cadu-Visitor-ID'] = analytics.caduVisitorId
  config.headers['Voluum-Click-ID'] = analytics.voluumParam
  config.headers['Enter-Referrer'] = analytics.enterReferrer

  config.headers['Partner-ID'] = analytics.partnerId
  config.headers['Partner-GA-Click-ID'] = analytics.partnerGaClickId
  config.headers['Partner-GA-Gbraid'] = analytics.partnerGaGbraid
  config.headers['Partner-GA-Wbraid'] = analytics.partnerGaWbraid

  return config
})

router.isReady().then(() => {
  const queryParams = router.currentRoute.value.query

  const gclid = queryParams.gclid
  const gbraid = queryParams.gbraid
  const wbraid = queryParams.wbraid

  if (gclid || gbraid || wbraid) {
    store.commit('SET_ANALYTICS_VARIABLE', { key: 'gaClickId', val: gclid })
    store.commit('SET_ANALYTICS_VARIABLE', { key: 'gaGbraid', val: gbraid })
    store.commit('SET_ANALYTICS_VARIABLE', { key: 'gaWbraid', val: wbraid })
  }

  const partnerId = queryParams.partner_id

  if (partnerId) {
    store.commit('SET_ANALYTICS_VARIABLE', { key: 'partnerId', val: partnerId })
  }

  const partnerGclid = queryParams.partner_gclid
  const partnerGbraid = queryParams.partner_gbraid
  const partnerWbraid = queryParams.partner_wbraid

  if (partnerGclid || partnerGbraid || partnerWbraid) {
    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'partnerGaClickId',
      val: partnerGclid,
    })
    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'partnerGaGbraid',
      val: partnerGbraid,
    })
    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'partnerGaWbraid',
      val: partnerWbraid,
    })
  }

  const utmParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
  ]

  const utm = {}

  for (const utmParam of utmParams) {
    if (queryParams[utmParam]) {
      utm[utmParam] = queryParams[utmParam]
    }
  }

  if (Object.keys(utm).length) {
    store.commit('SET_UTM', utm)
  }
})

// TODO: Need to find a way to readd this without spamming our analytics
// document.addEventListener('visibilitychange', function () {
//   if (document.hidden) {
//     mixpanel.track('page_exit')
//     hj('event', 'page_exit')
//   } else {
//     mixpanel.track('page_enter')
//     hj('event', 'page_enter')
//   }
// })

// Disable right clicking on production so they can't download images
// and spam the debugger to annoy users opening the console
if (import.meta.env.VITE_ENV === 'production') {
  document.addEventListener('contextmenu', (event) => event.preventDefault())

  setInterval(() => {
    debugger
  }, 3000)
}

function loadRecaptcha() {
  const script = document.createElement('script')
  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${import.meta.env.VITE_RECAPTCHA_KEY}`
  document.body.appendChild(script)
}

window.addEventListener('load', () => {
  Promise.all([
    loadRecaptcha(),
    import('vue-loading-overlay/dist/css/index.css'),
    import('vue-toast-notification/dist/theme-default.css'),
  ])
})

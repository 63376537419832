<template>
  <DiscountPopup
    v-if="user && discountPopupOpen"
    @update:open="discountPopupOpenUpdated"
  ></DiscountPopup>
  <navigation-menu v-if="!warn.banned" />
  <router-view v-if="!warn.banned" />
  <p
    class="flex justify-center items-center text-2xl font-bold h-full"
    v-if="warn.banned"
  >
    This service is no longer available
  </p>
  <Popup
    v-if="warn.warned"
    :open="warn.warned"
    @update:open="warn.toggleWarned"
    popupStyle="w-4/5 h-1/2"
  >
    <div class="flex justify-center items-center text-2xl font-bold h-full">
      <h2 class="pt-6">You've been warned</h2>
    </div>
  </Popup>
  <Confirm18 />
  <SubscribePopup
    v-if="user && !user.subscription"
    class="sticky z-50"
    :open="subscribePopupOpen"
    :text="subscribePopupText"
    @update:open="subscribePopupOpenUpdated"
  ></SubscribePopup>
  <LoginRegisterPopup
    :open="loginRegisterPopupOpen"
    @update:open="loginRegisterPopupOpenUpdated"
  />
  <NoBalancePopup
    :open="noBalancePopupOpen"
    @update:open="noBalancePopupOpenUpdated"
  />
</template>

<script setup>
import NavigationMenu from '@/components/NavigationMenu.vue'
import SubscribePopup from '@/components/SubscribePopup.vue'
import DiscountPopup from '@/components/DiscountPopup.vue'
import LoginRegisterPopup from '@/components/LoginRegisterPopup.vue'
import Confirm18 from '@/components/Confirm18.vue'
import { useHead } from '@unhead/vue'
import { computed, onMounted, onBeforeUnmount, ref, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useLogger } from 'vue-logger-plugin'
import { useLogsStore } from '@/stores/logs'
import { useWarnStore } from '@/stores/warn'
import Popup from '@/components/Popup.vue'
import NoBalancePopup from '@/components/NoBalancePopup.vue'

const $router = useRouter()
const $route = useRoute()
const $store = useStore()
const log = useLogger()
const warn = useWarnStore()
log.info('Frontend logger has been set')
log.info(
  'Each time application comes to one of these statements it will put it in store (and log it to console in that order)',
)

const timer = ref()
const data = useLogsStore()
const $axios = inject('axios')
function sendLogs() {
  try {
    $axios.post('/log', {
      data: data.logs,
    })
    // clear log state
    data.logs = []
  } catch (e) {
    console.log(e)
  }
}

onMounted(() => {
  timer.value = setInterval(() => {
    sendLogs()
  }, 20000)
})

// Clean up
onBeforeUnmount(() => {
  clearInterval(timer.value)
})

const discountPopupOpen = computed(() => $store.state.discountPopupOpen)

function discountPopupOpenUpdated(val) {
  $store.commit('SET_DISCOUNT_POPUP', {
    open: val,
  })
}

const user = computed(() => $store.state.user)

const loginRegisterPopupOpen = computed(
  () => $store.state.loginRegisterPopupOpen,
)

function loginRegisterPopupOpenUpdated(val) {
  $store.commit('SET_LOGIN_REGISTER_POPUP_OPEN', {
    open: val,
  })
}

const subscribePopupOpen = computed(() => $store.state.subscribePopupOpen)
const subscribePopupText = computed(() => $store.state.subscribePopupText)
function subscribePopupOpenUpdated(val) {
  $store.commit('SET_SUBSCRIBE_POPUP', {
    open: val,
  })
}

const noBalancePopupOpen = computed(() => $store.state.noBalancePopupOpen)
function noBalancePopupOpenUpdated(val) {
  $store.commit('SET_NO_BALANCE_POPUP', {
    open: val,
  })
}

useHead({
  link: () => [
    {
      rel: 'canonical',
      href: `https://${import.meta.env.VITE_MASTER_DOMAIN_NAME}${$router.resolve($route.name ? { name: $route.name } : $route).path}`,
    },
  ],
})
</script>

<style>
/* Your styles for App.vue go here */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

*::-webkit-scrollbar-corner,
*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(85, 85, 85);
  border-radius: 15px;
}
</style>

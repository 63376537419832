import { defineStore, acceptHMRUpdate } from 'pinia'

export const useSplitTestStore = defineStore('splitTest', {
  state: () => ({
    cache: {},
  }),
  actions: {
    generateNumber(useCase) {
      if (!this.cache[useCase]) {
        this.cache[useCase] = Math.random()
      }

      return this.cache[useCase]
    },
    yesno(useCase) {
      return this.generateNumber(useCase) > 0.5
    },
    shuffleArray(useCase, array) {
      const result = array.slice() // Create a copy of the array

      for (let i = result.length - 1; i > 0; i--) {
        const randomNumber = this.generateNumber(`${useCase}-${i}`)
        const j = Math.floor(randomNumber * (i + 1))
        ;[result[i], result[j]] = [result[j], result[i]]
      }

      return result
    },
    pickFromArray(useCase, array) {
      const randomNumber = this.generateNumber(useCase)
      const index = Math.floor(randomNumber * array.length)
      return array[index]
    },
  },
  persist: {
    storage: localStorage,
    paths: ['cache'],
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSplitTestStore, import.meta.hot))
}

import { defineStore, acceptHMRUpdate } from 'pinia'

export const useLogsStore = defineStore('logs', {
  state: () => ({
    logs: [],
  }),
  actions: {
    addLog(newLog) {
      this.logs.push(newLog)
    },
  },
  persist: {
    storage: sessionStorage,
    paths: ['logs'],
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLogsStore, import.meta.hot))
}

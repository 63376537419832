import { defineStore, acceptHMRUpdate } from 'pinia'

export const useConfirm18Store = defineStore('confirm18', {
  state: () => ({
    confirm18Status: false,
  }),
  actions: {
    confirm18() {
      this.confirm18Status = true
    },
  },
  persist: {
    paths: ['confirm18Status'],
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConfirm18Store, import.meta.hot))
}

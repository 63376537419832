<script setup>
import { ref, inject, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toast-notification'
import { useLoading } from 'vue-loading-overlay'
import gmail from '@/assets/icons/gmail.svg'
import icloud from '@/assets/icons/icloud.svg'
import outlook from '@/assets/icons/outlook.svg'
import yahoo from '@/assets/icons/yahoo.svg'
import Button from './Button.vue'

const $store = useStore()
const $toast = useToast()
const $axios = inject('axios')
const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
  color: '#a15cff',
})

const emit = defineEmits(['finishRegister', 'openEmailDialog'])

const resendCooldown = ref(null)
let interval

const d1Ref = ref(null)
const d2Ref = ref(null)
const d3Ref = ref(null)
const d4Ref = ref(null)

const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

onMounted(() => {
  d1Ref.value.focus()
})

const handlePress = (e) => {
  if (!keysAllowed.includes(e.key)) {
    return e.preventDefault()
  }

  e.target.value = e.key

  const nextSibling = e.target.nextSibling

  if (nextSibling) {
    e.preventDefault()
    nextSibling.focus()
  } else {
    e.target.blur()
  }
}

const handleDelete = (e) => {
  if (e.target.value !== '') {
    return (e.target.value = '')
  }

  const previousSibling = e.target.previousSibling

  if (previousSibling) {
    e.preventDefault()
    previousSibling.focus()
    previousSibling.value = ''
  }
}

const handlePaste = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData
  const pastedText = clipboardData.getData('text').trim()

  if (pastedText.split('').some((c) => isNaN(c)) || pastedText === '') {
    return e.preventDefault()
  }

  d1Ref.value.value = pastedText[0] || ''
  d2Ref.value.value = pastedText[1] || ''
  d3Ref.value.value = pastedText[2] || ''
  d4Ref.value.value = pastedText[3] || ''

  e.target.blur()
}

const handleFilterLetters = (e) => {
  if (!keysAllowed.includes(e.key)) {
    e.target.value = ''
    e.preventDefault()
  }
}

async function verifyEmail(otp) {
  const loader = $loading.show()

  try {
    const data = await $axios({
      method: 'post',
      url: '/email-validation/validate',
      data: {
        otp,
      },
    }).then((res) => res.data)

    const user = $store.state.user
    $store.dispatch('setUser', {
      ...user,
      emailValid: data.emailValid,
    })
    emit('finishRegister')
  } catch (e) {
    const message = e?.response?.data?.message
    $toast.error(message || 'Unable to perform action. Please try again later.')
    d1Ref.value.value = ''
    d2Ref.value.value = ''
    d3Ref.value.value = ''
    d4Ref.value.value = ''
  } finally {
    loader.hide()
  }
}

async function resendEmail() {
  const loader = $loading.show()

  try {
    const res = await $axios({
      method: 'post',
      url: '/email-validation/resend',
    })
    const message = res.data.message
    $toast.success(message)
    resendCooldown.value = 30
    interval = setInterval(() => {
      if (resendCooldown.value > 0) {
        resendCooldown.value--
        if (resendCooldown.value === 0) {
          clearInterval(interval)
          resendCooldown.value = null
        }
      }
    }, 1000)
  } catch (e) {
    const message = e?.response?.data?.message
    $toast.error(message || 'Unable to perform action. Please try again later.')
  } finally {
    loader.hide()
  }
}

const handleVerify = () => {
  const value =
    d1Ref.value.value +
    d2Ref.value.value +
    d3Ref.value.value +
    d4Ref.value.value

  if (value.length !== 4) return

  verifyEmail(value)
}

const handleResendEmail = () => {
  if (resendCooldown.value !== null) return
  resendEmail()
  resendCooldown.value = null
}

const handleOpenEmailModal = () => {
  emit('openEmailDialog', true)
}
</script>

<template>
  <div>
    <p class="text-center mb-[46px] lg:mb-[30px] text-sm text-[#B1B5DB]">
      A verification code has been sent to <br />
      {{ $store.state.user.email }}
    </p>
    <form @submit.prevent="handleVerify">
      <div class="flex justify-between mb-[56px] lg:mb-5 gap-[10px] lg:gap-4">
        <input
          ref="d1Ref"
          class="text-center w-[30%] max-w-[120px] aspect-square text-[36px] rounded-[15px] lg:rounded-[25px] border-0 bg-[#0A0D22] text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-500 sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          maxlength="1"
          @keypress="handlePress"
          @keydown.delete="handleDelete"
          @paste="handlePaste"
          @input="handleFilterLetters"
          pattern="\d*"
          type="number"
          autofocus
        />
        <input
          ref="d2Ref"
          class="text-center w-[30%] max-w-[120px] aspect-square text-[36px] rounded-[15px] lg:rounded-[25px] border-0 bg-[#0A0D22] text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-500 sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          maxlength="1"
          @keypress="handlePress"
          @keydown.delete="handleDelete"
          @paste="handlePaste"
          @input="handleFilterLetters"
          pattern="\d*"
          type="number"
        />
        <input
          ref="d3Ref"
          class="text-center w-[30%] max-w-[120px] aspect-square text-[36px] rounded-[15px] lg:rounded-[25px] border-0 bg-[#0A0D22] text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-500 sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          maxlength="1"
          @keypress="handlePress"
          @keydown.delete="handleDelete"
          @paste="handlePaste"
          @input="handleFilterLetters"
          pattern="\d*"
          type="number"
        />
        <input
          ref="d4Ref"
          class="text-center w-[30%] max-w-[120px] aspect-square text-[36px] rounded-[15px] lg:rounded-[25px] border-0 bg-[#0A0D22] text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-500 sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          maxlength="1"
          @keypress="handlePress"
          @keydown.delete="handleDelete"
          @paste="handlePaste"
          @input="handleFilterLetters"
          pattern="\d*"
          type="number"
        />
      </div>
      <div
        class="text-sm text-center mt-0 lg:mt-5 mb-[46px] lg:mb-[31px] text-[#B1B5DB]"
      >
        Didin’t received code ?
        <a
          :class="`${!resendCooldown ? 'text-[#CC47FF]' : 'text-[red] cursor-default'}`"
          @click.prevent="handleResendEmail"
          href="#"
        >
          Send again
        </a>
        or
        <span v-if="resendCooldown">
          (00:{{
            resendCooldown < 10 ? '0' + resendCooldown : resendCooldown
          }})</span
        >
        <br />
        <a class="text-[#CC47FF]" @click.prevent="handleOpenEmailModal" href="#"
          >Change email address</a
        >
      </div>
      <Button
        type="submit"
        class="flex w-full justify-center py-[14px] font-medium"
      >
        Verify
      </Button>
    </form>
    <div class="mt-[10px] flex flex-col gap-4">
      <Button
        variant="secondary"
        :borderVisible="false"
        class="py-[14px] block lg:hidden"
        href="mailto:"
        >Open Mail</Button
      >
      <a
        class="w-full py-5 rounded-[15px] border border-[#141A3D] hidden sm:flex items-center justify-center gap-4"
        href="https://gmail.com"
        rel="noopener"
        target="_blank"
      >
        <img class="h-8 w-8" :src="gmail" />
        <div class="w-[105px]">Open Gmail</div>
      </a>
      <a
        class="w-full py-5 rounded-[15px] border border-[#141A3D] hidden sm:flex items-center justify-center gap-4"
        href="https://yahoo.com"
        rel="noopener"
        target="_blank"
      >
        <img class="h-8 w-8" :src="yahoo" />
        <div class="w-[105px]">Open Yahoo</div>
      </a>
      <a
        class="w-full py-5 rounded-[15px] border border-[#141A3D] hidden sm:flex items-center justify-center gap-4"
        href="https://outlook.com"
        rel="noopener"
        target="_blank"
      >
        <img class="h-8 w-8" :src="outlook" />
        <div class="w-[105px]">Open Outlook</div>
      </a>
      <a
        class="w-full py-5 rounded-[15px] border border-[#141A3D] hidden sm:flex items-center justify-center gap-4"
        href="https://www.icloud.com"
        rel="noopener"
        target="_blank"
      >
        <img class="h-8 w-8" :src="icloud" />
        <div class="w-[105px]">Open iCloud</div>
      </a>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue'
import cdnFile from '@/helpers/cdnFile'
import RegisterModal from '@/components/RegisterModal.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useHead } from '@unhead/vue'
import authParticles from '@/assets/img/auth-particles.svg'
import { useMixpanel } from '@/composables/mixpanel'
import logo from '@/assets/logo.svg'

useHead({
  title: `${import.meta.env.VITE_BRAND_NAME} | Register`,
})

const mixpanel = useMixpanel()

mixpanel.track('register_page_visit', {}, { source: 'previous' })

const $router = useRouter()
const $store = useStore()

const user = computed(() => $store.state.user)
function onRegistered() {
  if (user.value.models.length) {
    $router.push(
      $store.state.lastAppPage
        ? `/${decodeURIComponent($store.state.lastAppPage)}`
        : '/chat',
    )
  } else {
    $router.push(
      $store.state.lastAppPage
        ? `/${decodeURIComponent($store.state.lastAppPage)}`
        : '/',
    )
  }
}

onMounted(() => {
  nextTick(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  })
})
const premadeModel = computed(() => $store.state.premadeModel)

const validationOpen = ref(false)
</script>

<template>
  <div class="flex min-h-full flex-1 relative">
    <img class="absolute bottom-0 left-0 w-full" :src="authParticles" />
    <div
      class="flex flex-1 flex-col justify-center px-2 py-4 lg:py-12 sm:px-6 lg:px-20 xl:px-24"
    >
      <div
        class="relative z-20 lg:mx-auto bg-[#0A0D22] pb-[18px] pt-[63px] lg:py-[45px] px-4 lg:px-[75px] lg:w-[625px] rounded-[35px] border border-[#141A3D] border-opacity-70"
      >
        <div class="flex flex-col items-center">
          <img
            class="w-[148px] h-auto"
            :src="cdnFile('logo.svg', true)"
            alt="Logo"
          />
          <template v-if="!validationOpen">
            <h2
              class="my-[30px] lg:text-[35px] text-[30px] text-center leading-[35px] font-medium lg:leading-[31px]"
            >
              Create a Free Account
            </h2>
            <p class="text-[15px] text-[#B1B5DB]">
              Already a member?
              {{ ' ' }}
              <router-link to="/login" class="font-bold text-[#CC47FF]"
                >Login here</router-link
              >
            </p>
          </template>
          <template v-else>
            <h2
              class="my-[25px] text-[35px] lg:text-[38px] font-medium leading-9 tracking-tight text-white"
            >
              Verify your Email
            </h2>
          </template>
        </div>

        <RegisterModal
          :with-accept-policies="true"
          :model-ids="premadeModel ? [premadeModel.id] : []"
          :redirectPath="decodeURIComponent($store.state.lastAppPage)"
          @registered="onRegistered"
          @validationOpen="validationOpen = true"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import cdnFile from '@/helpers/cdnFile.js'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Button from './Button.vue'
import female from '@/assets/icons/female.svg'
import male from '@/assets/icons/male.svg'
import LunaAmount from './LunaAmount.vue'
import logo from '@/assets/logo.svg'

const $store = useStore()
const $route = useRoute()

const props = defineProps({
  gender: {
    type: String,
    default: '',
  },
  style: {
    type: String,
    default: '',
  },
})
const emits = defineEmits(['updateGender'])
const user = computed(() => $store.state.user)
const isHomePage = computed(() =>
  [
    '/',
    '/ai-boyfriend',
    '/anime-ai-girlfriend',
    '/anime-ai-boyfriend',
  ].includes($route.path),
)
const isGirlfriendRoute = computed(() =>
  ['/', '/anime-ai-girlfriend'].includes($route.path),
)
const discountPopupOpen = computed(() => $store.state.discountPopupOpen)

const handleGenderSelect = (value) => {
  emits('updateGender', value)
}
</script>

<template>
  <div
    class="w-full sticky bg-[#070917] py-4 lg:pt-[21px] pb-[12px] lg:pb-6 flex lg:items-center z-30"
    :class="discountPopupOpen ? 'top-[114px] lg:top-[64px]' : 'top-0'"
  >
    <div
      class="w-full max-w-[1450px] mx-auto px-4 xl:px-16 2xl:mx-auto flex lg:items-center flex-col-reverse lg:flex-row"
      :class="
        $slots.title || $slots.end || isHomePage
          ? 'justify-between'
          : 'justify-end'
      "
    >
      <div
        v-if="isHomePage"
        class="p-[7px] bg-[#0e122a] border-[1px] border-[#161929] lg:gap-[6px] rounded-[15px] mt-3 lg:mt-0 hidden md:flex"
      >
        <router-link
          :to="style == 'realistic' ? '/' : '/anime-ai-girlfriend'"
          class="flex-1 lg:flex-0 flex items-center justify-center lg:justify-normal gap-[10px] py-2 sm:py-[13px] px-4 sm:px-[26.5px] font-bold text-xs rounded-lg"
          :class="{
            'border border-white border-opacity-15 bg-[#2a3b5f]':
              isGirlfriendRoute,
          }"
          @click="handleGenderSelect('female')"
          ><img :src="female" />
          <h3>Female</h3></router-link
        >
        <router-link
          :to="style == 'realistic' ? '/ai-boyfriend' : '/anime-ai-boyfriend'"
          class="flex-1 lg:flex-0 flex items-center justify-center lg:justify-normal gap-[10px] py-2 sm:py-[13px] px-4 sm:px-[26.5px] font-bold text-xs rounded-lg"
          :class="{
            'border border-white border-opacity-15 bg-[#2a3b5f]':
              !isGirlfriendRoute,
          }"
          @click="handleGenderSelect('male')"
          ><img :src="male" />
          <h3>Male</h3></router-link
        >
      </div>
      <slot name="title"></slot>
      <div class="flex justify-between items-center lg:justify-normal lg:gap-3">
        <router-link to="/"
          ><img
            class="w-[130px] h-auto lg:hidden"
            :src="cdnFile('logo.svg', true)"
            alt="Logo"
            style="user-select: none; user-drag: none; pointer-events: none"
            draggable="false"
        /></router-link>
        <div v-if="user" class="flex gap-2">
          <LunaAmount />
          <slot name="end"></slot>
        </div>
        <div v-if="!user" class="flex gap-[11px]">
          <Button
            variant="secondary"
            :borderVisible="false"
            to="/login"
            class="flex justify-center items-center w-[80px] h-[34px] lg:h-[50px] lg:w-[100px] text-[14px]"
            style="border-radius: 10px; background: #0e122a"
            >Login</Button
          >
          <router-link
            to="/register"
            class="flex justify-center items-center w-[80px] h-[34px] lg:h-[50px] lg:w-[100px] text-[14px]"
            style="
              background: radial-gradient(
                255.14% 174.74% at 38.76% 155.71%,
                #cc47ff 0%,
                #9a5cff 100%
              );
              border-radius: 10px;
              border: 1px solid;
              border-image-source: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(153, 153, 153, 0.1) 100%
              );
              box-shadow:
                0px 0px 0px 1px #b852ff,
                0px -2px 0px 0px #a831fd inset;
            "
            >Register</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script setup>
import { ref, inject, computed, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import { useLoading } from 'vue-loading-overlay'
import { useToast } from 'vue-toast-notification'
import googleAuth from '@/services/google-auth'
import twitterAuth from '@/services/twitter-auth'
import GenderSelect from '@/components/GenderSelect.vue'
import ValidateEmailDialog from './ValidateEmailDialog.vue'
import Confirm from '@/components/Confirm.vue'
import checkmark from '@/assets/icons/checkmark.svg'
import Button from './Button.vue'
import { useMixpanel } from '@/composables/mixpanel'

const mixpanel = useMixpanel()

mixpanel.track('register_modal_shown')

const $axios = inject('axios')
const $store = useStore()

const loggedIn = computed(() => Boolean($store.state.user))
const dateOfEffect = new Date(2024, 12, 25)

const updateEmail = ref('')
const email = ref('')
const name = ref('')
const password = ref('')
const acceptPolicies = ref(false)
const emailOptIn = ref(true)
const acceptRequire = ref(false)

const modalOpen = ref(false)
const emailModalOpen = ref(false)

const selectedGender = ref(null)

const analytics = computed(() => $store.state.analytics)

async function generateCaptchaToken() {
  return await grecaptcha.enterprise.execute(
    import.meta.env.VITE_RECAPTCHA_KEY,
    { action: 'REGISTRATION' },
  )
}

const $toast = useToast()

const props = defineProps({
  withAcceptPolicies: Boolean,
  ctaText: String,
  modelIds: {
    type: Array,
    default: () => [],
  },
  redirectPath: String,
})

const { withAcceptPolicies, ctaText, modelIds } = toRefs(props)

function checkAcceptPolicies() {
  if (withAcceptPolicies.value && !acceptPolicies.value) {
    acceptRequire.value = true
    $toast.error('Please confirm you accept our policies')
    return false
  }
  acceptRequire.value = false
  return true
}

const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
  color: '#a15cff',
})

const emit = defineEmits(['registered', 'validationOpen'])

const utmParams = computed(() => $store.state.utm)

async function google() {
  const captchaToken = await generateCaptchaToken()

  if (!checkAcceptPolicies()) return
  window.location.href = googleAuth.getLoginUrl({
    ...analytics.value,
    captchaToken,
    modelIds: modelIds.value,
    emailOptIn: emailOptIn.value,
    ...utmParams.value,
    redirectPath: props.redirectPath,
  })
}

async function twitter() {
  const captchaToken = await generateCaptchaToken()

  if (!checkAcceptPolicies()) return
  window.location.href = twitterAuth.getLoginUrl({
    ...analytics.value,
    captchaToken,
    modelIds: modelIds.value,
    emailOptIn: emailOptIn.value,
    ...utmParams.value,
    redirectPath: props.redirectPath,
  })
}

async function register() {
  if (!checkAcceptPolicies()) return

  const loader = $loading.show()

  const captchaToken = await generateCaptchaToken()

  try {
    const user = await $axios({
      method: 'post',
      url: '/auth/signup',
      data: {
        email: email.value,
        name: name.value.split(' ')[0],
        gender: selectedGender.value.id,
        password: password.value,
        modelIds: modelIds.value,
        emailOptIn: emailOptIn.value,
      },
      params: {
        ...utmParams.value,
      },
      headers: {
        'Captcha-Token': captchaToken,
      },
    }).then((res) => res.data)

    await $store.dispatch('setUser', user)
    await $store.dispatch('setPremadeModel', null)

    if (!user.emailValid && new Date(user.createdAt) >= dateOfEffect) {
      console.log('showing stuff')
      loader.hide()
      return (modalOpen.value = true)
    }

    emit('registered')
  } catch (e) {
    console.log(e)
    const message = e?.response?.data?.message
    $toast.error(message || e.response.status)
  }

  loader.hide()
}

async function changeEmail() {
  const loader = $loading.show()

  try {
    const { message } = await $axios({
      method: 'post',
      url: '/email-validation/change',
      data: {
        email: updateEmail.value,
      },
    }).then((res) => res.data)

    const user = $store.state.user
    $store.dispatch('setUser', {
      ...user,
      email: updateEmail.value,
    })
    updateEmail.value = ''
    updateEmailModalOpen(false)
    $toast.success(message)
  } catch (e) {
    console.log(e)
    const message = e?.response?.data?.message
    $toast.error(message || 'Unable to perform action. Please try again later.')
  } finally {
    loader.hide()
  }
}

const updateEmailModalOpen = (val) => {
  emailModalOpen.value = val
}

const finishRegister = () => {
  emit('registered')
}

const validateModalShown = computed(() => {
  return modalOpen.value || loggedIn.value
})

watch(
  validateModalShown,
  (val) => {
    if (val) emit('validationOpen')
  },
  { immediate: true },
)

const brandName = import.meta.env.VITE_BRAND_NAME
</script>

<template>
  <div class="mt-[45px] lg:mt-[30px]" v-if="!validateModalShown">
    <div>
      <form
        action="#"
        method="POST"
        class="space-y-[27px]"
        @submit.prevent="register"
      >
        <div>
          <label for="first-name" class="font-medium text-white text-opacity-80"
            >First Name</label
          >
          <div class="mt-[10px]">
            <input
              id="first-name"
              v-model="name"
              name="first-name"
              type="text"
              required=""
              class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631]"
            />
          </div>
        </div>

        <div>
          <label for="gender" class="font-medium text-white text-opacity-80"
            >Gender</label
          >
          <div class="mt-[10px]">
            <GenderSelect v-model="selectedGender" />
          </div>
        </div>

        <div>
          <label for="email" class="font-medium text-white text-opacity-80"
            >Email Address</label
          >
          <div class="mt-[10px]">
            <input
              id="email"
              v-model="email"
              name="email"
              type="email"
              autocomplete="email"
              required=""
              class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631]"
            />
          </div>
        </div>

        <div>
          <label for="password" class="font-medium text-white text-opacity-80"
            >Password</label
          >
          <div class="mt-[10px]">
            <input
              id="password"
              v-model="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required=""
              class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631]"
            />
          </div>
        </div>

        <div class="flex flex-col gap-[37px] lg:gap-5">
          <div
            v-if="withAcceptPolicies"
            class="flex items-start cursor-pointer"
            @click="acceptPolicies = !acceptPolicies"
          >
            <div class="relative">
              <input
                id="comments"
                v-model="acceptPolicies"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                class="h-[22px] w-[22px] rounded-[5px] focus:border-0 focus:outline-0 focus:ring-0 focus-visible:outline-none focus-visible:border-none hover:cursor-pointer"
                :class="
                  (acceptRequire && 'ring-2 ring-red-600 checked:ring-0') || ''
                "
                style="
                  background: radial-gradient(
                    255.14% 174.74% at 38.76% 155.71%,
                    #cc47ff 0%,
                    #9a5cff 100%
                  );
                "
                @click.stop
              />
              <img
                v-if="acceptPolicies"
                class="absolute w-[11px] h-auto top-[50%] left-[50%] -translate-x-1/2 -translate-y-[25%]"
                :src="checkmark"
              />
            </div>
            <div class="ml-[10px] text-sm leading-6">
              <span id="comments-description" class="text-gray-300"
                >I have read and accept the
                <router-link
                  class="font-medium text-[#CC47FF]"
                  to="/terms-of-service"
                  @click.stop
                  >Terms of Service</router-link
                >
                and
                <router-link
                  class="font-medium text-[#CC47FF]"
                  to="/privacy-policy"
                  @click.stop
                  >Privacy Policy.</router-link
                ></span
              >
            </div>
          </div>

          <div
            class="flex items-start cursor-pointer"
            @click="emailOptIn = !emailOptIn"
          >
            <div class="flex h-6 items-center">
              <div class="relative">
                <input
                  id="comments"
                  v-model="emailOptIn"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  class="h-[22px] w-[22px] rounded-[5px] focus:border-0 focus:outline-0 focus:ring-0 focus-visible:outline-none focus-visible:border-none hover:cursor-pointer"
                  :class="
                    (acceptRequire && 'ring-2 ring-red-600 checked:ring-0') ||
                    ''
                  "
                  style="
                    background: radial-gradient(
                      255.14% 174.74% at 38.76% 155.71%,
                      #cc47ff 0%,
                      #9a5cff 100%
                    );
                  "
                  @click.stop
                />
                <img
                  v-if="emailOptIn"
                  class="absolute w-[11px] h-auto top-[50%] left-[50%] -translate-x-1/2 -translate-y-[25%]"
                  :src="checkmark"
                />
              </div>
            </div>
            <div class="ml-[10px] text-sm leading-6">
              <span id="comments-description" class="text-gray-300"
                >I want to receive discreet marketing emails from
                {{ brandName }}.</span
              >
            </div>
          </div>
        </div>
        <div>
          <Button
            type="submit"
            class="w-full py-[14px] font-medium rounded-[15px]"
          >
            {{ ctaText || 'Sign up' }}
          </Button>
        </div>
      </form>
    </div>

    <div>
      <p class="my-[37px] lg:my-[35px] text-center text-[#B1B5DB] text-[14px]">
        Or continue with
      </p>
      <div class="flex flex-col lg:flex-row gap-[9px]">
        <a
          href="#"
          class="flex justify-center items-center gap-[14px] flex-1 bg-[#0E122A] py-[26px] rounded-[15px] border border-[#141A3D]"
          @click.prevent="google"
        >
          <svg
            id="Capa_1"
            class="h-6 w-6"
            aria-hidden="true"
            fill="currentColor"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 210 210"
            xml:space="preserve"
          >
            <title>Google Logo</title>
            <path
              d="M0,105C0,47.103,47.103,0,105,0c23.383,0,45.515,7.523,64.004,21.756l-24.4,31.696C133.172,44.652,119.477,40,105,40 c-35.841,0-65,29.159-65,65s29.159,65,65,65c28.867,0,53.398-18.913,61.852-45H105V85h105v20c0,57.897-47.103,105-105,105 S0,162.897,0,105z"
            />
          </svg>
          <span class="font-medium leading-6">Google</span>
        </a>
        <a
          href="#"
          class="flex justify-center items-center gap-[14px] flex-1 bg-[#0E122A] py-[26px] rounded-[15px] border border-[#141A3D]"
          @click.prevent="twitter"
        >
          <svg
            class="h-6 w-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 300 300"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66"
            />
          </svg>
          <span class="text-sm font-semibold leading-6">Twitter</span>
        </a>
      </div>
    </div>
  </div>
  <ValidateEmailDialog
    v-else
    @finish-register="finishRegister"
    @open-email-dialog="updateEmailModalOpen"
  />
  <Confirm
    popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-4 lg:py-[75px] mx-4 lg:mx-0 lg:px-[124px]"
    :open="emailModalOpen"
    :showDefault="false"
    type="confirm"
    @update:open="updateEmailModalOpen"
  >
    <form @submit.prevent="changeEmail" class="m-1">
      <h1 class="text-center">Change your email address</h1>
      <input
        id="updateEmail"
        v-model="updateEmail"
        name="updateEmail"
        type="email"
        autocomplete="email"
        required=""
        class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631] mt-4 mb-6 lg:mb-[34px]"
      />
      <Button type="submit" class="py-3 px-6 w-full mt-2"> Confirm </Button>
    </form>
  </Confirm>
</template>

<style scoped>
input:not([type='checkbox']):focus {
  box-shadow: 0px 0px 0px 4px #cc47ff33;
}
</style>

<script setup>
import { ref, inject, computed, toRefs } from 'vue'
import { useToast } from 'vue-toast-notification'
import { useStore } from 'vuex'
import { LockClosedIcon } from '@heroicons/vue/24/outline'
import heart from '@/assets/icons/heart.svg'
import heartFilled from '@/assets/icons/heart-filled.svg'
import refresh from '@/assets/icons/refresh.svg'
import cdnFile from '@/helpers/cdnFile'
import unlock from '@/assets/icons/unlock.svg'

const $store = useStore()

const $axios = inject('axios')

const $toast = useToast()

const props = defineProps({
  message: Object,
  freeUnlock: Boolean,
  instantUnlock: Boolean,
})

const { message, freeUnlock, instantUnlock } = toRefs(props)

const user = computed(() => $store.state.user)
const messages = computed(() => $store.state.messages)
const model = computed(() => $store.getters.model)

const emit = defineEmits(['img:click', 'img:regenerate', 'update:saved'])

const loadingLimit = 15
const loading = ref(false)
const loadingCounter = ref(loadingLimit)
const loadingIntervalID = ref(0)

const loadingDisplayValue = computed(() =>
  loadingCounter.value > 0
    ? loadingCounter.value
    : loadingCounter.value > -10
      ? 'Just a few more seconds...'
      : loadingCounter.value > -20
        ? 'Getting there shortly...'
        : loadingCounter.value > -30
          ? 'Almost there...'
          : loadingCounter.value > -40
            ? 'Hang on tight...'
            : 'Any time now...',
)

function setLoading(flag) {
  loading.value = flag
  if (flag) {
    loadingCounter.value = loadingLimit
    loadingIntervalID.value = setInterval(() => {
      loadingCounter.value -= 1
    }, 1000)
  } else {
    clearInterval(loadingIntervalID.value)
  }
  console.log(loading)
}

// The isInstant logic exists because we don't want to throw an error
// right after the message is sent if the user does not have enough luna
// Instead, we want to give him the option of unlocking the message on his own
async function unlockMessage(isInstant = false) {
  if (
    !freeUnlock.value &&
    user.value.luna < message.value.lunaToUnlock - message.value.lunaPaid
  ) {
    if (!isInstant) {
      $store.commit('SET_NO_BALANCE_POPUP', {
        open: true,
      })
    }
    return
  }
  if (loading.value) return
  setLoading(true)
  let unlockedMessage
  try {
    unlockedMessage = await $axios
      .post('/user/buy-message', {
        modelId: model.value.id,
        messageId: message.value.id,
      })
      .then((response) => response.data)
  } catch (e) {
    setLoading(false)
    console.log(e)
    const message = e?.response?.data?.message
    return $toast.error(
      message || 'Unable to perform action. Please try again later.',
    )
  }

  $store.dispatch('replaceMessage', unlockedMessage)

  $store.dispatch(
    'changeLuna',
    message.value.lunaPaid - message.value.lunaToUnlock,
  )

  setLoading(false)
}

async function regenerateImage() {
  if (loading.value) return
  setLoading(true)
  const { message } = props
  let updatedMessage

  emit('img:regenerate', message.id)

  try {
    updatedMessage = await $axios
      .post('/user/regenerate-image', {
        messageId: message.id,
        modelId: model.value.id,
      })
      .then((response) => response.data.newMessage)
  } catch (e) {
    setLoading(false)
    const message = e?.response?.data?.message
    return $toast.error(
      message || 'Unable to perform action. Please try again later.',
    )
  }

  $store.dispatch('replaceMessage', updatedMessage)
  $store.dispatch('changeLuna', message.lunaPaid - message.lunaToUnlock)

  setLoading(false)
}

if (
  instantUnlock.value &&
  message.value.lunaToUnlock - message.value.lunaPaid > 0
) {
  unlockMessage(true)
}
</script>

<template>
  <template v-if="message.lunaPaid < message.lunaToUnlock">
    <div
      class="cursor-pointer relative flex justify-center items-center bg-gray-400 rounded-xl aspect-[832/1216] my-2 w-full max-w-[512px] min-w-[150px]"
      @click="unlockMessage(false)"
    >
      <div
        v-if="loading"
        class="absolute inset-x-0 inset-y-0 loading-container rounded-xl flex flex-col items-center justify-center z-10"
      >
        <div
          class="relative w-[50px] h-[50px] rounded-full border-[8px] border-[#A15CFF] border-opacity-30"
        >
          <div
            class="h-[50px] w-[50px] absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 rounded-full"
            style="
              box-shadow:
                0px 0px 10px 0px #cc47ff,
                0px 0px 10px 0px #cc47ff inset;
            "
          >
            <div class="loader w-full h-full"></div>
          </div>
        </div>
        <div class="text-center text-lg font-semibold mt-3 px-1">
          {{ loadingDisplayValue }}
        </div>
      </div>
      <div
        v-else
        class="flex flex-col items-center px-3 text-black font-semibold"
      >
        <LockClosedIcon class="w-8 h-8" />
        <div class="mt-3">
          {{
            !freeUnlock ? `Unlock for ${message.lunaToUnlock} Luna` : `Unlock`
          }}
        </div>
      </div>
    </div>
    <!--    <div @click="unlockMessage" class="cursor-pointer max-w-[512px]">{{ message.content }}</div>-->
  </template>
  <template v-else>
    <div
      v-if="loading"
      class="absolute inset-x-0 inset-y-0 loading-container rounded-xl flex flex-col items-center justify-center z-10"
    >
      <div
        class="relative w-[50px] h-[50px] rounded-full border-[8px] border-[#A15CFF] border-opacity-30"
      >
        <div
          class="h-[50px] w-[50px] absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 rounded-full"
          style="
            box-shadow:
              0px 0px 10px 0px #cc47ff,
              0px 0px 10px 0px #cc47ff inset;
          "
        >
          <div class="loader w-full h-full"></div>
        </div>
      </div>

      <div class="text-center text-lg font-semibold mt-3 px-1">
        {{ loadingDisplayValue }}
      </div>
    </div>
    <template v-if="message.lunaToUnlock > 0">
      <div
        class="absolute left-full top-0 ml-2 lg:ml-[17px] lg:flex flex-col gap-[15px] bg-[#171B3A] pt-3 pb-3 px-[10px] w-[40px] rounded-[100px]"
      >
        <template
          v-if="
            !freeUnlock &&
            !loading &&
            message.regenerateCount < 1 &&
            messages[messages.length - 1].id == message.id &&
            !message.saved
          "
        >
          <button @click="regenerateImage">
            <img :src="refresh" />
          </button>
        </template>
        <button @click="$emit('update:saved', true)" v-if="!message.saved">
          <img :src="heart" />
        </button>
        <button @click="$emit('update:saved', false)" v-else>
          <img :src="heartFilled" />
        </button>
      </div>
      <div
        class="overflow-hidden rounded-xl cursor-pointer relative"
        @click="() => emit('img:click')"
      >
        <img
          :src="cdnFile(message.image)"
          class="p-0. py-15 rounded-xl w-full max-w-[512px]"
          :style="
            loading
              ? 'filter: brightness(0.2)'
              : freeUnlock
                ? 'filter: blur(8px) brightness(0.4); transform: scale(1.03);'
                : ''
          "
          style="user-select: none; user-drag: none; pointer-events: none"
          draggable="false"
        />
        <button
          v-if="freeUnlock"
          class="bg-purple-500 text-white rounded-md py-2 px-3.5 text-center text-sm leading-6 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex gap-1 items-center font-bold text-nowrap min-w-[100px]"
        >
          <img :src="unlock" /> Unlock
        </button>
      </div>
    </template>
    <template v-else>
      <div
        class="overflow-hidden rounded-xl my-2 cursor-pointer relative"
        @click="() => emit('img:click')"
      >
        <img
          :src="cdnFile(message.image)"
          class="p-0.5 rounded-xl w-full max-w-[512px]"
          :style="loading ? 'filter: brightness(0.2)' : ''"
          style="user-select: none; user-drag: none; pointer-events: none"
          draggable="false"
        />
      </div>
    </template>
  </template>
</template>

<style scoped>
.loading-container {
  background-color: #0a0d1e;
}
</style>

<script setup>
import { ref, computed, inject, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import { getPricing } from 'common/lib/pricing'
import cdnFile from '@/helpers/cdnFile.js'
import { useMixpanel } from '@/composables/mixpanel'
import LunaPackage from './components/LunaPackage.vue'
import Button from '@/components/Button.vue'
import PaymentForm from '@/components/PaymentForm.vue'
import star from '@/assets/icons/star.svg'
import recording from '@/assets/icons/recording.svg'
import recieveImage from '@/assets/icons/recieve-image.svg'
import upload from '@/assets/icons/upload.svg'
import gift from '@/assets/icons/gift.svg'
import arrowLeft from '@/assets/icons/arrow-left.svg'
import visa from '@/assets/icons/visa.svg'
import mastercard from '@/assets/icons/mastercard.svg'
import visaVerified from '@/assets/icons/visa-verified.svg'
import mastercardSecure from '@/assets/icons/mastercard-secure.svg'
import rta from '@/assets/icons/rta.svg'
import logo from '@/assets/logo.svg'
import logoicon from '@/assets/logoicon.svg'

useHead({
  title: `${import.meta.env.VITE_BRAND_NAME} | Buy Luna`,
})

const pricingInfo = getPricing(
  import.meta.env.VITE_ENV === 'production',
).purchases

const descriptor = import.meta.env.VITE_DESCRIPTOR

const $store = useStore()
const $router = useRouter()
const mixpanel = useMixpanel()

const currency = computed(() =>
  $store.state.user?.currency ? $store.state.user?.currency : 'USD',
)

const costsInfo = [
  {
    name: 'Voice Message',
    price: '1',
    icon: recording,
  },
  {
    name: 'Generate Image',
    price: '10',
    icon: recieveImage,
  },
  {
    name: 'Send Image',
    price: '20',
    icon: upload,
  },
  {
    name: 'Send Gift',
    price: '20 - 180',
    icon: gift,
  },
]

const isChangeCard = ref(false)
const paymentStep = ref(false)
const selectedPackage = ref(
  typeof $store.state.buyLunaPurchaseNumber === 'number'
    ? $store.state.buyLunaPurchaseNumber
    : 1,
)

const discountPopupOpen = computed(() => $store.state.discountPopupOpen)
const discount = computed(() => $store.state.discountCode)

onMounted(() => {
  if (typeof $store.state.buyLunaPurchaseNumber === 'number') {
    handleNextStep(true)
  } else {
    mixpanel.track('buy_luna_page_visit', {}, { source: 'previous' })
  }
})

const handleBack = () => {
  $store.commit('SET_BUY_LUNA_PURCHASE_NUMBER', null)

  if (paymentStep.value) paymentStep.value = false
  else $router.back()

  window.scrollTo({ top: 0 })
}

const handleNextStep = (fromRedirect) => {
  $store.commit('SET_BUY_LUNA_PURCHASE_NUMBER', selectedPackage.value)
  paymentStep.value = true
  isChangeCard.value = false

  window.scrollTo({ top: 0 })

  if (fromRedirect) return
  mixpanel.track('buy_luna_click_package', {
    package: selectedPackage.value + 1,
    originalPrice: parseFloat(pricingInfo[selectedPackage.value].amount),
    lunas: pricingInfo[selectedPackage.value].luna,
    currency: currency.value,
  })
}

$store.commit('UPDATE_LAST_APP_PAGE')

const corpName = import.meta.env.VITE_CORP_NAME
const corpAddress = import.meta.env.VITE_CORP_ADDRESS
const supportPhone = import.meta.env.VITE_SUPPORT_PHONE
</script>

<template>
  <div
    class="w-full px-4 h-full xl:px-[18px] py-[21px] xl:py-[19px] relative"
    :class="discountPopupOpen ? 'mt-[115px] xl:mt-[64px]' : ''"
  >
    <div class="flex min-h-full flex-col xl:flex-row w-full">
      <div
        class="flex flex-col xl:pt-[39px] xl:pb-[12px] xl:w-[755px] xl:bg-[#0A0D1E] rounded-[25px] xl:border border-[#161C3B]"
      >
        <div class="xl:mx-[44px]">
          <div class="flex items-center gap-[19px] mb-[49px] xl:mb-[77px]">
            <img
              :src="cdnFile('logo.svg', true)"
              class="w-[133px] xl:w-[152px] h-auto"
            />
            <div
              class="flex gap-[10px] py-[7px] pl-[9px] pr-[14px] rounded-[100px] border border-white border-opacity-15"
              style="
                background: radial-gradient(
                  255.14% 174.74% at 38.76% 155.71%,
                  #cc47ff 0%,
                  #9a5cff 100%
                );
                box-shadow:
                  0px 0px 3px 0px #1cae6e,
                  0px 0px 0px 1.18px #b552ff;
              "
            >
              <img class="h-5 w-5" :src="star" />
              <span class="font-medium leading-[21px]">Pro</span>
            </div>
          </div>
          <div v-if="!paymentStep">
            <p class="text-[25px] font-medium leading-[22px] mb-8">
              Add Luna to your Account
            </p>
          </div>
          <div v-else>
            <p class="text-[25px] font-medium leading-[22px] mb-8">
              Your Selected Package
            </p>
            <LunaPackage
              class="max-w-[100%] mb-6"
              v-bind="pricingInfo[selectedPackage]"
              :selected="selectedPackage"
            />
          </div>
          <p
            class="text-[19px] font-medium mb-4 leading-[23px]"
            :class="{ 'hidden lg:block': paymentStep }"
          >
            Costs:
          </p>
          <div
            class="mb-7 xl:mb-[58px]"
            :class="{ 'hidden lg:block': paymentStep }"
          >
            <div
              v-for="(item, index) in costsInfo"
              :key="item.name"
              class="bg-[#0E122A] flex justify-between items-center py-4 pl-[17px] pr-[22px] border-x border-[#161C3B]"
              :class="
                index === 0
                  ? 'rounded-[15px] border rounded-b-none'
                  : index === costsInfo.length - 1
                    ? 'rounded-[15px] rounded-t-none border border-t-0'
                    : 'border border-t-0'
              "
            >
              <div class="flex items-center">
                <img :src="item.icon" />
                <p class="text-[14px] font-medium ml-3">{{ item.name }}</p>
              </div>
              <div class="flex">
                <img class="mr-[10px] w-[25px] h-auto" :src="logoicon" />
                <p class="text-[14px] font-medium">{{ item.price }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-auto">
          <div
            class="flex flex-col gap-2 text-sm font-medium mb-[31px] ml-[46px]"
          >
            <p>{{ corpName }}</p>
            <p>{{ corpAddress }}</p>
            <p>
              Charges will appear on your statement as: {{ descriptor }}
              {{ supportPhone }}
            </p>
          </div>
          <div class="hidden xl:block">
            <div class="flex ml-[46px] gap-[10px] mb-10">
              <div
                class="flex justify-center items-center w-[54px] h-[35px] bg-[#161832] rounded-[6px]"
              >
                <img
                  class="h-auto"
                  :src="cdnFile('card-brands/visa.svg')"
                  alt="Visa Logo"
                />
              </div>
              <div
                class="flex justify-center items-center w-[54px] h-[35px] bg-[#161832] rounded-[6px]"
              >
                <img
                  :src="cdnFile('card-brands/mastercard.svg')"
                  alt="MasterCard Logo"
                />
              </div>
              <div
                class="flex justify-center items-center w-[54px] h-[35px] bg-[#161832] rounded-[6px]"
              >
                <img :src="cdnFile('rta.gif')" alt="RTA Logo" />
              </div>
              <div
                class="flex justify-center items-center w-[54px] h-[35px] bg-[#161832] rounded-[6px]"
              >
                <img
                  :src="cdnFile('card-brands/vbv.svg')"
                  alt="Verified By Visa Logo"
                />
              </div>
              <div
                class="flex justify-center items-center w-[54px] h-[35px] bg-[#161832] rounded-[6px]"
              >
                <img
                  class="w-[50px]"
                  :src="cdnFile('card-brands/ms.svg')"
                  alt="MasterCard SecureCode Logo"
                />
              </div>
            </div>
          </div>

          <div
            class="hidden xl:flex justify-center items-center gap-[31px] bg-[#0E122A] py-8 rounded-[15px] mx-[11px]"
          >
            <router-link to="/terms-of-service" class="text-[14px] font-medium"
              >Terms of Service</router-link
            >
            <router-link to="/privacy-policy" class="text-[14px] font-medium"
              >Privacy Policy</router-link
            >
            <router-link
              to="/cancellation-policy"
              class="text-[14px] font-medium"
              >Cancellation Policy</router-link
            >
          </div>
        </div>
      </div>
      <div
        class="flex flex-col justify-center xl:items-center flex-1 xl:mx-10 2xl:mx-0 mb-4"
      >
        <Button
          variant="secondary"
          :borderVisible="false"
          class="flex gap-[10px] p-3 xl:py-[13px] xl:px-[17px] xl:rounded-[100px] absolute top-[15px] xl:top-[32px] right-6 xl:right-[38px]"
          @click="handleBack"
        >
          <img :src="arrowLeft" />
          <span class="text-[14px] font-medium hidden xl:inline">Go back</span>
        </Button>
        <div v-if="!paymentStep">
          <p class="text-[25px] font-medium leading-[22px] mb-2">
            Buy more Luna
          </p>
          <p
            class="text-[14px] text-white text-opacity-70 font-medium mb-[35px]"
          >
            Select your package and proceed to payment
          </p>
          <div class="flex flex-col gap-[15px]">
            <LunaPackage
              v-for="(p, i) in pricingInfo"
              v-bind="p"
              :key="p.name"
              :i="i"
              :selected="selectedPackage === i"
              @click="selectedPackage = i"
            />
          </div>
          <button
            @click="handleNextStep"
            class="flex justify-center items-center gap-[15px] py-[15px] w-full mt-[35px] rounded-[15px] border border-white border-opacity-15"
            style="
              background: radial-gradient(
                255.14% 174.74% at 38.76% 155.71%,
                #cc47ff 0%,
                #9a5cff 100%
              );
              box-shadow:
                0px 0px 3px 0px #1cae6e,
                0px 0px 0px 1.18px #b552ff;
            "
          >
            <img :src="star" />
            Next: Payment
          </button>
        </div>
        <div class="lg:w-[743px]" v-else>
          <PaymentForm :discount="discount" :purchaseNumber="selectedPackage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import cdnFile from '@/helpers/cdnFile.js'
import { watch, toRefs } from 'vue'
import { useMixpanel } from '@/composables/mixpanel'
import Popup from './Popup.vue'
import Button from './Button.vue'
import logo from '@/assets/logo.svg'

const props = defineProps({
  open: Boolean,
})
const { open } = toRefs(props)

const emit = defineEmits(['update:open'])

const mixpanel = useMixpanel()

watch(open, (val) => {
  if (val) {
    mixpanel.track('open_login_register_popup', {}, { source: 'current' })
  }
})
</script>

<template>
  <Popup
    class="sticky z-40"
    :open="open"
    :mobile="false"
    popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-4 lg:py-[74px] mx-4 lg:mx-0"
    @update:open="emit('update:open', $event)"
  >
    <div>
      <div class="flex flex-col items-center gap-[25px]">
        <img class="w-[148px] h-auto" :src="cdnFile('logo.svg', true)" />
        <h2 class="text-[35px] font-medium leading-[31px]">
          Create a Free Account
        </h2>
        <p for="gender" class="text-[#B1B5DB]">
          Please login or register to use this feature
        </p>
      </div>
      <div
        class="flex justify-center mt-6 m-[1px] lg:mt-[34px] text-sm font-semibold gap-x-[18px]"
      >
        <Button
          to="/login"
          variant="secondary"
          :borderVisible="false"
          class="flex justify-center items-center py-[14px] w-[180px]"
          @click="$emit('update:open', false)"
          >Login</Button
        >
        <Button
          to="/register"
          class="flex justify-center items-center py-[14px] w-[180px]"
          @click="$emit('update:open', false)"
          >Signup</Button
        >
      </div>
    </div>
  </Popup>
</template>

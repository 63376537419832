<script setup>
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  inject,
  watch,
  defineEmits,
  defineExpose,
} from 'vue'
import { useStore } from 'vuex'
import { useLoading } from 'vue-loading-overlay'
import { useRouter } from 'vue-router'
import { Form, Field, ErrorMessage } from 'vee-validate'
import queryString from 'query-string'
import countryList from 'country-list'
import zipState from 'zip-state'
import * as yup from 'yup'
import { getPricing } from 'common/lib/pricing'
import { useMixpanel } from '@/composables/mixpanel'
import Button from './Button.vue'
import { useTempPaymentData } from '@/stores/tempPaymentData.js'
import { usePaymentError } from '@/stores/paymentError'
import states from '@/assets/data/states.json'
import star from '@/assets/icons/star.svg'
import refresh from '@/assets/icons/refresh-2.svg'
import mastercard from '@/assets/icons/mastercard.svg'
import visa from '@/assets/icons/visa.svg'

const {
  type,
  subscriptionType,
  discount,
  purchaseNumber,
  cardChangeDefault,
  cardChangePreventDefault,
} = defineProps({
  type: String,
  subscriptionType: Number,
  purchaseNumber: Number,
  discount: String,
  cardChangeDefault: {
    type: Boolean,
    default: false,
  },
  cardChangePreventDefault: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['completed'])

const $axios = inject('axios')
const $store = useStore()
const $router = useRouter()
const $loading = useLoading({
  canCancel: false,
})

const tempDataStore = useTempPaymentData()
const paymentErrorStore = usePaymentError()

let autocomplete
const mixpanel = useMixpanel()
const utmParams = $store.state.utm
const pricing = getPricing(import.meta.env.VITE_ENV !== 'dev')

const getCardBrand = (card) => {
  const { setFieldValue } = formRef.value
  if (!card) return setFieldValue('cardBrand', undefined)

  if (/^4/.test(card)) {
    setFieldValue('cardBrand', 'VISA')
    return 'VISA'
  } else if (/^5[1-5]/.test(card) || /^2[2-7][2-9]/.test(card)) {
    setFieldValue('cardBrand', 'MASTER')
    return 'MASTER'
  } else if (/^6(?:011|22[1-9]|4[4-9]|5)/.test(card)) {
    setFieldValue('cardBrand', 'DISCOVER')
    return 'DISCOVER'
  }

  setFieldValue('cardBrand', undefined)
  return undefined
}

const isChangingCard = ref(cardChangeDefault)
const isAutocompleteFocused = ref(false)
const autocompleteBlurred = ref(false)
const formRef = ref(null)
const showAdditionalFields = ref(false)
const autocompleteRef = ref(null)
const paymentError = ref(null)
const iframeContainer = ref(null)
const cardBrand = ref(null)
const cardNumber = ref('')
const cvv = ref('')
const cvvError = ref(null)

defineExpose({
  isChangingCard,
})

watch(cardNumber, (newVal) => {
  cardBrand.value = getCardBrand(newVal)
})

watch(paymentError, (newVal) => {
  if (!newVal) return
  window.scrollTo(0, document.body.scrollHeight)
})

const handleChange = (e) => {
  cardNumber.value = e.target.value
}

const user = computed(() => $store.state.user)
const storedPaymentError = computed(() => paymentErrorStore.paymentError)
const countries = computed(() =>
  countryList.getData().sort((a, b) => (a.name < b.name ? -1 : 1)),
)

const insertManualEnterButton = () => {
  const button = document.createElement('button')
  button.textContent = "I don't see my address..."
  button.classList.add(
    'bg-[#15173a]',
    'block',
    'w-full',
    'text-left',
    'pl-4',
    'py-4',
    'absolute',
    'left-0',
    'bottom-0',
    'translate-y-[100%]',
    'hover:bg-[darkslateblue]',
    'text-sm',
    'text-purple-500',
  )

  const { setFieldValue } = formRef.value

  button.addEventListener('mousedown', (e) => {
    e.stopPropagation()
    showAdditionalFields.value = true
    setFieldValue('billingAddress', autocompleteRef.value.value)
    setFieldValue('billingCountry', user.value.countryCode)
    mixpanel.track('payment_form_billing_address_select', {
      type: 'manual',
      street_address_autofilled: !!autocompleteRef.value.value,
      country_autofilled: !!user.value.countryCode,
      country: user.value.countryCode,
    })
  })

  const pacContainer = document.querySelector('.pac-container')
  pacContainer.classList.add('relative', 'overflow-visible', 'mb-[30px]')
  pacContainer.appendChild(button)
}

const handleFieldFocus = (fieldName, setTouched) => {
  setTouched && setTouched(false)
  mixpanel.track(`payment_form_${fieldName}_field_focus`)
}

const handleFieldChange = (fieldName) => {
  mixpanel.track(`payment_form_${fieldName}_field_edit`)
}

const handlePopulateFields = (result) => {
  const { values, setFieldValue } = formRef.value

  result.forEach((result) => {
    const {
      short_name: name,
      types: [type],
    } = result

    switch (type) {
      case 'street_number':
        setFieldValue('billingAddress', name)
        return
      case 'route':
        const address = values.billingAddress
        setFieldValue(
          'billingAddress',
          address ? `${values.billingAddress} ${name}` : name,
        )
        return
      case 'locality':
        setFieldValue('billingCity', `${name}`)
        return
      case 'neighborhood':
        if (values.billingCity) return
        setFieldValue('billingCity', `${name}`)
        return
      case 'sublocality_level_1':
        if (values.billingCity) return
        setFieldValue('billingCity', `${name}`)
        return
      case 'administrative_area_level_1':
        setFieldValue('billingState', name)
        if (values.billingCity) return
        setFieldValue('billingCity', `${name}`)
        return
      case 'postal_code':
        setFieldValue('billingZipCode', name)
        return
      case 'country':
        setFieldValue('billingCountry', name)
        return
    }
  })

  const shouldPassBillingState =
    values.billingState && values.billingCountry === 'US'

  mixpanel.track('payment_form_billing_address_select', {
    type: 'autofill',
    street_address_autofilled: !!values.billingAddress,
    city_autofilled: !!values.billingCity,
    ...(shouldPassBillingState && { state_autofilled: !!values.billingState }),
    zip_code_autofilled: !!values.billingZipCode,
    country_autofilled: !!values.billingCountry,
    country: values.billingCountry,
    ...(shouldPassBillingState && { billingState: values.billingState }),
  })

  showAdditionalFields.value = true
}

onMounted(async () => {
  if (isChangingCard.value || !user.value.cardLast4) {
    await renderPlaceAutocomplete()
    setTimeout(insertManualEnterButton, 1000)
  }
})

onUnmounted(() => {
  handleErrorReset()
  if (!autocomplete) return
  google.maps.event.clearInstanceListeners(autocomplete)
  document.querySelector('.pac-container')?.remove()
})

watch(isChangingCard, async () => {
  if (isChangingCard.value) {
    await renderPlaceAutocomplete()
    setTimeout(insertManualEnterButton, 1000)
  } else {
    if (!autocomplete) return
    google.maps.event.clearInstanceListeners(autocomplete)
    document.querySelector('.pac-container')?.remove()
  }

  formRef.value.resetForm()
  cardNumber.value = undefined
  autocompleteBlurred.value = false
  showAdditionalFields.value = false
  handleErrorReset()
})

const renderPlaceAutocomplete = async () => {
  await google.maps.importLibrary('places')
  autocomplete = await new google.maps.places.Autocomplete(
    autocompleteRef.value,
  )
  const geocoder = await new google.maps.Geocoder()

  autocompleteRef?.value?.addEventListener(
    'focus',
    () => (isAutocompleteFocused.value = true),
  )
  autocompleteRef?.value?.addEventListener(
    'blur',
    () => (isAutocompleteFocused.value = false),
  )

  google.maps.event.addListener(autocomplete, 'place_changed', async () => {
    const { place_id } = autocomplete.getPlace()

    const result = (await geocoder.geocode({ placeId: place_id }))?.results?.[0]
      ?.address_components

    handlePopulateFields(result)
  })
}

const showThreeDSecureLoader = () => {
  $loading.show({
    opacity: 0,
    color: '#a15cff',
    container: iframeContainer.value,
    'is-full-page': false,
    'z-index': 1,
  })
}

const handleRecurringPayment = async () => {
  const captchaToken = await grecaptcha.enterprise.execute(
    import.meta.env.VITE_RECAPTCHA_KEY,
    {
      action: 'purchase',
    },
  )

  const loader = $loading.show()

  try {
    const { data } = await $axios({
      method: 'post',
      url: '/user/payment/recurring',
      data: {
        discount,
        subscriptionType,
        purchaseNumber,
        cardCvv: cvv.value,
      },
      headers: {
        'Captcha-Token': captchaToken,
      },
    })

    if (data.redirect) {
      return showThreeDSecureLoader()
    }

    let updatedUser

    if (subscriptionType) {
      updatedUser = {
        ...user.value,
        ...data.user,
      }
    } else if (typeof purchaseNumber === 'number') {
      updatedUser = {
        ...user.value,
        luna: user.value.luna + pricing.purchases[purchaseNumber].luna,
      }
    }

    const params = {}

    if (subscriptionType) {
      params.type = 'subscription'
      params.subscriptionType = subscriptionType === 1 ? 'initial' : 'upgrade'
    } else if (typeof purchaseNumber === 'number') {
      params.type = 'purchase'
      params.luna = pricing.purchases[purchaseNumber].luna
    }

    const stringifiedParams = queryString.stringify(params)

    $router.push(`/purchase-complete?${stringifiedParams}`)

    $store.dispatch('setUser', updatedUser)
  } catch (e) {
    const message = e?.response?.data?.message
    paymentError.value =
      message || 'Unable to perform action. Please try again later.'
  } finally {
    loader.hide()
  }
}

const handleInitialSubscription = async (cardInfo, billingInfo) => {
  const loader = $loading.show()

  const captchaToken = await grecaptcha.enterprise.execute(
    import.meta.env.VITE_RECAPTCHA_KEY,
    {
      action: 'purchase',
    },
  )

  try {
    const { data } = await $axios({
      method: 'post',
      url: '/user/payment/initial',
      data: {
        cardInfo,
        billingInfo,
        discount,
        utmParams,
        subscriptionType,
      },
      headers: {
        'Captcha-Token': captchaToken,
      },
    })

    const updatedUser = {
      ...user.value,
      ...data.user,
    }

    if (data.redirect) {
      showThreeDSecureLoader()

      return tempDataStore.addPaymentData({
        cardInfo,
        billingInfo,
        utmParams,
      })
    }

    $store.dispatch('setUser', updatedUser)

    const params = { type: 'subscription', subscriptionType: 'initial' }
    const stringifiedParams = queryString.stringify(params)

    $router.push(`/purchase-complete?${stringifiedParams}`)
  } catch (e) {
    const message = e?.response?.data?.message
    paymentError.value =
      message || 'Unable to perform action. Please try again later.'
  } finally {
    loader.hide()
  }
}

const handleCardChange = async (cardInfo, billingInfo) => {
  const loader = $loading.show()

  mixpanel.track('buy_luna_change_card')

  const captchaToken = await grecaptcha.enterprise.execute(
    import.meta.env.VITE_RECAPTCHA_KEY,
    {
      action: 'purchase',
    },
  )

  try {
    const { data } = await $axios({
      method: 'post',
      url: '/user/payment/change-card',
      data: {
        cardInfo,
        billingInfo,
      },
      headers: {
        'Captcha-Token': captchaToken,
      },
    })

    const updatedUser = {
      ...user.value,
      ...data.cardData,
    }

    if (data.redirect) {
      return showThreeDSecureLoader()
    }

    $store.dispatch('setUser', updatedUser)

    const params = { type: 'cardChange' }
    const stringifiedParams = queryString.stringify(params)
    $router.push(`/purchase-complete?${stringifiedParams}`)
  } catch (e) {
    const message = e?.response?.data?.message
    paymentError.value =
      message || 'Unable to perform action. Please try again later.'
  } finally {
    loader.hide()
  }
}

const handleFormSubmit = async ({
  cardNumber,
  cardExpirationDate,
  cardCvv,
  cardholderName,
  cardBrand,
  ...rest
}) => {
  handleErrorReset()

  const cardInfo = {
    cardNumber: cardNumber?.split(' ')?.join(''),
    cardExpirationDate: cardExpirationDate?.replace(' / ', ''),
    cardCvv,
    cardholderName,
    cardBrand,
  }
  const billingInfo = { ...rest }

  if (billingInfo.billingCountry !== 'US') {
    delete billingInfo.billingState
  }

  if (subscriptionType && !user.value.subscription && !user.value.cardLast4) {
    handleInitialSubscription(cardInfo, billingInfo)
  } else if (user.value.cardLast4 && !isChangingCard.value) {
    checkCvvError()
    if (cvvError.value) return
    handleRecurringPayment()
  } else if (user.value.cardLast4) {
    handleCardChange(cardInfo, billingInfo)
  }
}

const checkCvvError = () => {
  if (!cvv.value) {
    cvvError.value = 'Enter CVV'
  } else if (cvv.value.length < 3) {
    cvvError.value = 'CVV is invalid'
  } else {
    cvvError.value = null
  }
}

const handleErrorReset = () => {
  paymentError.value = null
  paymentErrorStore.setPaymentError(null)
}

const luhnCheck = (value) => {
  let sum = 0
  let shouldDouble = false
  for (let i = value.length - 1; i >= 0; i--) {
    let digit = parseInt(value.charAt(i), 10)
    if (shouldDouble) {
      digit *= 2
      if (digit > 9) {
        digit -= 9
      }
    }
    sum += digit
    shouldDouble = !shouldDouble
  }
  return sum % 10 === 0
}

const validationSchema = yup.object({
  cardNumber: yup
    .string()
    .required('Enter a card number')
    .length(19, 'Card number is invalid')
    .test('luhn-check', 'Card number is invalid', (val) => {
      val = val.split(' ').join('')

      return luhnCheck(val)
    }),
  cardExpirationDate: yup
    .string()
    .required('Enter an expiry date')
    .length(7, 'Expiry date is invalid')
    .test('invalid-date', 'Expiry date is invalid', (val) => {
      if (!val) return false

      val = val.replace(' / ', '')

      const month = parseInt(val.slice(0, 2))
      if (month > 12) {
        return false
      }

      return true
    })
    .test('past-date', 'Expiry date cannot be in the past', (val) => {
      if (!val) return false

      val = val.replace(' / ', '')

      const month = val.slice(0, 2)
      const year = val.slice(2, 4)

      const expiryDate = new Date(`20${year}`, month - 1)
      if (expiryDate < Date.now()) {
        return false
      }

      return true
    }),
  cardCvv: yup.string().required('Enter a CVV').length(3, 'CVV is invalid'),
  cardholderName: yup
    .string()
    .test('is-full-name', 'Enter your full name', (value) => {
      return value && value.trim().includes(' ')
    })
    .required('Enter your full name'),
  cardBrand: yup.string().required().oneOf(['VISA', 'MASTER', 'DISCOVER']),
  billingAddress: yup
    .string()
    .min(2, 'Street address must be at least 2 characters')
    .max(50)
    .required('Enter your street address'),
  billingCity: yup
    .string()
    .min(2, 'City must be at least 2 characters')
    .max(50)
    .required('Enter your city'),
  billingState: yup.string().when('billingCountry', {
    is: (value) => value === 'US',
    then: () =>
      yup
        .string()
        .required('Select your state')
        .min(2, 'State must be at least 2 characters')
        .max(50),
    otherwise: () => yup.string(),
  }),
  billingCountry: yup.string().required('Select country'),
  billingZipCode: yup.string().when('billingCountry', {
    is: (value) => value === 'US',
    then: () =>
      yup
        .string()
        .required('Enter your zip code')
        .min(5, 'Zip code must be at least 5 characters long')
        .test('zipcode-valid', 'Zip code is invalid', (val, context) => {
          const isValid = zipState(val) === context.parent.billingState
          if (!isValid) {
            const stateName = states.find(
              (s) => s.code === context.parent.billingState,
            )?.name
            return context.createError({
              path: 'billingZipCode',
              message: `This Zip Code does not belong to ${stateName}`,
            })
          }
          return isValid
        }),
    otherwise: () =>
      yup
        .string()
        .required('Enter your zip code')
        .min(2, 'Zip code must be at least 2 characters long'),
  }),
})
</script>

<template>
  <div class="flex flex-col gap-5 mb-[30px]">
    <p class="text-[25px] font-medium">
      {{ user.cardLast4 && !isChangingCard ? 'Saved Card' : 'Payment Details' }}
    </p>
  </div>
  <div id="savedCardData" v-if="user.cardLast4 && !isChangingCard">
    <div class="flex w-full gap-[18px]">
      <div class="flex-1">
        <label class="font-medium inline-block mb-5">Card Number</label>
        <input
          :value="`**** **** **** ${user.cardLast4}`"
          disabled
          class="w-full bg-[#0E122A] h-[50px] pl-6 rounded-[15px] border border-[#111631]"
        />
      </div>
      <div class="flex-3">
        <label for="cvv" class="font-medium inline-block mb-5">CVV</label>
        <input
          id="cvv"
          v-mask="'###'"
          v-model="cvv"
          name="cvv"
          @focus="cvvError = null"
          @blur="checkCvvError"
          required
          class="w-full bg-[#0E122A] h-[50px] pl-6 rounded-[15px] border"
          :class="cvvError ? 'border-red-600' : 'border-[#111631]'"
        />
        <p v-if="cvvError" class="text-sm text-red-600 mt-1">
          {{ cvvError }}
        </p>
      </div>
    </div>
    <div class="border-b border-[#343755] border-dashed my-[36px]"></div>
    <div class="flex flex-col lg:flex-row gap-5">
      <Button
        variant="secondary"
        :borderVisible="false"
        class="flex-1 flex justify-center items-center gap-[11px] py-[14px]"
        @click="isChangingCard = true"
      >
        <img class="h-6 w-6" :src="refresh" />
        Change Card
      </Button>
      <Button
        class="flex-1 flex justify-center items-center gap-[11px] py-[14px]"
        @click="handleFormSubmit"
      >
        <img class="h-6 w-6" :src="star" />
        {{
          typeof purchaseNumber === 'number'
            ? 'Buy Luna'
            : user.subscription < subscriptionType
              ? 'Upgrade Subscription'
              : 'Upgrade Account'
        }}
      </Button>
    </div>
  </div>
  <div v-else>
    <Form
      ref="formRef"
      v-slot="{ submitCount, values }"
      action="#"
      id="paymentForm"
      class="space-y-[30px] max-w-[743px]"
      @submit="handleFormSubmit"
      :validation-schema="validationSchema"
      @focusin.stop="handleErrorReset"
    >
      <div>
        <label for="cardNumber" class="inline-block mb-3">Card Number</label>
        <Field
          v-slot="{ field, meta, setTouched }"
          validateOnInput
          name="cardNumber"
        >
          <div class="relative">
            <input
              v-bind="field"
              id="cardNumber"
              v-mask="'#### #### #### ####'"
              placeholder="x x x x   x x x x   x x x x   x x x x"
              class="w-full bg-[#0E122A] h-[50px] pl-6 rounded-[15px] border"
              :class="
                meta.touched && !meta.valid
                  ? 'border-red-600 focus:border-red-600 focus:ring-red-600'
                  : 'border-[#111631]'
              "
              @focus="handleFieldFocus('card_number', setTouched)"
              @input="cardNumber = field.value"
              @change="handleChange"
            />
            <div
              class="absolute -translate-y-1/2 top-[50%] right-[10px] flex gap-[2px]"
            >
              <div
                v-if="cardBrand === 'VISA'"
                class="flex justify-center items-center bg-[#15173A] w-[42px] h-[26px] rounded-[4px]"
              >
                <img class="w-[25px] h-2 select-none" :src="visa" />
              </div>
              <div
                v-if="cardBrand === 'MASTER'"
                class="flex justify-center items-center bg-[#15173A] w-[42px] h-[26px] rounded-[4px]"
              >
                <img class="w-[25px] h-[14px] select-none" :src="mastercard" />
              </div>
              <div
                v-if="cardBrand === 'DISCOVER'"
                class="flex justify-center items-center bg-[#15173A] w-[42px] h-[26px] rounded-[4px]"
              >
                <img class="w-[25px] h-[14px] select-none" :src="discover" />
              </div>
            </div>
          </div>
          <ErrorMessage
            v-if="meta.touched && !meta.valid"
            name="cardNumber"
            class="inline-block text-sm text-red-600 mt-1"
          />
        </Field>
      </div>
      <div class="flex gap-[30px]">
        <div class="flex-1">
          <label for="cardExpirationDate" class="inline-block mb-3"
            >Expiry Date</label
          >
          <Field
            v-slot="{ field, meta, setTouched }"
            validateOnInput
            name="cardExpirationDate"
          >
            <input
              v-bind="field"
              id="cardExpirationDate"
              v-mask="'## / ##'"
              placeholder="MM / YY"
              class="w-full bg-[#0E122A] h-[50px] pl-6 rounded-[15px] border"
              :class="
                meta.touched && !meta.valid
                  ? 'border-red-600'
                  : 'border-[#111631]'
              "
              @focus="handleFieldFocus('expiry_date', setTouched)"
            />
            <ErrorMessage
              v-if="meta.touched && !meta.valid"
              name="cardExpirationDate"
              class="inline-block text-sm text-red-600 mt-1"
            />
          </Field>
        </div>
        <div class="flex-1">
          <label for="cardCvv" class="inline-block mb-3">CVV</label>
          <Field
            v-slot="{ field, meta, setTouched }"
            validateOnInput
            name="cardCvv"
          >
            <input
              v-bind="field"
              id="cardCvv"
              v-mask="'###'"
              placeholder="CVV"
              class="w-full bg-[#0E122A] h-[50px] pl-6 rounded-[15px] border"
              :class="
                meta.touched && !meta.valid
                  ? 'border-red-600'
                  : 'border-[#111631]'
              "
              @focus="handleFieldFocus('cvv', setTouched)"
            />
            <ErrorMessage
              v-if="meta.touched && !meta.valid"
              name="cardCvv"
              class="inline-block text-sm text-red-600 mt-1"
            />
          </Field>
        </div>
      </div>
      <div>
        <label for="cardholderName" class="inline-block mb-3"
          >Cardholder Name</label
        >
        <Field
          v-slot="{ field, meta, setTouched }"
          name="cardholderName"
          validateOnInput
        >
          <input
            v-bind="field"
            id="cardholderName"
            placeholder="Cardholder Name"
            class="w-full bg-[#0E122A] h-[50px] pl-6 rounded-[15px] border"
            :class="
              meta.touched && !meta.valid
                ? 'border-red-600'
                : 'border-[#111631]'
            "
            @focus="handleFieldFocus('cardholder_name', setTouched)"
          />
          <ErrorMessage
            v-if="meta.touched && !meta.valid"
            name="cardholderName"
            class="inline-block text-sm text-red-600 mt-1"
          />
        </Field>
      </div>
      <div class="border-b border-[#343755] border-dashed"></div>
      <div v-if="!showAdditionalFields">
        <label class="inline-block mb-3">Billing Address</label>
        <input
          ref="autocompleteRef"
          placeholder="Street Address"
          class="w-full bg-[#0E122A] h-[50px] pl-6 rounded-[15px] border border-[#111631]"
          :class="
            submitCount > 0 || autocompleteBlurred
              ? 'border-red-600'
              : 'border-[#111631]'
          "
          @blur="autocompleteBlurred = true"
          @focus="handleFieldFocus('billing_address')"
        />
        <ErrorMessage
          v-if="submitCount > 0 || autocompleteBlurred"
          name="billingAddress"
          class="inline-block text-sm text-red-600 mt-1"
        />
      </div>
      <div v-if="showAdditionalFields">
        <div class="mb-[30px]">
          <label for="billingAddress" class="inline-block mb-5"
            >Street Address</label
          >
          <Field
            v-slot="{ field, meta, setTouched }"
            name="billingAddress"
            validateOnInput
          >
            <input
              v-bind="field"
              id="billingAddress"
              placeholder="Street Address"
              class="w-full bg-[#0E122A] h-[50px] pl-6 rounded-[15px] border border-[#111631]"
              :class="
                meta.touched && !meta.valid
                  ? 'border-red-600'
                  : 'border-[#111631]'
              "
              @focus="setTouched(false)"
              @change="handleFieldChange('street_address')"
            />
            <ErrorMessage
              v-if="meta.touched && !meta.valid"
              name="billingAddress"
              class="inline-block text-sm text-red-600 mt-1"
            />
          </Field>
        </div>
        <div class="flex gap-[30px]">
          <div class="flex-1">
            <label for="billingCity" class="inline-block mb-3">City</label>
            <Field
              v-slot="{ field, meta, setTouched }"
              name="billingCity"
              validateOnInput
            >
              <input
                v-bind="field"
                id="billingCity"
                placeholder="City"
                class="w-full bg-[#0E122A] h-[50px] pl-6 rounded-[15px] border border-[#111631]"
                :class="
                  meta.touched && !meta.valid
                    ? 'border-red-600'
                    : 'border-[#111631]'
                "
                @focus="setTouched(false)"
                @change="handleFieldChange('city')"
              />
              <ErrorMessage
                v-if="meta.touched && !meta.valid"
                name="billingCity"
                class="inline-block text-sm text-red-600 mt-1"
              />
            </Field>
          </div>
          <div class="flex-1" v-if="values.billingCountry === 'US'">
            <label for="billingState" class="inline-block mb-3">State</label>
            <Field
              v-slot="{ field, meta, setTouched }"
              name="billingState"
              validateOnInput
            >
              <select
                placeholder="Select a state"
                v-bind="field"
                id="billingState"
                class="w-full bg-[#0E122A] h-[50px] pl-6 rounded-[15px] border border-[#111631]"
                :class="
                  meta.touched && !meta.valid
                    ? 'border-red-600'
                    : 'border-[#111631]'
                "
                @focus="setTouched(false)"
                @change="handleFieldChange('state')"
              >
                <option value="" disabled selected>Select your state</option>
                <option
                  v-for="state in states"
                  :value="state.code"
                  :key="state.code"
                >
                  {{ state.name }}
                </option>
              </select>
              <ErrorMessage
                v-if="meta.touched && !meta.valid"
                name="billingState"
                class="inline-block text-sm text-red-600 mt-1"
              />
            </Field>
          </div>
        </div>
        <div class="mt-[30px]">
          <label for="billingZipCode" class="inline-block mb-3"
            >ZIP / Postal Code</label
          >
          <Field
            v-slot="{ field, meta, setTouched }"
            name="billingZipCode"
            validateOnInput
          >
            <input
              v-bind="field"
              id="billingZipCode"
              placeholder="ZIP / Postal Code"
              class="w-full bg-[#0E122A] h-[50px] pl-6 rounded-[15px] border border-[#111631]"
              :class="
                meta.touched && !meta.valid
                  ? 'border-red-600'
                  : 'border-[#111631]'
              "
              @focus="setTouched(false)"
              @change="handleFieldChange('zip_code')"
            />
            <ErrorMessage
              v-if="meta.touched && !meta.valid"
              name="billingZipCode"
              class="inline-block text-sm text-red-600 mt-1"
            />
          </Field>
        </div>
        <div class="mt-[30px]">
          <label for="country" class="inline-block mb-3">Country</label>
          <Field v-slot="{ field, meta, setTouched }" name="billingCountry">
            <select
              v-bind="field"
              id="billingCountry"
              class="w-full bg-[#0E122A] h-[50px] pl-6 rounded-[15px] border border-[#111631]"
              :class="
                meta.touched && !meta.valid
                  ? 'border-red-600'
                  : 'border-[#111631]'
              "
              @focus="setTouched(false)"
              @change="handleFieldChange('country')"
            >
              <option
                v-for="country in countries"
                :value="country.code"
                :key="country.code"
              >
                {{ country.name }}
              </option>
            </select>
            <ErrorMessage
              v-if="meta.touched && !meta.valid"
              name="billingCountry"
              class="inline-block text-sm text-red-600 mt-1"
            />
          </Field>
        </div>
      </div>
      <Button
        class="flex justify-center items-center gap-[12px] w-full py-[14px]"
      >
        <img v-if="isChangingCard" class="h-6 w-6" :src="refresh" />
        <img v-else class="h-6 w-6" :src="star" />
        {{ isChangingCard ? 'Change Card' : 'Upgrade Account' }}
      </Button>
    </Form>
  </div>
  <div
    v-if="paymentError || storedPaymentError"
    class="p-2 mt-4 text-center rounded-[10px] border border-white border-opacity-15"
    style="
      background: radial-gradient(
        255.14% 174.74% at 38.76% 155.71%,
        #dc3027 0%,
        #dc2773 100%
      );
    "
  >
    <p>{{ paymentError || storedPaymentError }}</p>
  </div>
  <div
    ref="iframeContainer"
    class="relative iframeContainer flex flex-col items-center"
  ></div>
</template>

<style>
.pac-container.pac-logo {
  border-top: none;
  border-radius: 15px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.pac-container.pac-logo::after {
  display: none;
}

.pac-item {
  border: none;
  background: #15173a;
  padding-left: 16px;
  padding-bottom: 12px;
}

.pac-item > span {
  display: block;
  height: 20px;
}

.pac-item > span:last-child {
  color: lightgray;
}

.pac-item:hover {
  cursor: pointer;
  background: darkslateblue;
  opacity: 1;
}

.pac-item > .pac-icon {
  display: none;
}

.pac-item > .pac-item-query {
  color: white;
}

#threeDSecureIframe {
  position: relative;
  z-index: 2;
  min-height: 800px;
  width: 100%;
  margin: auto;
  max-width: 100%;
}
</style>

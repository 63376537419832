export default {
  KOMNINOS: {
    type: 'All', // 'All', 'Subscriptions','Purchases'
    expiresAt: new Date('12 12 2024 18:05:40'),
    subscriptions: [
      {
        type: 1,
        luna: 100,
        amount: '4.99',
        discountPercentage: '50',
      },
      {
        type: 2,
        luna: 100,
        amount: '29.99',
        monthlyAmount: '2.45',
        discountPercentage: '50',
      },
    ],
    purchases: [
      {
        amount: '4.95',
        luna: 200,
        discountPercentage: '50',
      },
      {
        amount: '24.95',
        luna: 800,
        discountPercentage: '50',
      },
      {
        amount: '39.95',
        luna: 1600,
        discountPercentage: '50',
      },
      {
        amount: '79.95',
        luna: 3600,
        discountPercentage: '50',
      },
    ],
  },
}

import { defineStore, acceptHMRUpdate } from 'pinia'

export const useTempPaymentData = defineStore('tempPaymentData', {
  state: () => ({
    tempPaymentData: null,
  }),
  actions: {
    addPaymentData(data) {
      this.tempPaymentData = data
    },
    removePaymentData() {
      this.tempPaymentData = null
    },
  },
  persist: {
    localStorage,
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTempPaymentData, import.meta.hot))
}

<script setup>
import instagram from '@/assets/icons/instagram.svg'
import x from '@/assets/icons/x.svg'
import visa from '@/assets/icons/visa.svg'
import mastercard from '@/assets/icons/mastercard.svg'
import visaVerified from '@/assets/icons/visa-verified.svg'
import mastercardSecure from '@/assets/icons/mastercard-secure.svg'
import rta from '@/assets/icons/rta.svg'
import cdnFile from '@/helpers/cdnFile'

const brandName = import.meta.env.VITE_BRAND_NAME
const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL
const supportPhone = import.meta.env.VITE_SUPPORT_PHONE
const twitterHandle = import.meta.env.VITE_TWITTER_HANDLE
const instagramHandle = import.meta.env.VITE_INSTAGRAM_HANDLE
const corpNumber = import.meta.env.VITE_CORP_NUMBER
const corpName = import.meta.env.VITE_CORP_NAME
const corpAddress = import.meta.env.VITE_CORP_ADDRESS
</script>

<template>
  <div class="flex flex-col lg:gap-[30px] mt-[100px] lg:mt-[140px] pb-[80px]">
    <div
      class="flex flex-col gap-[25px] lg:gap-0 lg:flex-row justify-between items-center mb-[50px] lg:mb-0"
    >
      <img
        class="w-[190px] h-auto"
        :src="cdnFile('logo.svg', true)"
        alt="Logo"
      />
      <div class="flex gap-4 justify-center md:justify-start">
        <a
          target="_blank"
          rel="noopener"
          :href="`https://instagram.com/${instagramHandle}`"
        >
          <div class="bg-[#131331] p-[15px] rounded-full">
            <img :src="instagram" />
          </div>
        </a>
        <a
          target="_blank"
          rel="noopener"
          :href="`https://twitter.com/${twitterHandle}`"
        >
          <div class="bg-[#131331] p-[15px] rounded-full"><img :src="x" /></div>
        </a>
      </div>
    </div>
    <div
      class="flex flex-col lg:flex-row items-center justify-between w-full md:text-left border border-white border-opacity-5 rounded-[15px] py-[35px] px-[30px]"
      style="
        background: radial-gradient(
          123.96% 123.96% at 18.89% 102.14%,
          rgba(76, 12, 81, 0.2) 0%,
          rgba(37, 6, 38, 0) 100%
        );
      "
    >
      <div
        class="text-[14px] text-center lg:text-left font-medium lg:flex-1 2xl:flex-[unset]"
      >
        <p class="mb-[23px]">Copyright © 2024, {{ corpName }}.</p>
        <p>
          {{ corpAddress }}.<br />All content in this website is
          <span class="text-[#CC47FF]">AI-generated.</span>
        </p>
      </div>
      <div class="text-[14px] font-medium">
        <div
          class="flex flex-col items-center lg:flex-row gap-[22px] lg:gap-[31px] mb-[38px] mt-[41px] lg:mt-0 lg:mb-[23px]"
        >
          <router-link to="/terms-of-service">Terms of Service</router-link>
          <router-link to="/privacy-policy">Privacy Policy</router-link>
          <router-link to="/cancellation-policy"
            >Cancellation Policy</router-link
          >
          <router-link to="/compliant-policy">Complaint Policy</router-link>
          <router-link to="/content-removal-policy"
            >Content Removal Policy</router-link
          >
        </div>
        <div class="flex justify-end gap-2">
          <p>{{ supportEmail }}</p>
          <p>{{ supportPhone }}</p>
        </div>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row justify-between items-center">
      <div
        class="h-8 flex gap-4 my-[25px] lg:my-0 justify-center md:justify-start"
      >
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <img
            class="h-auto"
            :src="cdnFile('card-brands/visa.svg')"
            alt="Visa Logo"
            loading="lazy"
          />
        </div>
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <img
            :src="cdnFile('card-brands/mastercard.svg')"
            alt="MasterCard Logo"
            loading="lazy"
          />
        </div>
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <img :src="cdnFile('rta.gif')" alt="RTA Logo" />
        </div>
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <img
            :src="cdnFile('card-brands/vbv.svg')"
            alt="Verified By Visa Logo"
            loading="lazy"
          />
        </div>
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <img
            class="w-[50px]"
            :src="cdnFile('card-brands/ms.svg')"
            alt="MasterCard SecureCode Logo"
            loading="lazy"
          />
        </div>
      </div>
      <div class="flex lg:block gap-[26px] lg:gap-0 mb-[30px] lg:mb-0">
        <a
          class="block text-[14px]"
          href="https://docs.google.com/forms/d/e/1FAIpQLSf09MZAznZ_6fYBWd75bhWz-_AGFXL_DGm9B2O4wqFxb-SoQw/viewform"
          >Report Content</a
        >
        <!-- <a
          class="block lg:hidden text-[14px]"
          href="https://forms.clickup.com/9015426845/f/8cnrzrx-615/15ZH5FKUYSOZDRJYL6"
          >Become an Affiliate</a
        > -->
      </div>
    </div>
  </div>
</template>

<style scoped></style>

import cdnFile from '@/helpers/cdnFile'
import hearts from '@/assets/icons/hearts.svg'
import angel from '@/assets/icons/angel.svg'
import flameAlt from '@/assets/icons/flame-alt.svg'
import crown from '@/assets/icons/crown.svg'
import snowflake from '@/assets/icons/snowflake.svg'
import submissive from '@/assets/icons/submissive.svg'
import tooth from '@/assets/icons/tooth.svg'
import bookOpen from '@/assets/icons/book-open.svg'
import brush from '@/assets/icons/brush.svg'
import star from '@/assets/icons/star.svg'
import atom from '@/assets/icons/atom.svg'
import pencilLine from '@/assets/icons/pencil-line.svg'
import glasses from '@/assets/icons/glasses.svg'
import flame from '@/assets/icons/flame.svg'
import scale from '@/assets/icons/scale.svg'
import cyclist from '@/assets/icons/cyclist.svg'
import blog from '@/assets/icons/blog.svg'
import mountain from '@/assets/icons/mountain.svg'
import gamingPad from '@/assets/icons/gaming-pad.svg'
import baloons from '@/assets/icons/baloons.svg'
import camera from '@/assets/icons/camera.svg'
import hands from '@/assets/icons/hands.svg'
import car from '@/assets/icons/car.svg'
import masks from '@/assets/icons/masks.svg'
import badBoy from '@/assets/icons/bad-boy.svg'
import protective from '@/assets/icons/protective.svg'
import caring from '@/assets/icons/caring.svg'
import flagUs from '@/assets/icons/flags/us.svg'
import flagGb from '@/assets/icons/flags/gb.svg'
import flagIn from '@/assets/icons/flags/in.svg'
import flagAfrica from '@/assets/icons/flags/africa.svg'
import flagAu from '@/assets/icons/flags/au.svg'
import flagFr from '@/assets/icons/flags/fr.svg'

export const stepsGender = [
  {
    displayName: 'Gender',
    modifies: 'gender',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/gender/female.webp'),
        displayName: 'Girlfriend',
        value: 'female',
      },
      {
        image: cdnFile('model-creator/gender/male.webp'),
        displayName: 'Boyfriend',
        value: 'male',
      },
    ],
  },
]

export const stepsFemaleRealistic = [
  {
    displayName: 'Style',
    modifies: 'style',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female/styles/realistic.webp'),
        displayName: 'Realistic',
        value: 'realistic',
      },
      {
        image: cdnFile('model-creator/female/styles/anime.webp'),
        displayName: 'Anime',
        value: 'anime',
      },
    ],
  },
  {
    displayName: 'Ethnicity',
    modifies: 'ethnicity',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female/ethnicities/caucasian.webp'),
        displayName: 'Caucasian',
        value: 'caucasian',
      },
      {
        image: cdnFile('model-creator/female/ethnicities/asian.webp'),
        displayName: 'Asian',
        value: 'asian',
      },
      {
        image: cdnFile('model-creator/female/ethnicities/arabic.webp'),
        displayName: 'Arabic',
        value: 'arabic',
      },
      {
        image: cdnFile('model-creator/female/ethnicities/indian.webp'),
        displayName: 'Indian',
        value: 'indian',
      },
      {
        image: cdnFile('model-creator/female/ethnicities/latina.webp'),
        displayName: 'Latina',
        value: 'latina',
      },
      {
        image: cdnFile('model-creator/female/ethnicities/african.webp'),
        displayName: 'African',
        value: 'african',
      },
    ],
  },
  {
    displayName: 'Age',
    modifies: 'age',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female/ages/twenties.webp'),
        displayName: 'Twenties',
        value: 20,
      },
      {
        image: cdnFile('model-creator/female/ages/thirties.webp'),
        displayName: 'Thirties',
        value: 30,
      },
      {
        image: cdnFile('model-creator/female/ages/fourties.webp'),
        displayName: 'Fourties',
        value: 40,
      },
      {
        image: cdnFile('model-creator/female/ages/fifties.webp'),
        displayName: 'Fifties',
        value: 50,
      },
    ],
  },
  {
    displayName: 'Eye Color',
    modifies: 'eyeColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female/eye-colors/black.webp'),
        displayName: 'Black',
        value: 'black',
      },
      {
        image: cdnFile('model-creator/female/eye-colors/brown.webp'),
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: cdnFile('model-creator/female/eye-colors/gray.webp'),
        displayName: 'Gray',
        value: 'gray',
      },
      {
        image: cdnFile('model-creator/female/eye-colors/blue.webp'),
        displayName: 'Blue',
        value: 'blue',
      },
      {
        image: cdnFile('model-creator/female/eye-colors/green.webp'),
        displayName: 'Green',
        value: 'green',
      },
    ],
  },
  {
    displayName: 'Hair Style',
    modifies: 'hairStyle',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female/hair-styles/straight.webp'),
        displayName: 'Straight',
        value: 'straight',
      },
      {
        image: cdnFile('model-creator/female/hair-styles/curly.webp'),
        displayName: 'Curly',
        value: 'curly',
      },
      {
        image: cdnFile('model-creator/female/hair-styles/ponytail.webp'),
        displayName: 'Ponytail',
        value: 'ponytail',
      },
      {
        image: cdnFile('model-creator/female/hair-styles/braids.webp'),
        displayName: 'Braids',
        value: 'braids',
      },
    ],
  },
  {
    displayName: 'Hair Length',
    modifies: 'hairLength',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female/hair-lengths/long.webp'),
        displayName: 'Long',
        value: 'long',
      },
      {
        image: cdnFile('model-creator/female/hair-lengths/short.webp'),
        displayName: 'Short',
        value: 'short',
      },
    ],
  },
  {
    displayName: 'Hair Color',
    modifies: 'hairColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female/hair-colors/brunette.webp'),
        displayName: 'Brunette',
        value: 'brunette',
      },
      {
        image: cdnFile('model-creator/female/hair-colors/black.webp'),
        displayName: 'Black',
        value: 'black',
      },
      {
        image: cdnFile('model-creator/female/hair-colors/blonde.webp'),
        displayName: 'Blonde',
        value: 'blonde',
      },
      {
        image: cdnFile('model-creator/female/hair-colors/ginger.webp'),
        displayName: 'Ginger',
        value: 'ginger',
      },
      {
        image: cdnFile('model-creator/female/hair-colors/pink.webp'),
        displayName: 'Pink',
        value: 'pink',
      },
      {
        image: cdnFile('model-creator/female/hair-colors/purple.webp'),
        displayName: 'Purple',
        value: 'purple',
      },
    ],
  },
  {
    displayName: 'Body Type',
    modifies: 'bodyType',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female/body-types/skinny.webp'),
        displayName: 'Skinny',
        value: 'skinny',
      },
      {
        image: cdnFile('model-creator/female/body-types/slim.webp'),
        displayName: 'Slim',
        value: 'slim',
      },
      {
        image: cdnFile('model-creator/female/body-types/athletic.webp'),
        displayName: 'Athletic',
        value: 'athletic',
      },
      {
        image: cdnFile('model-creator/female/body-types/thick.webp'),
        displayName: 'Thick',
        value: 'thick',
      },
    ],
  },
  {
    displayName: 'Breast Size',
    modifies: 'breastSize',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female/breast-sizes/small.webp'),
        displayName: 'Small',
        value: 'small',
      },
      {
        image: cdnFile('model-creator/female/breast-sizes/average.webp'),
        displayName: 'Average',
        value: 'average',
      },
      {
        image: cdnFile('model-creator/female/breast-sizes/large.webp'),
        displayName: 'Large',
        value: 'large',
      },
      {
        image: cdnFile('model-creator/female/breast-sizes/huge.webp'),
        displayName: 'Huge',
        value: 'huge',
      },
    ],
  },
  {
    displayName: 'Butt Size',
    modifies: 'buttSize',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female/butt-sizes/small.webp'),
        displayName: 'Small',
        value: 'small',
      },
      {
        image: cdnFile('model-creator/female/butt-sizes/average.webp'),
        displayName: 'Average',
        value: 'average',
      },
      {
        image: cdnFile('model-creator/female/butt-sizes/large.webp'),
        displayName: 'Large',
        value: 'large',
      },
      {
        image: cdnFile('model-creator/female/butt-sizes/huge.webp'),
        displayName: 'Huge',
        value: 'huge',
      },
    ],
  },
  {
    displayName: 'Personality',
    modifies: 'personality',
    customModifies: 'customPersonality',
    isCustom: true,
    columns: 3,
    type: 'icon-radio',
    allowEmpty: true,
    allowNull: false,
    options: [
      {
        icon: hearts,
        displayName: 'Lover',
        displayDescription:
          'Romantic, affectionate, and cherishes emotional connection.',
        value: 'lover',
      },
      {
        icon: angel,
        displayName: 'Innocent',
        displayDescription: 'Optimistic, naive, and sees world with wonder.',
        value: 'innocent',
      },
      {
        icon: submissive,
        displayName: 'Submissive',
        displayDescription: 'Obedient, yielding, and happy to follow.',
        value: 'submissive',
      },
      {
        icon: flameAlt,
        displayName: 'Nympho',
        displayDescription:
          'Insatiable, passionate, and constantly craving intimacy.',
        value: 'nympho',
      },
      {
        icon: crown,
        displayName: 'Dominant',
        displayDescription: 'Assertive, controlling, and commanding.',
        value: 'dominant',
      },
      {
        icon: snowflake,
        displayName: 'Mean',
        displayDescription: 'Cold, dismissive, and often sarcastic.',
        value: 'mean',
      },
    ],
  },
  {
    displayName: 'Accent',
    modifies: 'voiceId',
    columns: 3,
    type: 'voice-radio',
    options: [
      {
        icon: flagUs,
        value: 'new-us-young-1',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-1.mp3',
          true,
        ),
        displayName: 'American 1',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-1',
        preview: cdnFile('model-creator/female/voices/new-us-old-1.mp3', true),
        displayName: 'American 1',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-2',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-2.mp3',
          true,
        ),
        displayName: 'American 2',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-2',
        preview: cdnFile('model-creator/female/voices/new-us-old-2.mp3', true),
        displayName: 'American 2',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-3',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-3.mp3',
          true,
        ),
        displayName: 'American 3',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-3',
        preview: cdnFile('model-creator/female/voices/new-us-old-3.mp3', true),
        displayName: 'American 3',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-4',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-4.mp3',
          true,
        ),
        displayName: 'American 4',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-4',
        preview: cdnFile('model-creator/female/voices/new-us-old-4.mp3', true),
        displayName: 'American 4',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagGb,
        value: 'new-gb-young',
        preview: cdnFile('model-creator/female/voices/new-gb-young.mp3', true),
        displayName: 'British',
        ethnicities: ['caucasian', 'arabic', 'indian', 'african'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagGb,
        value: 'new-gb-old',
        preview: cdnFile('model-creator/female/voices/new-gb-old.mp3', true),
        displayName: 'British',
        ethnicities: ['caucasian', 'arabic', 'indian', 'african'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagIn,
        value: 'new-in-young',
        preview: cdnFile('model-creator/female/voices/new-in-young.mp3', true),
        displayName: 'Indian',
        ethnicities: ['indian'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagIn,
        value: 'new-in-old',
        preview: cdnFile('model-creator/female/voices/new-in-old.mp3', true),
        displayName: 'Indian',
        ethnicities: ['indian'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAfrica,
        value: 'new-africa-young',
        preview: cdnFile(
          'model-creator/female/voices/new-africa-young.mp3',
          true,
        ),
        displayName: 'African',
        ethnicities: ['african'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAfrica,
        value: 'new-africa-old',
        preview: cdnFile(
          'model-creator/female/voices/new-africa-old.mp3',
          true,
        ),
        displayName: 'African',
        ethnicities: ['african'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAu,
        value: 'new-au-young',
        preview: cdnFile('model-creator/female/voices/new-au-young.mp3', true),
        displayName: 'Australian',
        ethnicities: ['caucasian', 'asian'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAu,
        value: 'new-au-old',
        preview: cdnFile('model-creator/female/voices/new-au-old.mp3', true),
        displayName: 'Australian',
        ethnicities: ['caucasian', 'asian'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagFr,
        value: 'new-fr',
        preview: cdnFile('model-creator/female/voices/new-fr.mp3', true),
        displayName: 'French',
        ethnicities: ['caucasian', 'african'],
        startAge: 0,
        endAge: 80,
      },
    ],
  },
  {
    displayName: 'Occupation',
    modifies: 'occupation',
    customModifies: 'occupation',
    columns: 3,
    type: 'custom-icon-radio',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: tooth,
        displayName: 'Dentist',
        value: 'dentist',
      },
      {
        icon: bookOpen,
        displayName: 'Librarian',
        value: 'librarian',
      },
      {
        icon: brush,
        displayName: 'Designer',
        value: 'designer',
      },
      {
        icon: star,
        displayName: 'Stylist',
        value: 'stylist',
      },
      {
        icon: atom,
        displayName: 'Scientist',
        value: 'scientist',
      },
      {
        icon: pencilLine,
        displayName: 'Writer',
        value: 'writer',
      },
      {
        icon: glasses,
        displayName: 'Teacher',
        value: 'teacher',
      },
      {
        icon: flame,
        displayName: 'Firefighter',
        value: 'firefighter',
      },
      {
        icon: scale,
        displayName: 'Lawyer',
        value: 'lawyer',
      },
    ],
  },
  {
    displayName: 'Hobbies',
    modifies: 'hobbies',
    customModifies: 'hobbies',
    columns: 3,
    type: 'custom-icon-select',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: cyclist,
        displayName: 'Cycling',
        value: 'cycling',
      },
      {
        icon: blog,
        displayName: 'Blogging',
        value: 'blogging',
      },
      {
        icon: mountain,
        displayName: 'Hiking',
        value: 'hiking',
      },
      {
        icon: gamingPad,
        displayName: 'Gaming',
        value: 'gaming',
      },
      {
        icon: baloons,
        displayName: 'Partying',
        value: 'partying',
      },
      {
        icon: camera,
        displayName: 'Photography',
        value: 'photography',
      },
      {
        icon: hands,
        displayName: 'Volunteering',
        value: 'volunteering',
      },
      {
        icon: car,
        displayName: 'Cars',
        value: 'cars',
      },
      {
        icon: masks,
        displayName: 'Theater',
        value: 'theater',
      },
    ],
  },
  {
    displayName: 'Type a girl name...',
    displayTitle: 'Name Your Girlfriend',
    modifies: 'name',
    type: 'custom',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
  },
]

export const stepsFemaleAnime = [
  {
    displayName: 'Style',
    modifies: 'style',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female/styles/realistic.webp'),
        displayName: 'Realistic',
        value: 'realistic',
      },
      {
        image: cdnFile('model-creator/female/styles/anime.webp'),
        displayName: 'Anime',
        value: 'anime',
      },
    ],
  },
  {
    displayName: 'Ethnicity',
    modifies: 'ethnicity',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female-anime/ethnicities/white.webp'),
        displayName: 'White',
        value: 'white',
      },
      {
        image: cdnFile('model-creator/female-anime/ethnicities/brown.webp'),
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: cdnFile('model-creator/female-anime/ethnicities/black.webp'),
        displayName: 'Black',
        value: 'black',
      },
    ],
  },
  {
    displayName: 'Age',
    modifies: 'age',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female-anime/ages/twenties.webp'),
        displayName: 'Twenties',
        value: 20,
      },
      {
        image: cdnFile('model-creator/female-anime/ages/thirties.webp'),
        displayName: 'Thirties',
        value: 30,
      },
      {
        image: cdnFile('model-creator/female-anime/ages/fourties.webp'),
        displayName: 'Fourties',
        value: 40,
      },
      {
        image: cdnFile('model-creator/female-anime/ages/fifties.webp'),
        displayName: 'Fifties',
        value: 50,
      },
    ],
    sidenote: 'The age setting will not impact how your anime will look like.',
  },
  {
    displayName: 'Eye Color',
    modifies: 'eyeColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female-anime/eye-colors/black.webp'),
        displayName: 'Black',
        value: 'black',
      },
      {
        image: cdnFile('model-creator/female-anime/eye-colors/brown.webp'),
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: cdnFile('model-creator/female-anime/eye-colors/gray.webp'),
        displayName: 'Gray',
        value: 'gray',
      },
      {
        image: cdnFile('model-creator/female-anime/eye-colors/blue.webp'),
        displayName: 'Blue',
        value: 'blue',
      },
      {
        image: cdnFile('model-creator/female-anime/eye-colors/green.webp'),
        displayName: 'Green',
        value: 'green',
      },
    ],
  },
  {
    displayName: 'Hair Style',
    modifies: 'hairStyle',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female-anime/hair-styles/straight.webp'),
        displayName: 'Straight',
        value: 'straight',
      },
      {
        image: cdnFile('model-creator/female-anime/hair-styles/curly.webp'),
        displayName: 'Curly',
        value: 'curly',
      },
      {
        image: cdnFile('model-creator/female-anime/hair-styles/ponytail.webp'),
        displayName: 'Ponytail',
        value: 'ponytail',
      },
      {
        image: cdnFile('model-creator/female-anime/hair-styles/braids.webp'),
        displayName: 'Braids',
        value: 'braids',
      },
    ],
  },
  {
    displayName: 'Hair Length',
    modifies: 'hairLength',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female-anime/hair-lengths/long.webp'),
        displayName: 'Long',
        value: 'long',
      },
      {
        image: cdnFile('model-creator/female-anime/hair-lengths/short.webp'),
        displayName: 'Short',
        value: 'short',
      },
    ],
  },
  {
    displayName: 'Hair Color',
    modifies: 'hairColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female-anime/hair-colors/brunette.webp'),
        displayName: 'Brunette',
        value: 'brunette',
      },
      {
        image: cdnFile('model-creator/female-anime/hair-colors/black.webp'),
        displayName: 'Black',
        value: 'black',
      },
      {
        image: cdnFile('model-creator/female-anime/hair-colors/blonde.webp'),
        displayName: 'Blonde',
        value: 'blonde',
      },
      {
        image: cdnFile('model-creator/female-anime/hair-colors/ginger.webp'),
        displayName: 'Ginger',
        value: 'ginger',
      },
      {
        image: cdnFile('model-creator/female-anime/hair-colors/pink.webp'),
        displayName: 'Pink',
        value: 'pink',
      },
      {
        image: cdnFile('model-creator/female-anime/hair-colors/purple.webp'),
        displayName: 'Purple',
        value: 'purple',
      },
    ],
  },
  {
    displayName: 'Body Type',
    modifies: 'bodyType',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female-anime/body-types/skinny.webp'),
        displayName: 'Skinny',
        value: 'skinny',
      },
      {
        image: cdnFile('model-creator/female-anime/body-types/slim.webp'),
        displayName: 'Slim',
        value: 'slim',
      },
      {
        image: cdnFile('model-creator/female-anime/body-types/athletic.webp'),
        displayName: 'Athletic',
        value: 'athletic',
      },
      {
        image: cdnFile('model-creator/female-anime/body-types/thick.webp'),
        displayName: 'Thick',
        value: 'thick',
      },
    ],
  },
  {
    displayName: 'Breast Size',
    modifies: 'breastSize',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female-anime/breast-sizes/small.webp'),
        displayName: 'Small',
        value: 'small',
      },
      {
        image: cdnFile('model-creator/female-anime/breast-sizes/average.webp'),
        displayName: 'Average',
        value: 'average',
      },
      {
        image: cdnFile('model-creator/female-anime/breast-sizes/large.webp'),
        displayName: 'Large',
        value: 'large',
      },
      {
        image: cdnFile('model-creator/female-anime/breast-sizes/huge.webp'),
        displayName: 'Huge',
        value: 'huge',
      },
    ],
  },
  {
    displayName: 'Butt Size',
    modifies: 'buttSize',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/female-anime/butt-sizes/small.webp'),
        displayName: 'Small',
        value: 'small',
      },
      {
        image: cdnFile('model-creator/female-anime/butt-sizes/average.webp'),
        displayName: 'Average',
        value: 'average',
      },
      {
        image: cdnFile('model-creator/female-anime/butt-sizes/large.webp'),
        displayName: 'Large',
        value: 'large',
      },
      {
        image: cdnFile('model-creator/female-anime/butt-sizes/huge.webp'),
        displayName: 'Huge',
        value: 'huge',
      },
    ],
  },
  {
    displayName: 'Personality',
    modifies: 'personality',
    customModifies: 'customPersonality',
    isCustom: true,
    columns: 3,
    type: 'icon-radio',
    allowEmpty: true,
    allowNull: false,
    options: [
      {
        icon: hearts,
        displayName: 'Lover',
        displayDescription:
          'Romantic, affectionate, and cherishes emotional connection.',
        value: 'lover',
      },
      {
        icon: angel,
        displayName: 'Innocent',
        displayDescription: 'Optimistic, naive, and sees world with wonder.',
        value: 'innocent',
      },
      {
        icon: submissive,
        displayName: 'Submissive',
        displayDescription: 'Obedient, yielding, and happy to follow.',
        value: 'submissive',
      },
      {
        icon: flameAlt,
        displayName: 'Nympho',
        displayDescription:
          'Insatiable, passionate, and constantly craving intimacy.',
        value: 'nympho',
      },
      {
        icon: crown,
        displayName: 'Dominant',
        displayDescription: 'Assertive, controlling, and commanding.',
        value: 'dominant',
      },
      {
        icon: snowflake,
        displayName: 'Mean',
        displayDescription: 'Cold, dismissive, and often sarcastic.',
        value: 'mean',
      },
    ],
  },
  {
    displayName: 'Accent',
    modifies: 'voiceId',
    columns: 3,
    type: 'voice-radio',
    options: [
      {
        icon: flagUs,
        value: 'new-us-young-1',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-1.mp3',
          true,
        ),
        displayName: 'American 1',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-1',
        preview: cdnFile('model-creator/female/voices/new-us-old-1.mp3', true),
        displayName: 'American 1',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-2',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-2.mp3',
          true,
        ),
        displayName: 'American 2',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-2',
        preview: cdnFile('model-creator/female/voices/new-us-old-2.mp3', true),
        displayName: 'American 2',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-3',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-3.mp3',
          true,
        ),
        displayName: 'American 3',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-3',
        preview: cdnFile('model-creator/female/voices/new-us-old-3.mp3', true),
        displayName: 'American 3',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagUs,
        value: 'new-us-young-4',
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-4.mp3',
          true,
        ),
        displayName: 'American 4',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        value: 'new-us-old-4',
        preview: cdnFile('model-creator/female/voices/new-us-old-4.mp3', true),
        displayName: 'American 4',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagGb,
        value: 'new-gb-young',
        preview: cdnFile('model-creator/female/voices/new-gb-young.mp3', true),
        displayName: 'British',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagGb,
        value: 'new-gb-old',
        preview: cdnFile('model-creator/female/voices/new-gb-old.mp3', true),
        displayName: 'British',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagIn,
        value: 'new-in-young',
        preview: cdnFile('model-creator/female/voices/new-in-young.mp3', true),
        displayName: 'Indian',
        ethnicities: ['brown'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagIn,
        value: 'new-in-old',
        preview: cdnFile('model-creator/female/voices/new-in-old.mp3', true),
        displayName: 'Indian',
        ethnicities: ['brown'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAfrica,
        value: 'new-africa-young',
        preview: cdnFile(
          'model-creator/female/voices/new-africa-young.mp3',
          true,
        ),
        displayName: 'African',
        ethnicities: ['black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAfrica,
        value: 'new-africa-old',
        preview: cdnFile(
          'model-creator/female/voices/new-africa-old.mp3',
          true,
        ),
        displayName: 'African',
        ethnicities: ['black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAu,
        value: 'new-au-young',
        preview: cdnFile('model-creator/female/voices/new-au-young.mp3', true),
        displayName: 'Australian',
        ethnicities: ['white'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAu,
        value: 'new-au-old',
        preview: cdnFile('model-creator/female/voices/new-au-old.mp3', true),
        displayName: 'Australian',
        ethnicities: ['white'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagFr,
        value: 'new-fr',
        preview: cdnFile('model-creator/female/voices/new-fr.mp3', true),
        displayName: 'French',
        ethnicities: ['white', 'black'],
        startAge: 0,
        endAge: 80,
      },
    ],
  },
  {
    displayName: 'Occupation',
    modifies: 'occupation',
    customModifies: 'occupation',
    columns: 3,
    type: 'custom-icon-radio',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: tooth,
        displayName: 'Dentist',
        value: 'dentist',
      },
      {
        icon: bookOpen,
        displayName: 'Librarian',
        value: 'librarian',
      },
      {
        icon: brush,
        displayName: 'Designer',
        value: 'designer',
      },
      {
        icon: star,
        displayName: 'Stylist',
        value: 'stylist',
      },
      {
        icon: atom,
        displayName: 'Scientist',
        value: 'scientist',
      },
      {
        icon: pencilLine,
        displayName: 'Writer',
        value: 'writer',
      },
      {
        icon: glasses,
        displayName: 'Teacher',
        value: 'teacher',
      },
      {
        icon: flame,
        displayName: 'Firefighter',
        value: 'firefighter',
      },
      {
        icon: scale,
        displayName: 'Lawyer',
        value: 'lawyer',
      },
    ],
  },
  {
    displayName: 'Hobbies',
    modifies: 'hobbies',
    customModifies: 'hobbies',
    columns: 3,
    type: 'custom-icon-select',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: cyclist,
        displayName: 'Cycling',
        value: 'cycling',
      },
      {
        icon: blog,
        displayName: 'Blogging',
        value: 'blogging',
      },
      {
        icon: mountain,
        displayName: 'Hiking',
        value: 'hiking',
      },
      {
        icon: gamingPad,
        displayName: 'Gaming',
        value: 'gaming',
      },
      {
        icon: baloons,
        displayName: 'Partying',
        value: 'partying',
      },
      {
        icon: camera,
        displayName: 'Photography',
        value: 'photography',
      },
      {
        icon: hands,
        displayName: 'Volunteering',
        value: 'volunteering',
      },
      {
        icon: car,
        displayName: 'Cars',
        value: 'cars',
      },
      {
        icon: masks,
        displayName: 'Theater',
        value: 'theater',
      },
    ],
  },
  {
    displayName: 'Type a girl name...',
    displayTitle: 'Name Your Girlfriend',
    modifies: 'name',
    type: 'custom',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
  },
]

export const stepsMaleRealistic = [
  {
    displayName: 'Style',
    modifies: 'style',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/male/styles/realistic.webp'),
        displayName: 'Realistic',
        value: 'realistic',
      },
      {
        image: cdnFile('model-creator/male/styles/anime.webp'),
        displayName: 'Anime',
        value: 'anime',
      },
    ],
  },
  {
    displayName: 'Ethnicity',
    modifies: 'ethnicity',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/male/ethnicities/caucasian.webp'),
        displayName: 'Caucasian',
        value: 'caucasian',
      },
      {
        image: cdnFile('model-creator/male/ethnicities/asian.webp'),
        displayName: 'Asian',
        value: 'asian',
      },
      {
        image: cdnFile('model-creator/male/ethnicities/arabic.webp'),
        displayName: 'Arabic',
        value: 'arabic',
      },
      {
        image: cdnFile('model-creator/male/ethnicities/indian.webp'),
        displayName: 'Indian',
        value: 'indian',
      },
      {
        image: cdnFile('model-creator/male/ethnicities/latino.webp'),
        displayName: 'Latino',
        value: 'latino',
      },
      {
        image: cdnFile('model-creator/male/ethnicities/african.webp'),
        displayName: 'African',
        value: 'african',
      },
    ],
  },
  {
    displayName: 'Age',
    modifies: 'age',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/male/ages/twenties.webp'),
        displayName: 'Twenties',
        value: 20,
      },
      {
        image: cdnFile('model-creator/male/ages/thirties.webp'),
        displayName: 'Thirties',
        value: 30,
      },
      {
        image: cdnFile('model-creator/male/ages/fourties.webp'),
        displayName: 'Fourties',
        value: 40,
      },
      {
        image: cdnFile('model-creator/male/ages/fifties.webp'),
        displayName: 'Fifties',
        value: 50,
      },
    ],
  },
  {
    displayName: 'Eye Color',
    modifies: 'eyeColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/male/eye-colors/black.webp'),
        displayName: 'Black',
        value: 'black',
      },
      {
        image: cdnFile('model-creator/male/eye-colors/brown.webp'),
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: cdnFile('model-creator/male/eye-colors/gray.webp'),
        displayName: 'Gray',
        value: 'gray',
      },
      {
        image: cdnFile('model-creator/male/eye-colors/blue.webp'),
        displayName: 'Blue',
        value: 'blue',
      },
      {
        image: cdnFile('model-creator/male/eye-colors/green.webp'),
        displayName: 'Green',
        value: 'green',
      },
    ],
  },
  {
    displayName: 'Hair Style',
    modifies: 'hairStyle',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/male/hair-styles/straight.webp'),
        displayName: 'Straight',
        value: 'straight',
      },
      {
        image: cdnFile('model-creator/male/hair-styles/curly.webp'),
        displayName: 'Curly',
        value: 'curly',
      },
      {
        image: cdnFile('model-creator/male/hair-styles/dreadlocks.webp'),
        displayName: 'Dreadlocks',
        value: 'dreadlocks',
      },
      {
        image: cdnFile('model-creator/male/hair-styles/buzzCut.webp'),
        displayName: 'Buzz Cut',
        value: 'buzz cut',
      },
    ],
  },
  {
    displayName: 'Hair Color',
    modifies: 'hairColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/male/hair-colors/brown.webp'),
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: cdnFile('model-creator/male/hair-colors/black.webp'),
        displayName: 'Black',
        value: 'black',
      },
      {
        image: cdnFile('model-creator/male/hair-colors/blonde.webp'),
        displayName: 'Blonde',
        value: 'blonde',
      },
      {
        image: cdnFile('model-creator/male/hair-colors/ginger.webp'),
        displayName: 'Ginger',
        value: 'ginger',
      },
    ],
  },
  {
    displayName: 'Body Type',
    modifies: 'bodyType',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/male/body-types/muscular.webp'),
        displayName: 'Muscular',
        value: 'muscular',
      },
      {
        image: cdnFile('model-creator/male/body-types/slim.webp'),
        displayName: 'Slim',
        value: 'slim',
      },
    ],
  },
  {
    displayName: 'Personality',
    modifies: 'personality',
    customModifies: 'customPersonality',
    isCustom: true,
    columns: 2,
    type: 'icon-radio',
    allowEmpty: true,
    allowNull: false,
    options: [
      {
        icon: caring,
        displayName: 'Caring',
        displayDescription: 'Kind-hearted, nurturing, and empathetic.',
        value: 'caring',
      },
      {
        icon: crown,
        displayName: 'Dominant',
        displayDescription: 'Assertive, controlling, and commanding.',
        value: 'dominant',
      },
      {
        icon: submissive,
        displayName: 'Submissive',
        displayDescription: 'Obedient, yielding, and happy to follow.',
        value: 'submissive',
      },
      {
        icon: hearts,
        displayName: 'Lover',
        displayDescription:
          'Romantic, affectionate, and cherishes emotional connection.',
        value: 'lover',
      },
      {
        icon: protective,
        displayName: 'Protector',
        displayDescription: 'Strong, vigilant, and fiercely loyal.',
        value: 'protector',
      },
      {
        icon: badBoy,
        displayName: 'Bad Boy',
        displayDescription: 'Rebellious, daring, and charismatic.',
        value: 'bad boy',
      },
    ],
  },
  {
    displayName: 'Accent',
    modifies: 'voiceId',
    columns: 3,
    type: 'voice-radio',
    options: [
      {
        icon: flagUs,
        displayName: 'American 1',
        preview: cdnFile('model-creator/male/voices/us.mp3', true),
        value: 'male-us',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latino',
          'african',
        ],
        startAge: 18,
        endAge: 80,
      },
      {
        icon: flagUs,
        displayName: 'American 2',
        preview: cdnFile('model-creator/male/voices/us-young.mp3', true),
        value: 'male-us-young',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latino',
          'african',
        ],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        displayName: 'American 2',
        preview: cdnFile('model-creator/male/voices/us-old.mp3', true),
        value: 'male-us-old',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latino',
          'african',
        ],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagGb,
        displayName: 'British',
        preview: cdnFile('model-creator/male/voices/gb-young.mp3', true),
        value: 'male-gb-young',
        ethnicities: ['caucasian', 'arabic', 'indian', 'african'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagGb,
        displayName: 'British',
        preview: cdnFile('model-creator/male/voices/gb-old.mp3', true),
        value: 'male-gb-old',
        ethnicities: ['caucasian', 'arabic', 'indian', 'african'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAu,
        displayName: 'Australian',
        preview: cdnFile('model-creator/male/voices/au-young.mp3', true),
        value: 'male-au-young',
        ethnicities: ['caucasian', 'asian'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAu,
        displayName: 'Australian',
        preview: cdnFile('model-creator/male/voices/au-old.mp3', true),
        value: 'male-au-old',
        ethnicities: ['caucasian', 'asian'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagIn,
        displayName: 'Indian',
        preview: cdnFile('model-creator/male/voices/in-young.mp3', true),
        value: 'male-id-young',
        ethnicities: ['indian'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagIn,
        displayName: 'Indian',
        preview: cdnFile('model-creator/male/voices/in-old.mp3', true),
        value: 'male-id-old',
        ethnicities: ['indian'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAfrica,
        displayName: 'African',
        preview: cdnFile('model-creator/male/voices/africa-young.mp3', true),
        value: 'male-africa-young',
        ethnicities: ['african'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAfrica,
        displayName: 'African',
        preview: cdnFile('model-creator/male/voices/africa-old.mp3', true),
        value: 'male-africa-old',
        ethnicities: ['african'],
        startAge: 35,
        endAge: 80,
      },
    ],
  },
  {
    displayName: 'Occupation',
    modifies: 'occupation',
    columns: 3,
    type: 'custom-icon-radio',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: tooth,
        displayName: 'Dentist',
        value: 'dentist',
      },
      {
        icon: bookOpen,
        displayName: 'Librarian',
        value: 'librarian',
      },
      {
        icon: brush,
        displayName: 'Designer',
        value: 'designer',
      },
      {
        icon: star,
        displayName: 'Stylist',
        value: 'stylist',
      },
      {
        icon: atom,
        displayName: 'Scientist',
        value: 'scientist',
      },
      {
        icon: pencilLine,
        displayName: 'Writer',
        value: 'writer',
      },
      {
        icon: glasses,
        displayName: 'Teacher',
        value: 'teacher',
      },
      {
        icon: flame,
        displayName: 'Firefighter',
        value: 'firefighter',
      },
      {
        icon: scale,
        displayName: 'Lawyer',
        value: 'lawyer',
      },
    ],
  },
  {
    displayName: 'Hobbies',
    modifies: 'hobbies',
    columns: 3,
    type: 'custom-icon-select',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: cyclist,
        displayName: 'Cycling',
        value: 'cycling',
      },
      {
        icon: blog,
        displayName: 'Blogging',
        value: 'blogging',
      },
      {
        icon: mountain,
        displayName: 'Hiking',
        value: 'hiking',
      },
      {
        icon: gamingPad,
        displayName: 'Gaming',
        value: 'gaming',
      },
      {
        icon: baloons,
        displayName: 'Partying',
        value: 'partying',
      },
      {
        icon: camera,
        displayName: 'Photography',
        value: 'photography',
      },
      {
        icon: hands,
        displayName: 'Volunteering',
        value: 'volunteering',
      },
      {
        icon: car,
        displayName: 'Cars',
        value: 'cars',
      },
      {
        icon: masks,
        displayName: 'Theater',
        value: 'theater',
      },
    ],
  },
  {
    displayName: 'Man Name',
    displayTitle: 'Name Your Man',
    modifies: 'name',
    type: 'custom',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
  },
]

export const stepsMaleAnime = [
  {
    displayName: 'Style',
    modifies: 'style',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/male/styles/realistic.webp'),
        displayName: 'Realistic',
        value: 'realistic',
      },
      {
        image: cdnFile('model-creator/male/styles/anime.webp'),
        displayName: 'Anime',
        value: 'anime',
      },
    ],
  },
  {
    displayName: 'Ethnicity',
    modifies: 'ethnicity',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/male-anime/ethnicities/white.webp'),
        displayName: 'White',
        value: 'white',
      },
      {
        image: cdnFile('model-creator/male-anime/ethnicities/brown.webp'),
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: cdnFile('model-creator/male-anime/ethnicities/black.webp'),
        displayName: 'Black',
        value: 'black',
      },
    ],
  },
  {
    displayName: 'Age',
    modifies: 'age',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/male-anime/ages/twenties.webp'),
        displayName: 'Twenties',
        value: 20,
      },
      {
        image: cdnFile('model-creator/male-anime/ages/thirties.webp'),
        displayName: 'Thirties',
        value: 30,
      },
      {
        image: cdnFile('model-creator/male-anime/ages/fourties.webp'),
        displayName: 'Fourties',
        value: 40,
      },
      {
        image: cdnFile('model-creator/male-anime/ages/fifties.webp'),
        displayName: 'Fifties',
        value: 50,
      },
    ],
    sidenote: 'The age setting will not impact how your anime will look like.',
  },
  {
    displayName: 'Eye Color',
    modifies: 'eyeColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/male-anime/eye-colors/black.webp'),
        displayName: 'Black',
        value: 'black',
      },
      {
        image: cdnFile('model-creator/male-anime/eye-colors/brown.webp'),
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: cdnFile('model-creator/male-anime/eye-colors/gray.webp'),
        displayName: 'Gray',
        value: 'gray',
      },
      {
        image: cdnFile('model-creator/male-anime/eye-colors/blue.webp'),
        displayName: 'Blue',
        value: 'blue',
      },
      {
        image: cdnFile('model-creator/male-anime/eye-colors/green.webp'),
        displayName: 'Green',
        value: 'green',
      },
    ],
  },
  {
    displayName: 'Hair Style',
    modifies: 'hairStyle',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/male-anime/hair-styles/straight.webp'),
        displayName: 'Straight',
        value: 'straight',
      },
      {
        image: cdnFile('model-creator/male-anime/hair-styles/curly.webp'),
        displayName: 'Curly',
        value: 'curly',
      },
      {
        image: cdnFile('model-creator/male-anime/hair-styles/dreadlocks.webp'),
        displayName: 'Dreadlocks',
        value: 'dreadlocks',
      },
      {
        image: cdnFile('model-creator/male-anime/hair-styles/buzzCut.webp'),
        displayName: 'Buzz Cut',
        value: 'buzz cut',
      },
    ],
  },
  {
    displayName: 'Hair Color',
    modifies: 'hairColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/male-anime/hair-colors/brown.webp'),
        displayName: 'Brown',
        value: 'brown',
      },
      {
        image: cdnFile('model-creator/male-anime/hair-colors/black.webp'),
        displayName: 'Black',
        value: 'black',
      },
      {
        image: cdnFile('model-creator/male-anime/hair-colors/blonde.webp'),
        displayName: 'Blonde',
        value: 'blonde',
      },
      {
        image: cdnFile('model-creator/male-anime/hair-colors/ginger.webp'),
        displayName: 'Ginger',
        value: 'ginger',
      },
    ],
  },
  {
    displayName: 'Body Type',
    modifies: 'bodyType',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        image: cdnFile('model-creator/male-anime/body-types/muscular.webp'),
        displayName: 'Muscular',
        value: 'muscular',
      },
      {
        image: cdnFile('model-creator/male-anime/body-types/slim.webp'),
        displayName: 'Slim',
        value: 'slim',
      },
    ],
  },
  {
    displayName: 'Personality',
    modifies: 'personality',
    customModifies: 'customPersonality',
    isCustom: true,
    columns: 2,
    type: 'icon-radio',
    allowEmpty: true,
    allowNull: false,
    options: [
      {
        icon: caring,
        displayName: 'Caring',
        displayDescription: 'Kind-hearted, nurturing, and empathetic.',
        value: 'caring',
      },
      {
        icon: crown,
        displayName: 'Dominant',
        displayDescription: 'Assertive, controlling, and commanding.',
        value: 'dominant',
      },
      {
        icon: submissive,
        displayName: 'Submissive',
        displayDescription: 'Obedient, yielding, and happy to follow.',
        value: 'submissive',
      },
      {
        icon: hearts,
        displayName: 'Lover',
        displayDescription:
          'Romantic, affectionate, and cherishes emotional connection.',
        value: 'lover',
      },
      {
        icon: protective,
        displayName: 'Protector',
        displayDescription: 'Strong, vigilant, and fiercely loyal.',
        value: 'protector',
      },
      {
        icon: badBoy,
        displayName: 'Bad Boy',
        displayDescription: 'Rebellious, daring, and charismatic.',
        value: 'bad boy',
      },
    ],
  },
  {
    displayName: 'Accent',
    modifies: 'voiceId',
    columns: 3,
    type: 'voice-radio',
    options: [
      {
        icon: flagUs,
        displayName: 'American 1',
        preview: cdnFile('model-creator/male/voices/us.mp3', true),
        value: 'male-us',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 18,
        endAge: 80,
      },
      {
        icon: flagUs,
        displayName: 'American 2',
        preview: cdnFile('model-creator/male/voices/us-young.mp3', true),
        value: 'male-us-young',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagUs,
        displayName: 'American 2',
        preview: cdnFile('model-creator/male/voices/us-old.mp3', true),
        value: 'male-us-old',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagGb,
        displayName: 'British',
        preview: cdnFile('model-creator/male/voices/gb-young.mp3', true),
        value: 'male-gb-young',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagGb,
        displayName: 'British',
        preview: cdnFile('model-creator/male/voices/gb-old.mp3', true),
        value: 'male-gb-old',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAu,
        displayName: 'Australian',
        preview: cdnFile('model-creator/male/voices/au-young.mp3', true),
        value: 'male-au-young',
        ethnicities: ['white'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAu,
        displayName: 'Australian',
        preview: cdnFile('model-creator/male/voices/au-old.mp3', true),
        value: 'male-au-old',
        ethnicities: ['white'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagIn,
        displayName: 'Indian',
        preview: cdnFile('model-creator/male/voices/in-young.mp3', true),
        value: 'male-id-young',
        ethnicities: ['brown'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagIn,
        displayName: 'Indian',
        preview: cdnFile('model-creator/male/voices/in-old.mp3', true),
        value: 'male-id-old',
        ethnicities: ['brown'],
        startAge: 35,
        endAge: 80,
      },
      {
        icon: flagAfrica,
        displayName: 'African',
        preview: cdnFile('model-creator/male/voices/africa-young.mp3', true),
        value: 'male-africa-young',
        ethnicities: ['black'],
        startAge: 0,
        endAge: 34,
      },
      {
        icon: flagAfrica,
        displayName: 'African',
        preview: cdnFile('model-creator/male/voices/africa-old.mp3', true),
        value: 'male-africa-old',
        ethnicities: ['black'],
        startAge: 35,
        endAge: 80,
      },
    ],
  },
  {
    displayName: 'Occupation',
    modifies: 'occupation',
    columns: 3,
    type: 'custom-icon-radio',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: tooth,
        displayName: 'Dentist',
        value: 'dentist',
      },
      {
        icon: bookOpen,
        displayName: 'Librarian',
        value: 'librarian',
      },
      {
        icon: brush,
        displayName: 'Designer',
        value: 'designer',
      },
      {
        icon: star,
        displayName: 'Stylist',
        value: 'stylist',
      },
      {
        icon: atom,
        displayName: 'Scientist',
        value: 'scientist',
      },
      {
        icon: pencilLine,
        displayName: 'Writer',
        value: 'writer',
      },
      {
        icon: glasses,
        displayName: 'Teacher',
        value: 'teacher',
      },
      {
        icon: flame,
        displayName: 'Firefighter',
        value: 'firefighter',
      },
      {
        icon: scale,
        displayName: 'Lawyer',
        value: 'lawyer',
      },
    ],
  },
  {
    displayName: 'Hobbies',
    modifies: 'hobbies',
    columns: 3,
    type: 'custom-icon-select',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        icon: cyclist,
        displayName: 'Cycling',
        value: 'cycling',
      },
      {
        icon: blog,
        displayName: 'Blogging',
        value: 'blogging',
      },
      {
        icon: mountain,
        displayName: 'Hiking',
        value: 'hiking',
      },
      {
        icon: gamingPad,
        displayName: 'Gaming',
        value: 'gaming',
      },
      {
        icon: baloons,
        displayName: 'Partying',
        value: 'partying',
      },
      {
        icon: camera,
        displayName: 'Photography',
        value: 'photography',
      },
      {
        icon: hands,
        displayName: 'Volunteering',
        value: 'volunteering',
      },
      {
        icon: car,
        displayName: 'Cars',
        value: 'cars',
      },
      {
        icon: masks,
        displayName: 'Theater',
        value: 'theater',
      },
    ],
  },
  {
    displayName: 'Man Name',
    displayTitle: 'Name Your Man',
    modifies: 'name',
    type: 'custom',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
  },
]

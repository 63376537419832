<script setup>
import { ref, computed, inject, onMounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import cdnFile from '@/helpers/cdnFile'
import { useToast } from 'vue-toast-notification'
import RegisterModal from '@/components/RegisterModal.vue'
import { createRandomAttributes } from '@/helpers/createRandomAttributes'
import { useHead } from '@unhead/vue'
import Chat from '@/components/icons/Chat.vue'
import Generate from '../components/icons/Generate.vue'
import refresh from '@/assets/icons/refresh-2.svg'
import Button from '../components/Button.vue'
import { useMixpanel } from '@/composables/mixpanel'
import { createTimeElapsedCounter } from '@/helpers/timeElapsed'
import { storeToRefs } from 'pinia'
import { useCreateModelStore } from '@/stores/createModel'
import logo from '@/assets/logo.svg'
import queryString from 'query-string'
import { encryptURIComponent } from '@/helpers/uriEncrypt'
import sleep from '@/helpers/sleep'

useHead({
  title: `${import.meta.env.VITE_BRAND_NAME} | View AI Character`,
  meta: [{ name: 'robots', content: 'noindex' }],
})

const mixpanel = useMixpanel()

const $store = useStore()
const $route = useRoute()
const $router = useRouter()
const $toast = useToast()

const createModelFunnelId = $route.query.funnel_id
const createModelFunnelTimeElapsed = createTimeElapsedCounter(
  $route.query.funnel_time_elapsed || 0,
)

const orphanModel = ref(null)

const user = computed(() => $store.state.user)
const model = computed(() => orphanModel.value || $store.getters.model)
const discountPopupOpen = computed(() => $store.state.discountPopupOpen)

const createModelStore = useCreateModelStore()
const { pendingModel } = storeToRefs(createModelStore)

const $axios = inject('axios')

const registerOpen = ref(false)

const loadingLimit = 15
const loading = ref(false)
const loadingCounter = ref(loadingLimit)
const loadingIntervalID = ref(0)

function setLoading(flag) {
  loading.value = flag
  if (flag) {
    loadingCounter.value = loadingLimit
    loadingIntervalID.value = setInterval(() => {
      loadingCounter.value -= 1
    }, 1000)
  } else {
    clearInterval(loadingIntervalID.value)
  }
  console.log(loading)
}

const loadingDisplayValue = computed(() =>
  loadingCounter.value > 0
    ? loadingCounter.value
    : loadingCounter.value > -10
      ? 'Just a few more seconds...'
      : loadingCounter.value > -20
        ? 'Getting there shortly...'
        : loadingCounter.value > -30
          ? 'Almost there...'
          : loadingCounter.value > -40
            ? 'Hang on tight...'
            : 'Any time now...',
)

setLoading(true)

async function generateCaptchaToken(action) {
  if (
    !window.grecaptcha ||
    !window.grecaptcha.enterprise ||
    !window.grecaptcha.enterprise.execute
  ) {
    await sleep(2000)
  }

  return await grecaptcha.enterprise.execute(
    import.meta.env.VITE_RECAPTCHA_KEY,
    {
      action,
    },
  )
}

const modelData = { ...pendingModel.value }

const generationCount = ref(0)

async function regenerate() {
  setLoading(true)
  let newModel

  let newAttributes

  if (model.value.random) {
    newAttributes = await createRandomAttributes(
      model.value.gender,
      model.value.style,
      ['gender', 'style'],
    )
  }

  mixpanel.track('create_model_regenerate_start', {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    generation_count: generationCount.value,
    model_metadata: modelData,
    random: modelData.random,
  })

  const regenerateModelTimeElapsed = createTimeElapsedCounter()

  try {
    if (!user.value) {
      const captchaToken = await generateCaptchaToken('regenerate_model')

      newModel = await $axios
        .post(
          '/model/regenerate',
          { modelId: model.value.id, newAttributes },
          {
            headers: {
              'Captcha-Token': captchaToken,
            },
          },
        )
        .then((res) => res.data.model)
    } else {
      newModel = await $axios
        .post('/user/regenerate-model', {
          modelId: model.value.id,
          newAttributes,
        })
        .then((res) => res.data.model)
    }
  } catch (e) {
    setLoading(false)
    const message = e?.response?.data?.message
    return $toast.error(message || 'An error occurred')
  }

  mixpanel.track('create_model_regenerate_finish', {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    generation_count: generationCount.value,
    model_metadata: modelData,
    random: modelData.random,
    generation_time_elapsed: regenerateModelTimeElapsed(),
  })

  if (!user.value) {
    orphanModel.value = newModel
  } else {
    $store.commit('REPLACE_MODEL', {
      oldModelId: model.value.id,
      newModel,
    })
  }

  generationCount.value += 1

  setLoading(false)
}

function backButtonBlocker(event) {
  history.pushState(null, document.title, location.href)
}

function warnOnUnload(event) {
  const confirmationMessage =
    'Please wait for your girl to finish loading and then click on "Continue to chat" to proceed to the chat.'

  ;(event || window.event).returnValue = confirmationMessage
  return confirmationMessage
}

onMounted(async () => {
  nextTick(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  })

  mixpanel.track('create_model_created_start', {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    generation_count: generationCount.value,
    model_metadata: modelData,
    random: modelData.random,
  })

  const createModelTimeElapsed = createTimeElapsedCounter()

  if (user.value) {
    let createModelData

    try {
      createModelData = await $axios
        .post('/user/create-model', {
          model: modelData,
        })
        .then((res) => res.data)
    } catch (e) {
      setLoading(false)
      const message = e?.response?.data?.message
      return $toast.error(message || 'An error occurred')
    }

    if (user.value?.subscription)
      $store.dispatch('changeLuna', -createModelData.changedLuna)
    $store.commit('ADD_MODEL', createModelData.model)
    $store.commit('SET_SELECTED_MODEL', createModelData.model.id)
    $store.dispatch('setMessages', createModelData.model.messages)
    setLoading(false)
  } else {
    const captchaToken = await generateCaptchaToken('create_model')

    let createModelData
    try {
      createModelData = await $axios
        .post(
          '/model/create',
          {
            model: modelData,
          },
          {
            headers: {
              'Captcha-Token': captchaToken,
            },
          },
        )
        .then((res) => res.data)
    } catch (e) {
      setLoading(false)
      const message = e?.response?.data?.message
      return $toast.error(message || 'An error occurred')
    }

    orphanModel.value = createModelData.model
    setLoading(false)
  }

  mixpanel.track('create_model_created_finish', {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    generation_count: generationCount.value,
    model_metadata: modelData,
    random: modelData.random,
    generation_time_elapsed: createModelTimeElapsed(),
  })

  generationCount.value += 1

  // Block back button
  // history.pushState(null, document.title, location.href);
  // window.addEventListener('popstate', backButtonBlocker)
  // window.addEventListener('beforeunload', warnOnUnload)
})

// onUnmounted(() => {
// window.removeEventListener('popstate', backButtonBlocker)
// window.removeEventListener('beforeunload', warnOnUnload)
// })

function continueToChat() {
  if (!user.value) {
    // window.removeEventListener('beforeunload', warnOnUnload)
    registerOpen.value = true
    return
  }

  mixpanel.track('create_model_finish', {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    generation_count: generationCount.value,
    model_metadata: model.value,
    random: model.value.random,
    redirect_to:
      $route.query.from === 'image-generator' ? 'image-generator' : 'chat',
  })

  const redirectQuery = queryString.stringify({
    modelId: encryptURIComponent(model.value.id),
  })

  $router.push(
    ($route.query.from === 'image-generator' ? '/image-generator' : '/chat') +
      '?' +
      redirectQuery,
  )
}

$store.commit('UPDATE_LAST_APP_PAGE')
</script>

<template>
  <div
    class="lg:ml-[280px] lg:pt-[103px] pb-[100px] lg:pb-[19px] px-4 lg:px-0 sm:h-auto"
    :class="discountPopupOpen ? 'pt-[156px]' : 'pt-[42px]'"
  >
    <div class="lg:mx-auto lg:px-8 flex flex-col items-center">
      <template v-if="loading">
        <p
          class="text-[30px] lg:text-[35px] text-center font-medium leading-[47px] mb-4"
        >
          Creating your
          {{ modelData.gender === 'female' ? 'girlfriend' : 'boyfriend' }}
        </p>
        <p class="text-center font-normal text-[#7D829E]">
          Please wait and do not close your browser
        </p>
      </template>
      <div v-if="model" class="text-white mb-10 text-center">
        <template v-if="!loading">
          <img
            class="w-[148px] h-auto lg:hidden mx-auto mb-[25px]"
            :src="cdnFile('logo.svg', true)"
          />
          <p
            class="text-[30px] lg:text-[35px] font-medium leading-[30px] lg:leading-[35px]"
          >
            <template v-if="!registerOpen">
              Say Hello to {{ (pendingModel || model)?.name }}!
            </template>
            <template v-else> Create a Free Account </template>
          </p>
        </template>
      </div>
      <div
        class="lg:mx-auto w-full flex flex-col justify-center items-center"
        :class="registerOpen ? 'lg:max-w-[472px]' : ''"
      >
        <div class="w-full flex flex-col items-center justify-center">
          <div
            v-if="loading"
            class="mt-[71px] relative w-[240px] h-[240px] rounded-full border-[20px] border-[#A15CFF] border-opacity-30"
          >
            <div
              class="h-[228px] w-[228px] absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 rounded-full"
              style="
                box-shadow:
                  0px 0px 30px 0px #cc47ff,
                  0px 0px 30px 0px #cc47ff inset;
              "
            >
              <div class="loader border-b-transparent w-full h-full"></div>
            </div>
            <p
              class="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 text-[75px] font-medium"
            >
              {{ loadingDisplayValue > 0 ? loadingDisplayValue : '' }}
            </p>
          </div>
          <div
            v-if="loading"
            class="text-[25px] font-medium leading-[33px] mt-[34px]"
          >
            {{ isNaN(loadingDisplayValue) ? loadingDisplayValue : '' }}
          </div>
          <div
            v-else-if="model"
            class="relative p-[3px] rounded-[25px] w-full max-w-[500px] h-auto"
            style="
              background: linear-gradient(
                19.86deg,
                #a359ff 10.28%,
                rgba(184, 0, 255, 0) 83.75%
              );
            "
          >
            <img
              :src="cdnFile(model.referenceImage)"
              class="relative h-auto object-cover rounded-[25px] select-none w-full max-w-[500px]"
              style="
                user-select: none;
                user-drag: none;
                -webkit-user-drag: none;
                pointer-events: none;
              "
              draggable="false"
            />
          </div>
        </div>
        <template v-if="!loading && !registerOpen">
          <Button
            :disabled="loading"
            class="flex justify-center items-center gap-[15px] mt-[17px] py-[14px] font-medium lg:max-w-[338px] w-full"
            @click="continueToChat"
          >
            Continue to
            {{
              $route.query.from === 'image-generator'
                ? 'image generator'
                : 'chat'
            }}
            <Chat
              v-if="
                $route.query.from === 'chat' ||
                $route.query.from === 'undefined' ||
                $route.query.from === undefined
              "
              :color="white"
              :size="24"
            />
            <Generate v-else />
          </Button>
          <p class="text-center font-medium my-[19px] lg:max-w-[338px] w-full">
            Don’t like how
            {{ model?.gender === 'female' ? 'she' : 'he' }} looks?
          </p>
          <Button
            :disabled="loading"
            variant="secondary"
            :borderVisible="false"
            class="flex justify-center items-center gap-[10px] py-[15px] font-medium lg:max-w-[338px] w-full"
            @click="regenerate"
          >
            Click to regenerate.
            <img :src="refresh" />
          </Button>
          <div v-if="!user?.models?.length" class="text-center mt-5 text-sm">
            You'll be able to create more
            {{ model?.gender === 'female' ? 'girls' : 'men' }} inside the app
          </div>
        </template>

        <RegisterModal
          v-if="registerOpen"
          :with-accept-policies="true"
          :cta-text="`Meet ${pendingModel.name}`"
          class="mt-10 w-full"
          :model-ids="[orphanModel.id]"
          @registered="continueToChat"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import cdnFile from '@/helpers/cdnFile.js'
import star from '@/assets/icons/star.svg'
import infoCircle from '@/assets/icons/info-circle.svg'

const props = defineProps({
  modelInfo: Object,
  isMobileView: Boolean,
  position: Number,
})

const { modelInfo, isMobileView, position } = toRefs(props)

const calculatedModelRoute = computed(() => {
  if (props.modelInfo.model.gender === 'female') {
    return `/ai-girlfriend/${props.modelInfo.model.name.toLowerCase()}`
  }
  return `/ai-boyfriend/${props.modelInfo.model.name.toLowerCase()}`
})

const emit = defineEmits(['model-clicked', 'model-details-clicked'])

function onModelInfoClick(e) {
  e.stopPropagation()
  emit('model-details-clicked')
}

const modelPicture = computed(() =>
  !isMobileView.value
    ? cdnFile(modelInfo.value.model.referenceImage)
    : cdnFile(
        modelInfo.value.model.referenceImage.replace(
          'premade/',
          'premade-mobile/',
        ),
      ),
)
</script>

<template>
  <div @click="$emit('model-clicked')" class="cursor-pointer">
    <div
      class="relative rounded-t-[15px] lg:rounded-t-[20px] overflow-hidden max-w-[75vw] sm:max-w-unset"
    >
      <div
        class="shadow-[inset_0_-60px_40px_rgba(0,0,0,0.66)] absolute top-0 left-0 w-full h-full"
      ></div>
      <div
        class="h-22 absolute top-2 right-2 lg:top-[15px] lg:right-[15px] flex flex-col gap-3"
      >
        <RouterLink
          :to="calculatedModelRoute"
          class="bg-[#0A0D1E] p-[7px] rounded-3xl opacity-80"
          @click="onModelInfoClick"
        >
          <img :src="infoCircle" class="h-[22px] w-[22px] sm:h-6 sm:w-6" />
        </RouterLink>
      </div>
      <img
        class="w-full aspect-[832/1216]"
        :src="modelPicture"
        :alt="modelInfo.model.name"
        style="user-select: none; user-drag: none; pointer-events: none"
        draggable="false"
        :loading="position < (!isMobileView ? 4 : 2) ? 'eager' : 'lazy'"
      />
      <div class="absolute bottom-0 left-0 px-[18px] pb-[12px] w-full">
        <div class="flex flex-col gap-2 sm:gap-4">
          <div
            class="flex flex-col sm:flex-row items-baseline gap-[5px] sm:gap-[10px]"
          >
            <h4 class="block text-lg lg:text-[25px] font-[500] leading-5">
              {{ modelInfo.model.name }}
            </h4>
            <span class="text-4 text-[#B1B5DB] leading-4"
              >{{ modelInfo.model.age }} Years</span
            >
          </div>
        </div>
        <!-- <p class="text-sm line-clamp-4">{{modelInfo.description}}</p> -->
      </div>
    </div>
    <div
      class="flex items-center gap-[10px] pl-[10px] pr-[7px] sm:px-[14px] h-[55px] text-[13px] lg:text-[14px] border border-white border-opacity-15 rounded-b-[15px] lg:rounded-b-[20px] font-medium"
      style="
        background: rgb(14, 18, 42);
        box-shadow:
          rgb(30, 26, 61) 0px 0px 0px 1px,
          rgb(19, 20, 46) 0px 0px 10px 2px inset;
      "
    >
      <img :src="star" />
      {{ modelInfo.phrase }}
    </div>
  </div>
</template>

<style scoped></style>

<script setup>
import { computed, toRefs } from 'vue'
import Button from '../../../components/Button.vue'
import bannerGf from '@/assets/img/banner-gf.webp'
import bannerGfSmall from '@/assets/img/banner-gf-small.webp'
import bannerGfAnime from '@/assets/img/banner-gf-anime.webp'
import bannerGfAnimeSmall from '@/assets/img/banner-gf-anime-small.webp'
import bannerBf from '@/assets/img/banner-bf.webp'
import bannerBfSmall from '@/assets/img/banner-bf-small.webp'
import bannerBfAnime from '@/assets/img/banner-bf-anime.webp'
import bannerBfAnimeSmall from '@/assets/img/banner-bf-anime-small.webp'
import magicWand from '@/assets/icons/magic-wand.svg'
import { useWindowSize } from '@vueuse/core'

const props = defineProps({
  gender: String,
  style: String,
})

const { gender, style } = toRefs(props)

const windowSize = useWindowSize()

const bannerPicture = computed(() => {
  const bannerBreakpoint = 1024 // 1024px

  const isMobileView = windowSize.width.value < bannerBreakpoint

  if (gender.value == 'female') {
    if (style.value == 'realistic') {
      if (isMobileView) {
        return bannerGfSmall
      }

      return bannerGf
    } else if (style.value == 'anime') {
      if (isMobileView) {
        return bannerGfAnimeSmall
      }

      return bannerGfAnime
    }
  } else {
    if (style.value == 'realistic') {
      if (isMobileView) {
        return bannerBfSmall
      }

      return bannerBf
    } else if (style.value == 'anime') {
      if (isMobileView) {
        return bannerBfAnimeSmall
      }

      return bannerBfAnime
    }
  }
})
</script>

<template>
  <div
    class="explore-banner flex items-center pt-[10px] pb-[15px] px-5 lg:px-0 lg:py-16 lg:pl-[52px] rounded-[25px] border border-[#FFFFFF0D] relative"
    style="
      background: radial-gradient(
        123.96% 123.96% at 18.89% 102.14%,
        rgba(76, 12, 81, 0.2) 0%,
        rgba(37, 6, 38, 0) 100%
      );
    "
  >
    <div
      class="lg:w-[40%] 2xl:w-[50%] flex flex-col items-start gap-4 lg:gap-[27px] relative z-10 select-none"
    >
      <h1
        class="text-lg lg:text-[35px] lg:leading-[45px] font-medium select-text"
      >
        Create your own <br class="lg:hidden" />
        <span class="text-purple-500"
          >AI {{ gender == 'female' ? 'Girlfriend' : 'Boyfriend' }}</span
        >
      </h1>
      <p class="w-full text-[16px] select-text hidden lg:block">
        Meet your new {{ style == 'anime' ? 'anime ' : '' }}AI
        {{ gender == 'female' ? 'girlfriend' : 'boyfriend' }}! Design
        {{ gender == 'female' ? 'her' : 'his' }} looks and personality just the
        way you want, and with one click, watch
        {{ gender == 'female' ? 'her' : 'him' }} come to life. All thanks to the
        magic of artificial intelligence.
      </p>
      <Button
        :to="gender == 'female' ? '/create-girlfriend' : '/create-boyfriend'"
        class="flex items-center gap-[5px] lg:gap-[15px] py-2 px-2 lg:py-[10px] lg:pl-[11px] lg:pr-[19px] text-[14px] font-medium"
      >
        <img :src="magicWand" class="h-5 w-5 lg:h-6 lg:w-6" />
        <span>
          Create <span class="hidden lg:inline">AI</span>
          {{ gender == 'female' ? 'Girlfriend' : 'Boyfriend' }}
        </span>
        <!-- <span class="lg:hidden"> Create </span> -->
      </Button>
    </div>
    <img
      :src="bannerPicture"
      alt="banner"
      style="user-select: none; -webkit-user-drag: none; user-drag: none"
      class="inline absolute top-0 right-0 h-[100%] rounded-tr-[25px] select-none rounded-br-[25px] object-cover"
      loading="eager"
    />
  </div>
</template>

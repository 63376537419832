<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import discounts from 'common/lib/discount'
import { useDiscountTimer } from '@/composables/useDiscountTimer'
import xClose from '@/assets/icons/x-close.svg'
import logoiconWhite from '@/assets/logoicon-white.svg'

const emit = defineEmits(['update:open'])

function close() {
  emit('update:open', false)
}

const { time, startTimer, clearTimer } = useDiscountTimer()
const discountCode = computed(() => $store.state.discountCode)

const discountRef = ref(null)
const $router = useRouter()
const $store = useStore()

const user = computed(() => $store.state.user)

function redirectToSubscribe() {
  $router.push('/subscribe')
  close()
}
function redirectToBuyLuna() {
  $router.push('/buy-luna')
  close()
}

function handleDiscountClick() {
  if (discounts[discountCode.value].type == 'Purchases') {
    user.value.subscription ? redirectToBuyLuna() : redirectToSubscribe()
  } else if (discounts[discountCode.value].type == 'Subscriptions') {
    redirectToSubscribe()
  } else {
    user.value.subscription ? redirectToBuyLuna() : redirectToSubscribe()
  }
}

const discountText = `${import.meta.env.VITE_BRAND_NAME} is now 50% off!`

onMounted(() => {
  startTimer()
})

onBeforeUnmount(() => {
  clearTimer()
})
</script>

<template>
  <div
    class="fixed top pt-[11px] lg:pt-[15px] top-0 w-full px-[13px] lg:px-[16px] bg-[#070917] z-50"
  >
    <div
      ref="discountRef"
      class="relative flex flex-col lg:flex-row justify-between items-center rounded-[15px] py-[9px] pl-[15px] pr-[11px]"
      style="
        background: radial-gradient(
          255.14% 174.74% at 38.76% 155.71%,
          #cc47ff 0%,
          #9a5cff 100%
        );
      "
    >
      <button
        @click="close"
        class="lg:hidden absolute top-[7px] right-[7px] flex justify-center items-center bg-[#151833] bg-opacity-25 w-[22px] h-[22px] rounded-[6px]"
      >
        <img class="w-4 h-4" :src="xClose" />
      </button>
      <div class="flex gap-3 items-center">
        <img
          class="hidden lg:block w-[33px] h-auto"
          :src="logoiconWhite"
          alt="Logo"
        />
        <p class="font-medium">{{ discountText }}</p>
      </div>
      <div
        class="flex flex-col lg:flex-row items-center gap-[6px] lg:gap-[25px]"
      >
        <p class="font-bold w-[200px]">
          Offer ends in:
          {{
            `${time.hours}:${time.minutes < 10 ? `0${time.minutes}` : `${time.minutes}`}:${time.seconds < 10 ? `0${time.seconds}` : `${time.seconds}`}`
          }}
        </p>
        <button
          type="button"
          class="text-[14px] py-[5px] px-[33px] cursor-pointer z-10 bg-[#0A0D1E] rounded-[10px]"
          style="
            box-shadow:
              0px 0px 0px 1px #1e1a3d,
              0px 0px 10px 2px #13142e inset;
          "
          @click="handleDiscountClick"
        >
          Claim Offer
        </button>
      </div>
    </div>
  </div>
</template>

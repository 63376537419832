<script setup>
import { ref, inject, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
import { useToast } from 'vue-toast-notification'
import googleAuth from '@/services/google-auth'
import twitterAuth from '@/services/twitter-auth'
import cdnFile from '@/helpers/cdnFile'
import { useHead } from '@unhead/vue'
import authParticles from '@/assets/img/auth-particles.svg'
import Button from '../../components/Button.vue'
import { useMixpanel } from '@/composables/mixpanel'
import logo from '@/assets/logo.svg'

useHead({
  title: `${import.meta.env.VITE_BRAND_NAME} | Login`,
})

const mixpanel = useMixpanel()

mixpanel.track('login_page_visit', {}, { source: 'previous' })

const $axios = inject('axios')
const $store = useStore()
const $router = useRouter()
const $toast = useToast()

const email = ref('')
const password = ref('')

const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
})

const analytics = computed(() => $store.state.analytics)

function google() {
  window.location.href = googleAuth.getLoginUrl({
    ...analytics.value,
    redirectPath: decodeURIComponent($store.state.lastAppPage),
  })
}

function twitter() {
  window.location.href = twitterAuth.getLoginUrl({
    ...analytics.value,
    redirectPath: decodeURIComponent($store.state.lastAppPage),
  })
}

async function login() {
  const loader = $loading.show()
  const premadeModel = computed(() => $store.state.premadeModel)

  $axios
    .post('/auth/signin', {
      email: email.value,
      password: password.value,
      modelIds: premadeModel.value ? [premadeModel.value.id] : [],
    })
    .then(async (res) => {
      await $store.dispatch('setUser', res.data)
      await $store.dispatch('setPremadeModel', null)
      await $router.push(decodeURIComponent($store.state.lastAppPage) || '/')
      return res.data
    })
    .catch((e) => {
      const message = e?.response?.data?.message
      $toast.error(
        message || 'Unable to perform action. Please try again later.',
      )
    })
    .finally(() => {
      loader.hide()
    })
}
</script>

<template>
  <div class="flex min-h-full flex-1 relative">
    <img class="absolute bottom-0 left-0 w-full" :src="authParticles" />
    <div
      class="flex flex-1 flex-col justify-center px-2 py-4 lg:py-12 2xl:py-0 sm:px-6 lg:px-20 xl:px-24"
    >
      <div
        class="relative z-20 bg-[#0A0D22] pt-[63px] pb-[18px] lg:pb-[45px] lg:mx-auto rounded-[35px] border border-[#141A3D] border-opacity-70 px-4 lg:px-[75px] lg:w-[625px]"
      >
        <div class="flex flex-col items-center gap-[30px]">
          <img
            class="w-[148px] h-auto"
            :src="cdnFile('logo.svg', true)"
            alt="Logo"
          />
          <h2
            class="text-[30px] lg:text-[35px] font-medium leading-[35px] lg:leading-9 text-center"
          >
            Sign in to your Account
          </h2>
          <p class="text-[15px] text-[#B1B5DB]">
            Don't have an account?
            <router-link to="/register" class="text-[#CC47FF] font-bold"
              >Signup</router-link
            >
          </p>
        </div>

        <div class="mt-[45px] lg:mt-[30px]">
          <div>
            <form
              action="#"
              method="POST"
              class="space-y-[27px]"
              @submit.prevent="login"
            >
              <div>
                <label
                  for="email"
                  class="block font-medium leading-6 text-white text-opacity-80"
                  >Email Address</label
                >
                <div class="mt-[10px]">
                  <input
                    id="email"
                    v-model="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required=""
                    class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631]"
                  />
                </div>
              </div>

              <div>
                <label
                  for="password"
                  class="block font-medium leading-6 text-white text-opacity-80"
                  >Password</label
                >
                <div class="mt-[10px]">
                  <input
                    id="password"
                    v-model="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required=""
                    class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631]"
                  />
                </div>
              </div>

              <div
                class="flex lg:justify-between flex-col gap-2 lg:gap-[unset] lg:flex-row text-[14px] font-medium"
              >
                <router-link to="/forgot-password"
                  >Forgot password?</router-link
                >
              </div>

              <div>
                <Button type="submit" class="w-full py-[14px] font-medium">
                  Login
                </Button>
              </div>
            </form>
          </div>

          <div>
            <p class="my-[35px] text-center text-[#B1B5DB] text-[14px]">
              Or continue with
            </p>
            <div class="flex flex-col lg:flex-row gap-[9px]">
              <a
                href="#"
                class="flex justify-center items-center gap-[14px] flex-1 bg-[#0E122A] py-[26px] rounded-[15px] border border-[#141A3D]"
                @click.prevent="google"
              >
                <svg
                  id="Capa_1"
                  class="h-6 w-6"
                  aria-hidden="true"
                  fill="currentColor"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 210 210"
                  xml:space="preserve"
                >
                  <title>Google Logo</title>
                  <path
                    d="M0,105C0,47.103,47.103,0,105,0c23.383,0,45.515,7.523,64.004,21.756l-24.4,31.696C133.172,44.652,119.477,40,105,40 c-35.841,0-65,29.159-65,65s29.159,65,65,65c28.867,0,53.398-18.913,61.852-45H105V85h105v20c0,57.897-47.103,105-105,105 S0,162.897,0,105z"
                  />
                </svg>
                <span class="font-medium leading-6">Google</span>
              </a>
              <a
                href="#"
                class="flex justify-center items-center gap-[14px] flex-1 bg-[#0E122A] py-[26px] rounded-[15px] border border-[#141A3D]"
                @click.prevent="twitter"
              >
                <svg
                  class="h-6 w-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 300 300"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66"
                  />
                </svg>
                <span class="text-sm font-semibold leading-6">Twitter</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bottom-gradient {
  height: 50%;
  width: 100%;
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 11.98%, #000 71.35%);
  opacity: 0.6;
  bottom: 0;
}

input:focus {
  box-shadow: 0px 0px 0px 4px #cc47ff33;
}
</style>

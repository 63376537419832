<script setup>
import premadeModels from 'common/lib/premade.js'
import { useRoute, useRouter } from 'vue-router'
import { computed, inject, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toast-notification'
import AppHeader from '@/components/AppHeader.vue'
import { useLoading } from 'vue-loading-overlay'
import { useHead } from '@unhead/vue'
import { useMixpanel } from '@/composables/mixpanel'
import { usePaymentNotifier } from '@/composables/usePaymentNotifier'
import Banner from './components/Banner.vue'
import InfoCard from './components/InfoCard.vue'
import FooterComponent from '@/components/Footer.vue'
import FAQCard from './components/FAQCard.vue'
import ModelCard from '@/components/ModelCard.vue'
import GenerateModelCard from '../../components/GenerateModelCard.vue'
import female from '@/assets/icons/female.svg'
import male from '@/assets/icons/male.svg'
import { useSplitTestStore } from '@/stores/splitTest'
import { useWindowSize } from '@vueuse/core'

const mixpanel = useMixpanel()
usePaymentNotifier()

mixpanel.track('explore_page_visit', {}, { source: 'previous' })

const windowSize = useWindowSize()

const isMobileViewModelCard = computed(() => windowSize.width.value < 768)

const $route = useRoute()

const splitTestStore = useSplitTestStore()

const gender = ref('female')

if (['/ai-boyfriend', '/anime-ai-boyfriend'].includes($route.path))
  gender.value = 'male'

const style = ref('realistic')

if ($route.path.startsWith('/anime')) style.value = 'anime'

const headTitleFemale = `${import.meta.env.VITE_BRAND_NAME} | AI Girlfriend | Virtual AI Girlfriend`
const headDescriptionFemale =
  'Explore your deepest fantasies with our virtual AI girlfriend app. Our AI girlfriends are open to anything and are ready to serve you and your needs.'
const headUrlFemale = `https://${import.meta.env.VITE_DOMAIN_NAME}/`

const headTitleFemaleAnime = `${import.meta.env.VITE_BRAND_NAME} | AI Girlfriend | Anime AI Girlfriend`
const headDescriptionFemaleAnime =
  'Explore your deepest fantasies with our anime AI girlfriend app. Our anime AI girlfriends are open to anything and are ready to serve you and your needs.'
const headUrlFemaleAnime = `https://${import.meta.env.VITE_DOMAIN_NAME}/anime-ai-girlfriend`

const headTitleMale = `${import.meta.env.VITE_BRAND_NAME} | AI Boyfriend | Virtual AI Boyfriend`
const headDescriptionMale =
  'Explore your deepest fantasies with our virtual AI boyfriend app. Our AI boyfriends are open to anything and are ready to serve you and your needs.'
const headUrlMale = `https://${import.meta.env.VITE_DOMAIN_NAME}/ai-boyfriend`

const headTitleMaleAnime = `${import.meta.env.VITE_BRAND_NAME} | AI Boyfriend | Anime AI Boyfriend`
const headDescriptionMaleAnime =
  'Explore your deepest fantasies with our anime AI boyfriend app. Our anime AI boyfriends are open to anything and are ready to serve you and your needs.'
const headUrlMaleAnime = `https://${import.meta.env.VITE_DOMAIN_NAME}/anime-ai-boyfriend`

const headImage = `https://${import.meta.env.VITE_DOMAIN_NAME}/twitter-card.png`

const computedMeta = computed(() =>
  gender.value == 'female'
    ? style.value == 'realistic'
      ? {
          title: headTitleFemale,
          meta: [
            { name: 'description', content: headDescriptionFemale },

            { property: 'og:title', content: headTitleFemale },
            { property: 'og:description', content: headDescriptionFemale },
            { property: 'og:url', content: headUrlFemale },
            { property: 'og:image', content: headImage },

            { name: 'twitter:title', content: headTitleFemale },
            { name: 'twitter:description', content: headDescriptionFemale },
            { name: 'twitter:url', content: headUrlFemale },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:image', content: headImage },
            {
              name: 'twitter:site',
              content: `@${import.meta.env.VITE_TWITTER_HANDLE}`,
            },
          ],
        }
      : {
          title: headTitleFemaleAnime,
          meta: [
            { name: 'description', content: headDescriptionFemaleAnime },

            { property: 'og:title', content: headTitleFemaleAnime },
            { property: 'og:description', content: headDescriptionFemaleAnime },
            { property: 'og:url', content: headUrlFemaleAnime },
            { property: 'og:image', content: headImage },

            { name: 'twitter:title', content: headTitleFemaleAnime },
            {
              name: 'twitter:description',
              content: headDescriptionFemaleAnime,
            },
            { name: 'twitter:url', content: headUrlFemaleAnime },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:image', content: headImage },
            {
              name: 'twitter:site',
              content: `@${import.meta.env.VITE_TWITTER_HANDLE}`,
            },
          ],
        }
    : style.value == 'realistic'
      ? {
          title: headTitleMale,
          meta: [
            { name: 'description', content: headTitleMale },

            { property: 'og:title', content: headTitleMale },
            { property: 'og:description', content: headDescriptionMale },
            { property: 'og:url', content: headUrlMale },
            { property: 'og:image', content: headImage },

            { name: 'twitter:title', content: headTitleMale },
            { name: 'twitter:description', content: headDescriptionMale },
            { name: 'twitter:url', content: headUrlMale },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:image', content: headImage },
            {
              name: 'twitter:site',
              content: `@${import.meta.env.VITE_TWITTER_HANDLE}`,
            },
          ],
        }
      : {
          title: headTitleMaleAnime,
          meta: [
            { name: 'description', content: headTitleMaleAnime },

            { property: 'og:title', content: headTitleMaleAnime },
            { property: 'og:description', content: headDescriptionMaleAnime },
            { property: 'og:url', content: headUrlMaleAnime },
            { property: 'og:image', content: headImage },

            { name: 'twitter:title', content: headTitleMaleAnime },
            { name: 'twitter:description', content: headDescriptionMaleAnime },
            { name: 'twitter:url', content: headUrlMaleAnime },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:image', content: headImage },
            {
              name: 'twitter:site',
              content: `@${import.meta.env.VITE_TWITTER_HANDLE}`,
            },
          ],
        },
)

useHead(computedMeta)

const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
})

const $router = useRouter()
const $store = useStore()
const $axios = inject('axios')
const $toast = useToast()

const discountPopupOpen = computed(() => $store.state.discountPopupOpen)
const user = computed(() => $store.state.user)
const premadeModel = computed(() => $store.state.premadeModel)

const shownPremadeModels = computed(() =>
  splitTestStore.shuffleArray(
    `homepage-models-${gender.value}-${style.value}`,
    premadeModels.filter(
      (el) =>
        el.model.gender === gender.value && el.model.style === style.value,
    ),
  ),
)

const loader = ref(null)

function mixpanelModelClick(model, towards) {
  mixpanel.track('explore_model_click', {
    model_id: model.premadeId,
    model_name: model.name,
    model_gender: model.gender,
    model_position:
      shownPremadeModels.value.findIndex(
        (el) => el.model.premadeId === model.premadeId,
      ) + 1,
    towards,
  })
}

async function createModel(model) {
  mixpanelModelClick(model, 'chat')

  if (!user.value && premadeModel.value?.premadeId === model.premadeId) {
    $store.commit('SET_SELECTED_MODEL', model.id)
    await $router.push('/chat')
  } else if (user.value) {
    const userModel = user.value.models.filter(
      (el) => el.premadeId === model.premadeId,
    )
    if (userModel.length) {
      $store.commit('SET_SELECTED_MODEL', userModel[0].id)
      await $router.push('/chat')
    } else {
      loader.value = $loading.show()
      await $axios
        .post('/user/create-model/premade', {
          model,
        })
        .then(({ data: { model: createdModel, changedLuna } }) => {
          if (model.value) $store.dispatch('changeLuna', changedLuna)
          $store.commit('ADD_MODEL', createdModel)
          $store.commit('SET_SELECTED_MODEL', createdModel.id)
          $store.dispatch('setMessages', createdModel.messages)
          $router.push('/chat')
        })
        .catch((e) => {
          const message = e?.response?.data?.message
          return $toast.error(message || 'An error occurred')
        })
      loader.value.hide()
    }
  } else {
    await $store.dispatch('setPremadeModel', model)
    await $store.commit('SET_SELECTED_MODEL', model.premadeId)
    await $router.push('/chat')
    await $axios
      .post('/model/create/premade', {
        model,
      })
      .then(({ data: { model: createdModel, changedLuna } }) => {
        $store.dispatch('setPremadeModel', createdModel)
        $store.commit('SET_SELECTED_MODEL', createdModel.id)
      })
      .catch((e) => {
        const message = e?.response?.data?.message
        return $toast.error(message || 'An error occurred')
      })
  }
}

function setGender(newGender) {
  gender.value = newGender
  mixpanel.track('explore_male_female_click', { selection: newGender })
}

function setStyle(newStyle) {
  style.value = newStyle
  mixpanel.track('explore_realistic_anime_click', {
    gender: gender.value,
    selection: newStyle,
  })
}

const isGirlfriendRoute = computed(() =>
  ['/', '/anime-ai-girlfriend'].includes($route.path),
)

$store.commit('UPDATE_LAST_APP_PAGE')
</script>

<template>
  <div class="lg:ml-[280px] bg-[#070917]">
    <AppHeader :gender="gender" :style="style" @update-gender="setGender" />
    <div
      class="px-4 xl:px-16 3xl:mx-auto m-auto max-w-[1450px]"
      :class="discountPopupOpen ? 'mt-[115px] lg:mt-[64px]' : ''"
    >
      <!-- Female/Male switch only shown on mobile that is not part of AppHeader -->
      <div
        class="p-[7px] bg-[#0e122a] border-[1px] border-[#161929] lg:gap-[6px] rounded-[15px] mb-3 lg:mt-0 flex md:hidden"
      >
        <router-link
          :to="style == 'realistic' ? '/' : '/anime-ai-girlfriend'"
          class="flex-1 lg:flex-0 flex items-center justify-center lg:justify-normal gap-[10px] py-2 sm:py-[13px] px-4 sm:px-[26.5px] font-bold text-xs rounded-lg"
          :class="{
            'border border-white border-opacity-15 bg-[#2a3b5f]':
              isGirlfriendRoute,
          }"
          @click="setGender('female')"
          ><img :src="female" />
          <h3>Female</h3></router-link
        >
        <router-link
          :to="style == 'realistic' ? '/ai-boyfriend' : '/anime-ai-boyfriend'"
          class="flex-1 lg:flex-0 flex items-center justify-center lg:justify-normal gap-[10px] py-2 sm:py-[13px] px-4 sm:px-[26.5px] font-bold text-xs rounded-lg"
          :class="{
            'border border-white border-opacity-15 bg-[#2a3b5f]':
              !isGirlfriendRoute,
          }"
          @click="setGender('male')"
          ><img :src="male" />
          <h3>Male</h3></router-link
        >
      </div>
      <Banner :gender="gender" :style="style" />
      <div class="explore-characters mt-3 lg:mt-[20px]">
        <div class="explore-characters-header">
          <div
            class="lg:max-w-[480px] cursor-pointer sm:max-w-unset mx-auto p-[7px] bg-[#0e122a] border-[1px] border-[#161929] lg:gap-[6px] rounded-[15px] mb-3 lg:mb-[20px] lg:mt-0 flex"
          >
            <router-link
              class="flex-1 lg:flex-0 flex items-center justify-center gap-[10px] py-2 sm:py-[13px] px-4 sm:px-[26.5px] font-bold text-xs rounded-lg"
              :class="{
                'border border-white border-opacity-15 bg-[#2a3b5f]':
                  style == 'realistic',
              }"
              :to="gender == 'female' ? '/' : '/ai-boyfriend'"
              @click="setStyle('realistic')"
            >
              <h3>Realistic</h3>
            </router-link>
            <router-link
              class="flex-1 lg:flex-0 flex items-center justify-center gap-[10px] py-2 sm:py-[13px] px-4 sm:px-[26.5px] font-bold text-xs rounded-lg"
              :class="{
                'border border-white border-opacity-15 bg-[#2a3b5f]':
                  style == 'anime',
              }"
              :to="
                gender == 'female'
                  ? '/anime-ai-girlfriend'
                  : '/anime-ai-boyfriend'
              "
              @click="setStyle('anime')"
            >
              <h3>Anime</h3>
            </router-link>
          </div>
          <div
            class="explore-models-container grid grid-cols-2 sm:flex gap-2 lg:gap-6 flex-wrap"
          >
            <ModelCard
              v-for="(model, i) in shownPremadeModels"
              :key="model.model.premadeId"
              :position="i"
              :model-info="model"
              class="w-full m-auto sm:m-0 sm:max-w-full sm:w-[calc((100%-1.5rem)/2)] md:w-[calc((100%-3rem)/3)] xl:w-[calc((100%-4.5rem)/4)] relative"
              @model-clicked="createModel(model.model)"
              @model-details-clicked="
                mixpanelModelClick(model.model, 'details')
              "
              :isMobileView="isMobileViewModelCard"
            ></ModelCard>
          </div>
        </div>
      </div>
      <GenerateModelCard
        class="mt-[23px] lg:mt-[51px]"
        :gender="gender"
        :style="style"
      />
      <FAQCard
        class="mt-[23px] lg:mt-[51px]"
        :gender="gender"
        :style="style"
      ></FAQCard>
      <InfoCard
        :gender="gender"
        :style="style"
        class="mt-[23px] lg:mt-[51px]"
      ></InfoCard>
      <FooterComponent></FooterComponent>
    </div>
  </div>
</template>

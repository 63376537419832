export default function createMixpanelData(user) {
  if (user) {
    return {
      user_logged_in: true,
      user_subscription_type: !user.subscription
        ? 'none'
        : user.subscription === 1
          ? 'monthly'
          : 'annual',
      user_id: user.id,
      user_gender: user.gender,
      user_name: user.name,
      user_chat_setting_describe: user.chatSettings.describe,
      user_chat_setting_username: user.chatSettings.username,
      user_chat_setting_moan: user.chatSettings.moan,
      user_auth_method: user.thirdPartyAuth || 'email',
    }
  }

  return {
    user_logged_in: false,
  }
}

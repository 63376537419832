<script setup>
import { ref } from 'vue'
import Popup from '@/components/Popup.vue'
import { useRoute, useRouter } from 'vue-router'
import { useConfirm18Store } from '@/stores/confirm18'
import { storeToRefs } from 'pinia'
import Button from './Button.vue'
import rta from '@/assets/icons/rta.svg'
import cdnFile from '@/helpers/cdnFile'

const confirm18Store = useConfirm18Store()
const { confirm18Status } = storeToRefs(confirm18Store)
console.log('intv', confirm18Status.value)
const open = ref(false)
const $route = useRoute()
const $router = useRouter()
function updateOpen(e) {
  open.value = e
}
function confirm() {
  confirm18Store.confirm18()
  open.value = false
}
if ($route.query?.confirm18Popup) {
  open.value = true
  const newQuery = { ...$route.query }
  delete newQuery.confirm18Popup
  $router.push({ query: newQuery })
} else if ($route.query?.noConfirm18Popup) {
  open.value = false
  confirm18Store.confirm18()
  const newQuery = { ...$route.query }
  delete newQuery.noConfirm18Popup
  $router.push({ query: newQuery })
} else if (
  $route.path.startsWith('/lp') ||
  $route.path.startsWith('/create-model')
) {
  confirm18Store.confirm18()
} else {
  open.value = !confirm18Status.value
}
</script>
<template>
  <Popup
    class="sticky z-50"
    popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-[30px] lg:py-[75px] mx-4 lg:mx-0 lg:px-[122px]"
    :mobile="false"
    :open="open"
    :close-button="false"
    :overlay-exit="false"
    :back-style="{ backdropFilter: 'blur(100px)' }"
    :animate="false"
    @update:open="updateOpen"
  >
    <div>
      <div class="text-center text-2xl font-bold">Age Verification</div>
      <div class="text-center mt-4">
        This website contains age-restricted materials including nudity and
        explicit depictions of sexual activity. By entering, you affirm that you
        are at least 18 years of age or the age of majority in your
        jurisdiction.
      </div>
      <div class="grid grid-cols-2 m-1 mt-10 font-semibold gap-8">
        <a href="https://www.google.com">
          <Button
            variant="secondary"
            :borderVisible="false"
            class="py-3 px-6 w-full"
            >Exit
          </Button>
        </a>
        <Button class="py-3 px-6" @click="confirm"> Enter </Button>
      </div>
      <div class="flex justify-center mt-8">
        <img class="w-[72px] h-auto" :src="cdnFile('rta.gif')" alt="RTA Logo" />
      </div>
    </div>
  </Popup>
</template>

<script setup>
import { ref, inject } from 'vue'
import { useStore } from 'vuex'
import { useLoading } from 'vue-loading-overlay'
import MaleRotated from './icons/MaleRotated.vue'
import Female from './icons/Female.vue'
import logoicon from '@/assets/logoicon.svg'

const genders = [
  { id: 'male', name: 'Male', icon: MaleRotated },
  { id: 'female', name: 'Female', icon: Female },
  { id: 'other', name: 'Other', icon: logoicon },
]

const $store = useStore()

const $axios = inject('axios')

const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
  color: '#a15cff',
})

const newGender = ref(null)

async function change() {
  const loader = $loading.show()

  const newUser = await $axios
    .post('/user/change', { gender: newGender.value.id })
    .then((res) => res.data)
  console.log(newUser)
  $store.dispatch('setUser', newUser)

  loader.hide()
}

const handleGenderChange = (newValue) => {
  if (newGender.value) return

  newGender.value = newValue
  change()
}
</script>

<template>
  <div
    class="fixed flex flex-col items-center pt-[234px] z-50 top-0 left-0 bottom-0 right-0 bg-[#070917]"
  >
    <p class="text-[35px] font-medium mt-[30px] mb-[30px]">
      Select your gender
    </p>
    <div class="flex gap-5">
      <div
        @click="handleGenderChange(gender)"
        v-for="gender in genders"
        class="relative flex flex-col justify-center items-center bg-[#0A0D1E] w-[283px] h-[274px] cursor-pointer rounded-[25px] border border-[#111631]"
        :class="
          newGender && gender.id === newGender.id ? 'border-[#CC47FF]' : ''
        "
        :style="
          newGender && gender.id === newGender.id
            ? { boxShadow: '0px 0px 0px 5px #CC47FF33' }
            : {}
        "
      >
        <img
          v-if="gender.id === 'other'"
          :src="gender.icon"
          class="w-[76px] h-auto"
        />
        <component v-else :is="gender.icon" color="#CC47FF" :size="57" />
        <p class="text-[25px] font-medium absolute bottom-[46px]">
          {{ gender.name }}
        </p>
      </div>
    </div>
  </div>
</template>

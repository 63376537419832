<script setup>
import { ref, computed, toRefs } from 'vue'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

const genders = [
  { id: 'male', name: 'Male' },
  { id: 'female', name: 'Female' },
  { id: 'other', name: 'Other' },
]

const props = defineProps({
  modelValue: Object,
})

const { modelValue } = toRefs(props)

const emit = defineEmits(['update:modelValue'])

if (!modelValue.value) emit('update:modelValue', genders[0])
</script>

<template>
  <Listbox
    v-if="modelValue"
    as="div"
    :model-value="modelValue"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <div class="relative">
      <ListboxButton
        id="gender"
        class="relative w-full h-[50px] cursor-default pl-[25px] bg-[#0E122A] rounded-[15px] text-left border border-[#111631]"
      >
        <span class="block truncate">{{ modelValue.name }}</span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        :show="true"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-700 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="gender in genders"
            :key="gender.id"
            v-slot="{ active, selected }"
            as="template"
            :value="gender"
          >
            <li
              :class="[
                active ? 'bg-purple-600 text-white' : 'text-white',
                'relative cursor-default select-none py-2 pl-3 pr-9',
              ]"
            >
              <span
                :class="[
                  modelValue ? 'font-semibold' : 'font-normal',
                  'block truncate',
                ]"
                >{{ gender.name }}</span
              >

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-purple-400',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

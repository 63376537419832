<script setup>
import { inject, onMounted, toRaw } from 'vue'
import { useRoute } from 'vue-router'
import * as Sentry from '@sentry/vue'
import { useTempPaymentData } from '@/stores/tempPaymentData'

const $axios = inject('axios')
const $route = useRoute()
const tempDataStore = useTempPaymentData()

const plainTempPaymentData = toRaw(tempDataStore.tempPaymentData)

const checkStatus = async () => {
  try {
    const { data } = await $axios.post('/user/payment/check-status', {
      id: $route.query.id,
      ...plainTempPaymentData,
    })

    const messageData = {
      ...(data.user && { user: data.user }),
      ...(typeof data.purchaseNumber === 'number' && {
        purchaseNumber: data.purchaseNumber,
      }),
      ...(data.cardData && { cardData: data.cardData }),
    }

    window.parent.postMessage(messageData)
  } catch (e) {
    Sentry.captureException(e)
    const message = e.response.data?.message
    window.parent.postMessage({
      message: message || 'Unable to perform action. Please try again later.',
      isPaymentError: true,
    })
  } finally {
    tempDataStore.removePaymentData()
  }
}

onMounted(checkStatus)
</script>

<template></template>

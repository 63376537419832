// These domains won't trigger an enterReferrer change
export default [
  'localhost',
  'accounts.google.com',
  'api.twitter.com',
  `dev.${import.meta.env.VITE_DOMAIN_NAME}`,
  `staging.${import.meta.env.VITE_DOMAIN_NAME}`,
  `${import.meta.env.VITE_DOMAIN_NAME}`,
  `api-dev.${import.meta.env.VITE_DOMAIN_NAME}`,
  `api-staging.${import.meta.env.VITE_DOMAIN_NAME}`,
  `api.${import.meta.env.VITE_DOMAIN_NAME}`,
]

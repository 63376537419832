<script setup>
import Popup from './Popup.vue'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import { watch, toRefs } from 'vue'
import { useMixpanel } from '@/composables/mixpanel'
import Button from './Button.vue'

const props = defineProps({
  open: Boolean,
})
const { open } = toRefs(props)

const emit = defineEmits(['update:open'])

const mixpanel = useMixpanel()

watch(open, (val) => {
  if (val) {
    mixpanel.track(
      'open_no_balance_popup',
      {},
      {
        source: 'current',
      },
    )
  }
})
</script>

<template>
  <Popup
    class="sticky z-40"
    popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-[30px] lg:py-[75px] mx-4 lg:mx-0 lg:px-[122px]"
    :open="open"
    :mobile="false"
    @update:open="emit('update:open', $event)"
  >
    <div class="px-1 py-2 md:px-4 md:py-5 sm:p-6 mt-2">
      <div class="flex flex-col items-center gap-[25px]">
        <img class="w-[148px] h-auto" :src="logo" />
        <h2 class="text-[35px] font-medium leading-[31px]">Not Enough Luna</h2>
        <p class="text-[#B1B5DB] text-center">
          You have insufficient Luna tokens to perform this action.
        </p>
      </div>
      <div
        class="flex justify-between mt-6 lg:mt-10 text-sm font-semibold gap-x-3 text-center"
      >
        <Button
          to="/buy-luna"
          class="p-2 lg:p-3 w-full"
          @click="$emit('update:open', false)"
          >Top up</Button
        >
      </div>
    </div>
  </Popup>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import discounts from 'common/lib/discount'
import cdnFile from '@/helpers/cdnFile.js'
import wind from '@/assets/icons/wind.svg'
import rocket from '@/assets/icons/rocket.svg'
import stars from '@/assets/icons/stars.svg'
import moonEclipse from '@/assets/icons/moon-eclipse.svg'
import logoicon from '@/assets/logoicon.svg'

const $store = useStore()

const props = defineProps({
  image: String,
  name: String,
  luna: Number,
  amount: String,
  prediscoutAmount: String,
  bestDeal: Boolean,
  popular: Boolean,
  selected: [Boolean, Number],
  i: Number,
})

const currentName = computed(() => props.name.split('<br>').join(' '))

const currentIcon = computed(() => {
  switch (currentName.value) {
    case 'Lunar Breeze':
      return wind
    case 'Lunar Odyssey':
      return rocket
    case 'Lunar Harmony':
      return stars
    case 'Lunar Eclipse':
      return moonEclipse
  }
})

const currency = computed(() =>
  $store.state.user?.currency ? $store.state.user?.currency : 'USD',
)
const homeCurrency = computed(() => $store.state.user?.homeCurrency)
const discount = computed(() => $store.state.discountCode)

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: currency.value,
})

function formatPrice(price) {
  let formatted = formatter.format(price)

  // This shows the currency as US$ instead of just $ for non-US countries
  // Temporarily remove it and might add it back if we get complaints from Canadians or Australians
  //
  // if (currency.value === 'USD' && currency.value != homeCurrency.value) {
  //   formatted = `US${formatted}`
  // }

  return formatted
}

function findPurchaseDiscountFromShared(purchaseNumber) {
  const foundDiscount = discount.value
  const endDate = $store.state.discountEndDate
  const now = new Date().getTime()

  if (!endDate || endDate - now > 0) {
    return discounts[foundDiscount]?.purchases?.[purchaseNumber]
  }

  return null
}
</script>

<template>
  <div
    class="bg-[#0F132B] xl:w-[701px] flex flex-col gap-[15px] lg:gap-0 lg:flex-row lg:items-center lg:py-[17px] p-[13px] lg:pl-4 lg:pr-[27px] rounded-[25px] border border-[#181E41] cursor-pointer select-none"
    :class="typeof selected !== 'number' && selected ? 'border-[#CC47FF]' : ''"
    style="box-shadow: 0px 0px 15px 0px #161b3c inset"
  >
    <div class="flex items-center">
      <div
        class="py-[10px] px-[11px] rounded-[15px] border border-white border-opacity-15"
        style="
          background: radial-gradient(
            255.14% 174.74% at 38.76% 155.71%,
            #cc47ff 0%,
            #9a5cff 100%
          );
          box-shadow:
            0px 0px 3px 0px #1cae6e,
            0px 0px 0px 1.18px #b552ff;
        "
      >
        <img :src="currentIcon" />
      </div>
      <p class="text-[19px] font-medium pl-5 lg:pr-5 lg:w-[180px]">
        {{ currentName }}
      </p>
      <div
        class="flex ml-auto lg:ml-0 items-center p-[9px] lg:bg-[#141A3B] rounded-[10px]"
      >
        <img class="mr-[10px] w-[28px] h-auto" :src="logoicon" />
        <p class="text-[19px] font-bold">{{ luna }}</p>
      </div>
    </div>
    <div class="flex justify-center items-center lg:ml-auto">
      <div
        v-if="bestDeal"
        class="hidden lg:block ml-auto mr-[22px] py-[7px] px-[9px] min-w-[80px] text-center rounded-[100px] border border-white border-opacity-15"
        style="
          background: radial-gradient(
            255.14% 174.74% at 38.76% 155.71%,
            #cc47ff 0%,
            #9a5cff 100%
          );
          box-shadow:
            0px 0px 3px 0px #1cae6e,
            0px 0px 0px 1.18px #b552ff;
        "
      >
        <p class="text-[14px] font-medium text-nowrap">Best Deal</p>
      </div>
      <div
        v-if="popular"
        class="hidden lg:block ml-auto mr-[28px] py-[7px] px-[9px] min-w-[70px] text-center rounded-[100px] text-[#AE53FF] border border-[#AE53FF]"
      >
        <p class="text-[14px] font-medium text-nowrap">Popular</p>
      </div>
      <div
        class="w-full bg-[#141A3B] lg:bg-transparent rounded-[10px] py-[10px] lg:py-0 relative flex flex-col items-center"
      >
        <p class="text-[25px] text-center font-medium leading-[25px]">
          <span
            v-if="prediscoutAmount"
            class="mr-[10px] text-[16px] font-medium line-through text-white text-opacity-50"
          >
            {{ formatPrice(prediscoutAmount) }}
          </span>
          <span
            v-else-if="
              !prediscoutAmount && discount && findPurchaseDiscountFromShared(i)
            "
            class="mr-[10px] text-[16px] font-medium line-through text-white text-opacity-50"
          >
            {{ formatPrice(amount) }}
          </span>
          <span>{{
            discount && findPurchaseDiscountFromShared(i)
              ? formatPrice(findPurchaseDiscountFromShared(i).amount)
              : formatPrice(amount)
          }}</span>
        </p>
        <div
          v-if="bestDeal"
          class="lg:hidden py-[3px] px-[4px] mt-3 text-center rounded-[10px]"
          style="
            background: radial-gradient(
              255.14% 174.74% at 38.76% 155.71%,
              #cc47ff 0%,
              #9a5cff 100%
            );
            box-shadow:
              0px 0px 3px 0px #1cae6e,
              0px 0px 0px 1.18px #b552ff;
          "
        >
          <p class="text-[13px] font-medium text-nowrap px-4">Best Deal</p>
        </div>
        <div
          v-if="popular"
          class="lg:hidden py-[3px] px-[4px] mt-3 text-center rounded-[10px] text-[#AE53FF] border border-[#AE53FF]"
        >
          <p class="text-[13px] font-medium text-nowrap px-4">Popular</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Button from './Button.vue'
import cdnFile from '@/helpers/cdnFile.js'
import particles from '@/assets/img/particles.svg'
import circles from '@/assets/img/circles.svg'
import magicWand from '@/assets/icons/magic-wand.svg'

defineProps({
  gender: String,
  style: String,
})

const modelsShowcased = {
  female: {
    realistic: [
      'premade/aaliyah.webp',
      'premade/nari.webp',
      'premade/amara.webp',
      'premade/riley.webp',
    ],
    anime: [
      'premade/aria.webp',
      'premade/akari.webp',
      'premade/ami.webp',
      'premade/ayumi.webp',
    ],
  },
  male: {
    realistic: [
      'premade/sean.webp',
      'premade/amir.webp',
      'premade/logan.webp',
      'premade/tyler.webp',
    ],
    anime: [
      'premade/ash.webp',
      'premade/daichi.webp',
      'premade/kai.webp',
      'premade/elric.webp',
    ],
  },
}
</script>

<template>
  <div
    class="flex justify-center items-center bg-[#0A0D1E] rounded-[25px] py-[56px] px-4 lg:py-[30px] 2xl:p-0 border border-white border-opacity-5 lg:min-h-[345px] relative overflow-hidden"
  >
    <div class="hidden lg:block">
      <div
        class="z-10 p-[0.6px] rounded-full absolute top-[62px] left-[88px]"
        style="
          background: linear-gradient(
            55.67deg,
            #a455ff 13.36%,
            #7700ff 13.36%,
            rgba(128, 0, 255, 0) 66.41%
          );
        "
      >
        <img
          class="w-[60px] h-[60px] object-cover rounded-full select-none object-top"
          :src="cdnFile(modelsShowcased[gender][style][0])"
          style="-webkit-user-drag: none"
        />
      </div>
      <div
        class="z-10 p-[1px] absolute rounded-full bottom-[59px] left-[50px] 2xl:left-[168px]"
        style="
          background: linear-gradient(
            55.67deg,
            #a455ff 13.36%,
            #7700ff 13.36%,
            rgba(128, 0, 255, 0) 66.41%
          );
        "
      >
        <img
          class="w-[79px] h-[79px] object-cover rounded-full select-none object-top"
          :src="cdnFile(modelsShowcased[gender][style][1])"
          style="-webkit-user-drag: none"
        />
      </div>
      <div
        class="z-10 p-[1px] absolute bottom-[74px] right-[50px] 2xl:right-[163px] rounded-full"
        style="
          background: linear-gradient(
            55.67deg,
            #a455ff 13.36%,
            #7700ff 13.36%,
            rgba(128, 0, 255, 0) 66.41%
          );
        "
      >
        <img
          class="w-[82px] h-[82px] object-cover rounded-full select-none object-top"
          :src="cdnFile(modelsShowcased[gender][style][2])"
          style="-webkit-user-drag: none"
        />
      </div>
      <div
        class="z-10 p-[1px] absolute top-[72px] right-[55px] rounded-full"
        style="
          background: linear-gradient(
            55.67deg,
            #a455ff 13.36%,
            #7700ff 13.36%,
            rgba(128, 0, 255, 0) 66.41%
          );
        "
      >
        <img
          class="w-[40px] h-[40px] object-cover rounded-full select-none object-top"
          :src="cdnFile(modelsShowcased[gender][style][3])"
          style="-webkit-user-drag: none"
        />
      </div>
      <img
        class="absolute bottom-[0] left-[50%] translate-x-[-50%] rounded-full select-none"
        :src="particles"
        style="-webkit-user-drag: none"
      />
    </div>
    <img
      class="hidden lg:block absolute top-[50%] w-[90%] translate-y-[-50%] select-none"
      :src="circles"
      style="-webkit-user-drag: none"
    />
    <div
      class="flex flex-col items-center gap-6 lg:gap-[36px] lg:w-[50%] text-center relative z-20"
    >
      <h2
        class="text-[25px] leading-[33px] lg:text-[35px] font-medium lg:leading-[47px]"
      >
        Generate the {{ style == 'anime' ? 'Anime ' : '' }}AI
        {{ gender === 'female' ? 'Girlfriend' : 'Boyfriend' }} of Your Dreams
      </h2>
      <p class="text-[16px]">
        Discover your new {{ style == 'anime' ? 'anime ' : '' }}AI
        {{ gender === 'female' ? 'girlfriend' : 'boyfriend' }}! Craft
        {{ gender === 'female' ? 'her' : 'his' }} appearance and personality to
        your liking, then bring {{ gender === 'female' ? 'her' : 'his' }} to
        life with a single click. Fully realized through the power of artificial
        intelligence.
      </p>
      <Button
        :to="gender === 'female' ? '/create-girlfriend' : '/create-boyfriend'"
        class="flex items-center gap-[15px] py-[10px] pl-[11px] pr-[19px] text-[14px]"
      >
        <img class="w-6 h-6" :src="magicWand" /> Create AI
        {{ gender === 'female' ? 'Girlfriend' : 'Boyfriend' }}
      </Button>
    </div>
  </div>
</template>
